<template>
  <!--<div class="content">
     
    <div class="card">-->
        
      <div>
          <notifications></notifications>
        <!--<card class="no-border-card" body-classes="px-3 pb-1" footer-classes="pb-2">-->
         <!-- <template slot="header">
            <h3 class="mb-0">View {{}} Product</h3>
          </template>-->
          <div>
            <div v-if="response_product_select !== null">
              
  <!--<b-container class="table-container">--> <!--class="over-flow">-->
  <div class="form-body">
      <div class="table-section ">
    <!-- User Interface controls -->
     <div class="row mb-2">
       <div class="col-12">
 <label for="search" class="form-control-label">Search</label>
        <div class="input-group">
  <input type="text" v-model="filter" placeholder="Search ..." class="form-control" name="search">
  <div class="input-group-append">
    <base-button size="sm" outline :disabled="loading" @click="queryFundTransferSetting()"  class="btn btn-primary btn-height"><i class="fas fa-search"></i>Search More  <span :class="{'spinner-border': loading}"></span></base-button>
                <base-button size="sm" outline :disabled="loading" @click="showAccountCreate=true" class="btn btn-primary btn-height"><i class="fas fa-plus"></i>New Fund Transfer</base-button>
                 
  </div>
</div>
</div>
</div> 
     
<div class="row my-1">
<div class="col-lg-6 col-md-6 col-sm-5 col-12">
        
        <el-select class="select-danger" filterable
             placeholder="" 
             v-model="perPage">
    <el-option v-for="option in [{label:'5',value:5},{label:'10',value:10},{label:'15',value:15}, {label:'100',value:100} ]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <label class="form-control-label ml-1" for="">Per Page</label>
          
        </div>
     

    <div class="col-lg-6 col-md-6 col-sm-7 col-12">
        <div class="text-right">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
        </div>
    </div>
    </div>
    <!-- Main table element -->
    <div>
    <b-table striped hover  
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="lg"
      show-empty
      large
      :head-variant="light"
      @filtered="onFiltered"
    >
      
      <template #cell(action)="row">
                <template>
                    <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-dark">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                      <!--<i class="ni ni-settings"></i>-->
                    </span>
                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                          <a class="dropdown-item" @click="editProduct(row.item, row.index)">
                             <!-- <i class="far fa-edit"></i>-->
                             <i class="fas fa-user-edit"></i>
                             <!--<i class="ni ni-ruler-pencil"></i>-->
                              Edit Product</a>
                            <!--<a class="dropdown-item" @click="showTerminal(row.item, row.index)">
                                <i class="fas fa-user-edit"></i>
                                Assign Terminal</a>
                            <a class="dropdown-item" href="#">Something else here</a>-->
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
      </template>

      <template #cell(Status)="row">
       <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
        <template >
                    <badge class="badge-dot mr-4" type="">
                      <div v-if="row.value==='ACTIVE'">
                        <badge type="success" size="lg">ACTIVE</badge>
                        </div>
                        <div v-else>
                        <badge type="danger" size="lg">{{row.value}}</badge>
                        </div>
                        <!--<i :class="[row.value == 'ACTIVE' ? 'bg-success' : 'bg-danger']"></i>
                        <span class="status">{{row.value}}</span>-->
                    </badge>
                </template>
      </template>


     

<!-- <template #head(accountNumber)="data">
        <span class="font-weight-bolder text-capitalize">{{ data.label. }}</span>
      </template>-->

      
      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>



<modal :show.sync="modals.modal3"
               body-classes="p-0"
               modal-classes="modal-dialog-centered modal-md">
               <div v-if="Object.keys(response_prod).length !== 0">
               <div class="vodal-dialog" style="width: 500px; height: 450px; animation-duration: 300ms;">
                     <article class="item-right" ><!-- @click="displayDetail()">-->
                    <a><span @click="modals.modal3 = false"
            ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="fill:#171717;" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
</span></a></article>
<!---<div class="card">-->
  <div class="card-body">
    <div>
      <div class="view-more-customer-info">
        <div class="view-more-customer-info-wrap">
          <div class="view-more-top">
            <h4 class="card-title mb-4">Edit Product</h4>
            </div>
                        <div class="view-more-base">
                          <div class="table-responsive">
                            <form id="form_update" ref="editForm"
          @submit.prevent="submitEdit ">
                                <div class="row">
<!------------------------------Line 1-------------------------------------->
           <div  v-for="(prod, templateSequence) in response_prod.data"
                  :key="templateSequence" class="col-lg-12 col-md-12 col-sm-12 col-12">
<div class="form-group">
    <label class="form-control-label"> {{prod.templateLabel}}</label>
    <div class="has-label"><!---->
    <div v-if="prod.templateDataType==='NUMBER'">
    <input :readonly="(prod.templateDisabled==='YES')?true:false" type="number" :name="prod.templateDescription"  :value="prod.templateValue"
    :placeholder="prod.templateLabel" class="form-control" required><!---->
    </div>
    <div v-else-if="prod.templateDataType==='FLOAT'">
    <input :readonly="(prod.templateDisabled==='YES')?true:false" type="number" step=0.01  :value="prod.templateValue"
     :name="prod.templateDescription" :placeholder="prod.templateLabel" class="form-control" required><!---->
    </div>
    <div v-else-if="prod.templateDataType==='STRING'">
     <input :readonly="(prod.templateDisabled==='YES')?true:false" type="text"  :value="prod.templateValue"
     :name="prod.templateDescription" :placeholder="prod.templateLabel" class="form-control" required><!---->
    </div>
    <div v-else>
    <select class="form-control" :placeholder="prod.templateLabel" :name="prod.templateDescription" :readonly="(prod.templateDisabled==='YES')?true:false" required>
         <option>Select {{prod.templateLabel}}</option> 
        <option  v-for="(item,index) in selectOption(prod.templateDescription)" :key="index" :value="item"
        :selected="item === prod.templateValue"
        >{{item}}</option>
   
    </select>
    
    </div>
    </div><!----><!----></div>

        </div>
<!------------------------------End of Line 1-------------------------------------->
</div>                 </form>
                       </div></div>
                    <p class="text-right"><span>
                <span class="authorize-btn"><button  :class="{disabled:loading_product}" type="submit" form="form_update" class="btn btn-primary mr-1"><i v-if="!loading_product" class="fas fa-check"></i>Update
                         <span :class="{'spinner-border': loading_product}"></span> </button>
                                    </span></span></p>
                                            </div></div></div>
  </div>
    <!---</card>
                                                </div></div>-->

               </div>
               </div>
                <div v-else>
                      <LoadingPanel></LoadingPanel>
                    </div>
        </modal>
      
</div>
  </div>
  <!--</b-container>-->
<!-----------------------------------Charges------------------------------------------------->
<!-----------------------------------End of Charges------------------------------------------>

<!-----------------------------------Funds Transfer------------------------------------------------->
<!-----------------------------------End of Funds Transfer------------------------------------------>

<!-----------------------------------Loan------------------------------------------------->
<!-----------------------------------End of Loan------------------------------------------>

<!-----------------------------------Interest------------------------------------------------->
<!-----------------------------------End of Interest------------------------------------------>

          </div>
          <div v-else>
            <LoadingPanel></LoadingPanel>
          </div>
          </div>
          <!---</card>-->
      </div>
   <!-- </div>
    
  </div>-->
</template>

<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
import country from '@/components/country';
import Loader from '@/components/Loader';
import BaseInput from '../../components/Inputs/BaseInput.vue';

  export default {
    components: { 
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      LoadingPanel,
       // BaseInput,
      //Loader
      
    },
    data() {
      return {
          products:[],
          light:'light',
        modals:{
          modal3:false,
          modal0:false,
          modal1:false,
        },
        items:[],
        fields: [],

        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
       ...mapState({userInformation: state => state.auth.userInfo,
       response_prod: state => state.product.product_response_edit,
    response_product_FT: state => state.product.product_view_FT,
    response_product_select: state => state.product.response_product_selected,
 loading_product: state => state.product.product_loading,
    success_product: state => state.product.product_success,error_product: state => state.product.product_errors,
    }),
    ...mapActions(['']),
    ...mapGetters([""]),
    
    },
    /*mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },*/
    methods: {
      selectOption(id){
    let option_obj=this.response_prod.data.find(obj => ((obj.templateDescription === id)));
    let optionsList=option_obj.templateList.split(",");
    return optionsList;
},
       
         resetForm(){
            //console.log(`>>><<<${this.nameCtrl}`);
            this.inputRequired=true;
        },
        submitForm(){
     // alert('this.model.username');
     if(this.validateSelect()){
        this.inputRequired=false;
         this.$store.dispatch("branchUpdate", this.model, { root: false });
       
     }
      },
 editProduct(item, index){
           this.modals.modal3 = true;
     //console.log(this.model.customerId);
    this.$store.dispatch("readProductForm1",{
        productCode: item.Code,
        templateBankCode: this.userInformation.userBankCode,
        templateType: this.response_product_select
  }, { root: false });


        //this.model=branch_obj;
        },
  submitEdit(){
  // var formData = new FormData(this.$refs.editForm);
  //       for(var pair of formData.entries()) {
  //  console.log(pair);
//}
this.products.push({
        bankcode:this.userInformation.userBankCode,
         productname:this.response_product_select,
         userId:this.userInformation.userId,
          key: 'source',
          value:'WEB',
          });
         var formData = new FormData(this.$refs.editForm);
         for(var pair of formData.entries()) {
          this.products.push({
         bankcode:this.userInformation.userBankCode,
         productname:this.response_product_select,
         userId:this.userInformation.userId,
          key: pair[0],
          value:pair[1],
          });
    console.log(pair);
}
console.log(this.products);
this.$store.dispatch("updateProduct",{
        requestType:"update",
        products:this.products,
         productBankCode: this.userInformation.userBankCode,
        productType: this.response_product_select
    }, { root: false });

      },
      
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      
    },
    
    watch: {

response_product_FT(newValue,oldValue){
    if(Object.keys(this.response_product_FT).length !== 0){
    if('data' in this.response_product_FT){
        
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'MinAmount',label: 'Min Amount',sortable: true,class: 'text-left'},
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'MaxAmount', label: 'Max Amount', sortable: true, class: 'text-left' },
          { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_FT.data;
    //console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                Status: item.Status,
                AccountingEntry: item.AccountingEntry,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                MinAmount: item.MinAmount,
                CreatedAt:item.CreatedAt,
                BankCode: item.BankCode,
                Code:item.Code,
                MaxAmount:item.MaxAmount,
               
            };
          });


        }
           }
    },

    errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('danger',newValue);
        //this.showDismissibleAlert=true;
      }
    },
     error_product(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
        this.notifyVue('danger',newValue);
        //this.showDismissibleAlert=true;
      }
    },
    success_product(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
        this.notifyVue('success',newValue);
        this.modals.modal3 = !true;
         
console.log("Updated");
        //this.showDismissibleAlert=true;
      }
    },

/*response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_product).length !== 0){
    var product = [];
    product = this.response_product.data;
    this.option_product = product.map((item) => {
            return {
                value: `${item.Code}`,
                label: `${item.Code} (${item.Description})`
            };
        });
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);
      
    }
        },
     response_glL2(newValue, oldValue) {
      //console.log(`Updating from Role ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      if(Object.keys(this.response_glL2).length !== 0){
    var gl = [];
    gl = this.response_glL2.data;
    this.option_gl = gl.map((item) => {
            return {
                value: item.glLevel2Description,
                label: item.glLevel2Description
            };
        });
 //       console.log('//////////');
//console.log("&$$$$$&"+this.option_role);
      
    }
   
        },*/


    success(newValue, oldValue) {
      console.log(`Success from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('success',`${newValue}`);
          this.modals.modal3=false;
         this.modals.modal0=false;
         
        //this.showDismissibleAlert=true;
      }
    },
   
  },
    updated() {
        //this.data = this.$store.getters.getPrivilageResponse;
        //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
    },
    created:function (){
    },
    mounted:function (){
        //console.log(`select ${JSON.stringify(this.response_product_LO)}`);
      console.log(`select ${this.response_product_select}`);
    if(Object.keys(this.response_product_FT).length !== 0){
    if('data' in this.response_product_FT){
        
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'MinAmount',label: 'Min Amount',sortable: true,class: 'text-left'},
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'MaxAmount', label: 'Max Amount', sortable: true, class: 'text-left' },
          { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_FT.data;
    //console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                Status: item.Status,
                AccountingEntry: item.AccountingEntry,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                MinAmount: item.MinAmount,
                CreatedAt:item.CreatedAt,
                BankCode: item.BankCode,
                Code:item.Code,
                MaxAmount:item.MaxAmount,
               
            };
          });

    }
        }
    }
           
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.table-section {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    /*max-width: 100%;*/
}


.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.item-right {
   padding-left: 90%; 
  cursor: pointer;
  margin:0px 0% 5px 0px;
    float:right!important;
}
 .vodal-dialog {
    overflow-y: scroll;
    overflow-x: hidden !important;
    background-color: #fff;
}
.vodal-dialog .card {
    box-shadow: none !important;
}
body{
  
  overflow-x: hidden !important;
}
.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.view-more-customer-info {
    color: #545b62;
}
.view-more-customer-info .view-more-top {
    margin-bottom: 10px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
p{
    color: #495057;
}
.view-more-customer-info p {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 0;
}
.view-more-top .queue-value {
    font-size: 14px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 20px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
.view-more-customer-info {
    color: #545b62;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #495057;
}
.table-section tbody {
    max-width: 500px;
    overflow-x: scroll;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8f9fa;
}
.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #eff2f7;
}
.view-more-base .view-more-base-tag {
    margin-bottom: 0;
}

.view-more-base .view-more-base-value {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 16px;
    color: #556ee6;
    font-weight: 600;
}
.card-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 600;
}
.view-more-customer-info .view-more-top .customer-bio {
    display: flex;
    margin: 0;
}
.view-more-customer-info .view-more-top .customer-bio .customer-avatar {
    margin-right: 10px;
}
.view-more-customer-info p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
}
.view-more-customer-info .view-more-top {
    margin-bottom: 10px;
}
.view-more-base .view-more-base-value .customer-signature {
    height: auto;
    width: 100%;
    cursor: pointer;
}
.sample-file-download {
    cursor: pointer;
}
.view-more-customer-info .view-more-top .customer-bio .customer-avatar img {
    height: 80px;
    border-radius: 10%;
    cursor: pointer;
}
img, svg {
    vertical-align: middle;
}
.view-more-customer-info .view-more-top .customer-bio .name {
    display: block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 10px;
}
.view-more-customer-info .view-more-top .customer-bio .age {
    display: block;
    margin-bottom: 5px;
}
.view-more-customer-info .view-more-top .customer-bio .status {
    display: inline-block;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    height: calc(1.5em + 1.25rem + 5px);
    line-height: 1.5;
    width: 100%;
}

</style>