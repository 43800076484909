<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Create GL Level 1</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">GL Level 1</a></li>
              <li class="breadcrumb-item active" aria-current="page">Create GL Level 1</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
         
          
          <!--</div>-->
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <!--<div v-if="option_acc.length!==0">-->
          <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Create GL Level 1</h3>

    <!-- Card body -->
    
    <form id="submitForm" @click="resetForm"
          @submit.prevent="submit">
      <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Description"
                      name="Description"
                      placeholder="Description"
                      :required="inputRequired"
                      v-model="model.description">
          </base-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Category Code">
                      <el-select class="select-danger" required clearable filterable
             placeholder="Category Code"
             v-model="model.categoryCode">
    <el-option v-for="option in option_code"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="codeCtrl" class="form-error-message"> The Category Code is Required' </p>
                    </base-input>
        </div>

      
      </div>

      <div class="text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_glLevel}">
     <span class="btn-inner--text">Submit</span>
        <span :class="{'spinner-border': loading_glLevel}"></span>
     </base-button>
      <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
      </div>
    </form>
  </card>
  <!---</div>
    <div v-else>
              <LoadingPanel></LoadingPanel>
          </div>-->
        </div>
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import country from '@/components/country';
//import UserInfo from './UserInfo.vue';
import vSelect from 'vue-select';
import { Select, Option } from 'element-ui';
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
export default {

    components: {
        BaseHeader,
        //UserInfo,
        //LoadingPanel,
        //vSelect,
        //[Select.name]: Select,
        //[Option.name]: Option
    },
    data() {
      return {
          //selected_branchCode:{},
          option_code:[{value:'10',label:'10 - (FIXED ASSET)'},{value:'20',label:'20 - (CURRENT ASSET)'},{value:'30',label:'30 - (EQUITIES)'},
          {value:'40',label:'40 - (LIABILITIES)'},{value:'50',label:'50 - (REVENUE)'}],
          codeCtrl:false,
          inputRequired:true,
            model: {
            categoryCode:'',
            description:''
            }
      }
    },
    methods: {
         resetForm(){
            //console.log(`>>><<<${this.nameCtrl}`);
            this.inputRequired=true;
        },
      submit: function(event) {
          console.log(`>>><<<${this.validateSelect()}`);
          if(this.validateSelect()){
              var payload={
                  glLevel1BankCode: this.userInformation.userBankCode,
glLevel1BranchCode: this.userInformation.userBranchCode,
glLevel1CategoryCode:this.model.categoryCode,
glLevel1Description: this.model.description,
glLevel1Maker: this.userInformation.userId,
source: "web"
              };
          this.$store.dispatch("glLevel1Create",payload , { root: false });
          this.inputRequired=false;

          
        
          //event.target.reset();

          }   
      },
      validateSelect(){
          if(this.model.categoryCode===""){
              this.codeCtrl=true;
          }
          else{
              this.codeCtrl=false;
          }
          
          return !this.codeCtrl;
      },
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    },
    watch:{
       success_glLevel(newValue, oldValue) {
     console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
       this.model={
            categoryCode:'',
            description:''
        };
        document.getElementById("submitForm").reset();
      }
    },
    
    error_glLevel(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    }
    },
   mounted:function (){

    },
    computed:{
           ...mapState({
               //errors: state => state.role.role_errors,loading: state => state.role.role_loading,
    //success: state => state.role.role_success,
    userInformation: state => state.auth.userInfo,
    //response: state => state.role.role_response, response_read: state => state.role.role_read_response,
    //response_teller: state => state.teller.teller_response,
    //response_internalAcc: state => state.internalAccount.internal_acc_response,
    response_glLevel: state => state.glLevel.gl_response, loading_glLevel: state => state.glLevel.gl_loading,
    success_glLevel: state => state.glLevel.gl_success,error_glLevel: state => state.glLevel.gl_errors,
    error_user: state => state.user.user_error
    
    }),
    ...mapActions(['userRead']),
    ...mapGetters([""]),
    
    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
</style>
