<template>
<div>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">All Roles</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Role</a></li>
              <li class="breadcrumb-item active" aria-current="page">All Roles</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="md" outline @click="show=true" class="btn btn-primary"><i class="fas fa-plus"></i>New Role</base-button>
           <!--<base-button size="sm" type="neutral">New Roles</base-button>
         <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      
<PageinatedTables></PageinatedTables>
<CreateRole @closeCreateRole="updateCreateRole" :showCreateRole="show" ></CreateRole>
   
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import PageinatedTables from './PaginatedTables'
import CreateRole from './CreateRole'
  export default {
    components: {
        BaseHeader,
        CreateRole,
        PageinatedTables
    },
    data(){
  return{
    show:false
  }
},
  methods: {
       updateCreateRole(value){
   this.show=value;
},
 
}
}
</script>