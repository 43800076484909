<template>
<div>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Branches</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Branch</a></li>
              <li class="breadcrumb-item active" aria-current="page">Branch</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
         <!-- <base-button size="md" outline class="btn btn-primary">New Branch</base-button>-->
          <base-button size="md" outline @click="show=true" class="btn btn-primary"><i class="fas fa-plus"></i>New Branch</base-button>
          <!---<base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>--->
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <user-info></user-info>
          </div>
         <div class="col-lg-12 col-md-12 col-sm-12 col-12">    
<AllBranchTable></AllBranchTable>
<CreateBranch @closeCreateBranch="updateCreateBranch" :showCreateBranch="show" ></CreateBranch>
        </div>
  </div>
   
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import AllBranchTable from './AllBranchTable';
import CreateBranch from './CreateBranch';
import UserInfo from './UserInfo.vue';
  export default {
    components: {
        BaseHeader,
        AllBranchTable,
        UserInfo,
        CreateBranch
    },
data(){
  return{
    show:false
  }
},
  methods: {
       updateCreateBranch(value){
   this.show=value;
},
 
}
  }
</script>