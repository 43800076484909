<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Customer Account Enquiry</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Fund Transfer</a></li>
              <li class="breadcrumb-item active" aria-current="page">Customer Account Enquiry</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Customer Account Enquiry</h3>

    <!-- Card body -->
    
    <form id="submitForm"
          @submit.prevent="submit" >
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Customer Account Number"
                      name="Customer Account Number"
                      type="number"
                      placeholder="Customer Account Number"
                      :required="inputRequired"
                      v-model="accountNumber">
          </base-input>
        </div>
      </div>

      <div class="text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_transfer}">
     <span class="btn-inner--text">Submit</span>
       <span :class="{'spinner-border': loading_transfer}"></span>
     </base-button>
      <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
      </div>
    </form>
  </card>
  
<modal :show.sync="modals.modal3"
               body-classes="p-0"
               modal-classes="modal-dialog-centered modal-sm">
               <div class="vodal-dialog" style="width: 380px; height: 480px; animation-duration: 300ms;">
                     <article class="item-right" ><!-- @click="displayDetail()">-->
                    <a><span @click="modals.modal3 = false"
            ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="fill:#171717;" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
</span></a></article>
<!---<div class="card">-->
  <div class="card-body">
    <div v-if="result" >
      
      <div class="notification-modal-inner">
          <div class="transactional-notification">
              <p class="title"> Customer Account Enquiry </p>
              <div class="cen" v-if="response_transfer.accountName.length!==0"><span class="avatar">{{`${response_transfer.accountName.split(' ')[0].charAt(0)}${response_transfer.accountName.split(' ')[1].charAt(0)}`}}</span>
              <!----></div>
              <div class="transaction-info"><div class="info-card">
                  <div class="row"><div class="col-4">
                      <span class="title">Name</span>
                      </div><div class="col-8">
                          <span class="value">{{response_transfer.accountName}}</span>
                          </div></div></div><div class="info-card"><div class="row">
                              <div class="col-4"><span class="title">BVN</span></div>
                              <div class="col-8"><span class="value"> {{response_transfer.bvn}} </span>
                              </div></div></div><div class="info-card"><div class="row">
                                  <div class="col-4"><span class="title">Phone No.</span></div>
                                  <div class="col-8"><span class="value"> {{response_transfer.accountCustomerPhone}} </span></div></div></div>
                                  <div class="info-card"><div class="row"><div class="col-4">
                                      <span class="title">Balance</span></div><div class="col-8">
                                          <span class="value"> ₦{{response_transfer.accountBalance}} </span></div></div></div>
                                          <div class="info-card"><div class="row"><div class="col-4">
                                              <span class="title">Ledger Balance</span></div><div class="col-8">
                                                  <span class="value"> ₦{{response_transfer.accountLedgerBalance}} </span></div></div></div></div><!----></div>
                                                  <div class="notification-modal-actions">
                                                      <p class="notification-modal-action full text-center">
                                                          <button @click="modals.modal3=false" class="btn btn-primary btn-tragedy only"> close </button>
                                                          </p></div></div>

      </div>
  </div>
    <!---</card>
                                                </div></div>-->

               </div>
            
        </modal>

        </div>
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import { mapState, mapActions, mapGetters } from "vuex";
export default {

    components: {
        BaseHeader,
      },
    data() {
      return {
          //selected_transferCode:{},
          //option_bank:[{value:'00001',label:'VI'},{value:'00002',label:'Ikeja'}],
          accountNumber:'',
          inputRequired:true,
          modals:{
              modal3:false
              },
  result:false

      }
    },
    methods: {
       resetForm(){
            //console.log(`>>><<<${this.nameCtrl}`);
            this.inputRequired=true;
        },
      submit() {
         this.$store.dispatch("balanceEnquiry",{
          accountBankCode: this.userInformation.userBankCode,
          accountNumber: this.accountNumber
    }, { root: false });
    document.getElementById("submitForm").reset();
      },
     
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    },
    watch:{
    
    error_transfer(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },
    success_transfer(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        this.modals.modal3 = true;
        this.result = true;
        //this.showDismissibleAlert=true;
      }
    }
    },
   mounted:function (){
    
    },
    computed:{
           ...mapState({
              userInformation: state => state.auth.userInfo,
        response_user: state => state.user.user_response,
    response_transfer: state => state.transfer.transfer_response, loading_transfer: state => state.transfer.transfer_loading,
    success_transfer: state => state.transfer.transfer_success,error_transfer: state => state.transfer.transfer_errors,
    error_user: state => state.user.user_error
    
    }),
    ...mapActions(['userRead']),
    ...mapGetters([""]),
    
    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
.item-right {
   padding-left: 90%; 
  cursor: pointer;
  margin:0px 0% 5px 0px;
    float:right!important;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}

.notification-modal-inner {
    padding: 20px;
}
.transactional-notification .title {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 800;
    color: #9E9E9E;
}
 .cen{
   display: flex;
  justify-content: center;
  margin-bottom: 15px;
 }

 .icon {
    text-align: center !important;
    /*margin: 0 auto !important;*/
    float:center!important;
    margin-bottom: 100px;
    
}
.transactional-notification .cen span.avatar {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    background-color: #1e222e;
    display: inline-block;
    text-align: center;
    padding: 35px 0;
    font-size: 40px;
    line-height: 50px;
    color: #ffffff;
}
.transactional-notification .transaction-info {
    margin-bottom: 20px;
}
.transactional-notification .info-card {
    margin-bottom: 3px;
}
.transactional-notification .transaction-info .title {
    text-align: right;
    display: block;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #9E9E9E;
    margin-bottom: 8px;
}
.transactional-notification .transaction-info .value {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 18px;
    color: #1f232e;
}
</style>
