<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <i @click="goBack()" class="mr-2 pointer fas fa-arrow-left"></i>
          <h6 class="h2 d-inline-block mb-0">Create {{selectedProduct(response_product_select)}} Product
</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Product</a></li>
              <li class="breadcrumb-item active" aria-current="page">Create {{selectedProduct(response_product_select)}} Product
</li>
            </ol>
          </nav>
        </div>
        <!---<div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>--->
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
            <div v-if="product_data.length!==0">
              <card>
    <!-- Card header -->
    <!--<h3 slot="header" class="mb-0">Select Product Type</h3>-->
          <form ref="createForm" id="createProductForm"
          @submit.prevent="submit">
<div class="row">
<!------------------------------Line 1-------------------------------------->
           <div  v-for="(prod, templateSequence) in product_data"
                  :key="templateSequence" class="col-lg-6 col-md-6 col-sm-6 col-12">
<div class="form-group">
    <label class="form-control-label"> {{prod.templateLabel}}</label>
    <div class="has-label"><!---->
    <div v-if="prod.templateDataType==='NUMBER'">
    <input type="number" :name="prod.templateDescription" :placeholder="prod.templateLabel" class="form-control" required><!---->
    </div>
    <div v-else-if="prod.templateDataType==='FLOAT'">
    <input type="number" step=0.01 :name="prod.templateDescription" :placeholder="prod.templateLabel" class="form-control" required><!---->
    </div>
    <div v-else-if="prod.templateDataType==='STRING'">
     <input type="text" :name="prod.templateDescription" :placeholder="prod.templateLabel" class="form-control" required><!---->
    </div>
    <div v-else>
    <select class="form-control" :placeholder="prod.templateLabel" :name="prod.templateDescription" required>
         <option>Select {{prod.templateLabel}}</option> 
        <option  v-for="(item,index) in selectOption(prod.templateDescription)" :key="index" :value="item">{{item}}</option>
   
    </select>
    
    </div>
    </div><!----><!----></div>

        </div>
<!------------------------------End of Line 1-------------------------------------->


  </div>

  <div class="text-right">
          <base-button icon type="primary" native-type="submit" >Create {{selectedProduct(response_product_select)}} Product
          </base-button>
  </div>
  </form>
              </card>
  </div>
    <div v-else>
              <LoadingPanel></LoadingPanel>
          </div>
        </div>
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import { Select, Option, DatePicker } from 'element-ui';
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
export default {

    components: {
        BaseHeader,
       LoadingPanel,
        //vSelect,
        //[Select.name]: Select,
        //[Option.name]: Option
    },
    data() {
      return {
          option_product:[{value:"Yes",label:"Yes"},{value:"No",label:"NO"}],
          option_products:[{value:"Yes",text:"Yes"},{value:"No",text:"NO"}],
          selected:'',
          edit:"phone",
          product_data:[],
         products:[],
      }
    },
methods:{
  selectedProduct(str){
          var product ="";
          switch(str){case "AC":
               product='Account';
              break;
            case "CH":
               product='Charges';
              break;
              case "FT":
               product='Fund Transfer';
              break;
              case "LO":
               product='Loan';
              break;
              case "IN":
               product='Interest';
              break;
          }
          return product;
      },

selectOption(id){
    let option_obj=this.product_data.find(obj => ((obj.templateDescription === id)));
    let optionsList=option_obj.templateList.split(",");
    return optionsList;
},

 notifyVue(type = "default", msg) {
      console.log(type);
      this.$notify({
        message: `<b>${msg}</b>`,
        timeout: 5000,
        icon: "ni ni-bell-55",
        type,
      });
    },
    resetForm(){
        console.log("test");
    },
    submit(){
        console.log("Submit");
        this.products.push({
        bankcode:this.userInformation.userBankCode,
         productname:this.response_product_select,
         userId:this.userInformation.userId,
          key: 'source',
          value:'WEB',
          });
        var formData = new FormData(this.$refs.createForm);
         for(var pair of formData.entries()) {
          this.products.push({
         bankcode:this.userInformation.userBankCode,
         productname:this.response_product_select,
         userId:this.userInformation.userId,
          key: pair[0],
          value:pair[1],
          });
    console.log(pair);
}
console.log(this.products);
this.$store.dispatch("createProduct",{
        requestType:"create",
        products:this.products
    }, { root: false });

    },
    createProduct(prdTemplateCode){
        this.$store.dispatch("productReadTemplate",{
        templateBankCode: this.userInformation.userBankCode,
        templateType: prdTemplateCode
    }, { root: false });
    },
goBack() {
      this.$router.go(-1);
    },
},
   mounted:function (){
console.log(`select ${this.response_product_select}`);

       if(Object.keys(this.response_product).length !== 0){
           this.product_data = this.response_product.data;
       };


    },
    watch:{
        error_product(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },
     success_product(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        document.getElementById("createProductForm").reset();
        //this.showDismissibleAlert=true;
      }
    }

      /*  response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_product).length !== 0){
           this.product_type = this.response_product.data;
           console.log(`Product types >>> ${JSON.stringify(this.product_type)}`);
           console.log(`Product length >>> ${this.product_type.length}`);
    }
        },*/
    },
    
    computed:{
          ...mapState({
               //errors: state => state.role.role_errors,loading: state => state.role.role_loading,
    //success: state => state.role.role_success,
    response_product_select: state => state.product.response_product_selected,
    userInformation: state => state.auth.userInfo,
    response_product: state => state.product.product_view,
 loading_product: state => state.product.product_loading,
    success_product: state => state.product.product_success,error_product: state => state.product.product_errors,
    
    }),
    ...mapActions(['productReadTemplate']),
    ...mapGetters([""]),
    
    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    height: calc(1.5em + 1.25rem + 5px);
    line-height: 1.5;
    width: 100%;
}
.pointer{
  cursor: pointer;
}

.product-type-card {
    cursor: pointer;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    margin-bottom: 15px;
}
.product-type-card .card {
    margin-bottom: 0;
}
.product-type-card-main {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
    font-weight: 600;
}
.product-type-card-sub {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
}
.product-type-card-icon svg {
    height: 45px;
    margin-bottom: 15px;
}
svg {
    overflow: hidden;
}
img, svg {
    vertical-align: middle;
}

</style>
