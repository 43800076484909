<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Customer Transactions</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Transaction</a></li>
              <li class="breadcrumb-item active" aria-current="page">Customer Transactions</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
             <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Customer Transactions</h3>

    <!-- Card body -->
    
    <div>

<!------------------------------Line2-------------------------------->
        <div class="row">
           <div class="col-lg-6 col-md-6 col-sm-6 col-12">
               <label class="form-control-label"> From</label>
           <datetime 
        v-model="model.startDate" input-class="form-control" class="theme-green"
        placeholder="Start Date"  zone="Africa/Lagos" value-zone="Africa/Lagos" :min-datetime="model.minDatetime" :max-datetime="model.maxDatetime"></datetime>
          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                         <label class="form-control-label"> To</label>
           <datetime 
        v-model="model.endDate" input-class="form-control" class="theme-green"
        placeholder="End Date"  zone="Africa/Lagos" value-zone="Africa/Lagos" :min-datetime="model.minDatetime" :max-datetime="model.maxDatetime"></datetime>
        </div>
        </div>
<!------------------------------End of Line2-------------------------------->
<!------------------------------Line1-------------------------------->
        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-9 col-12">
          <base-input label="Account Number"
                      name="Account Number"
                      placeholder="Account Number or Reference"
                      v-model="model.accountNumber">
          </base-input>

        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-12 text-right mt-4">
          <base-button size="lg"  :class="{disabled:loading_transaction}" @click="search" type="primary"><i v-if="!loading_transaction" class="fas fa-search"></i> Search
        <span :class="{'spinner-border': loading_transaction}"></span> </base-button>
        </div>
        </div>
<!------------------------------End of Line1-------------------------------->

    </div>
<div class="search-result-info ">
    <p class="text-center">
        <span>{{title}}</span>
        <span class="sub"> {{records}} records</span></p>
</div>
<br>

<div>
<div v-if="Object.keys(response_transaction).length !== 0">
              
  <!--<b-container class="table-container">--> <!--class="over-flow">-->
  <div class="form-body">
      <div class="table-section col-12">
    <!-- User Interface controls  v-model="sortDirection" -->
    <b-row>
     
      <b-col lg="6" class="my-1">
        <b-form-group
          
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <div>
    <b-table striped hover  
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="lg"
      show-empty
      large
      :head-variant="light"
      @filtered="onFiltered"
    >
    

      <template #cell(trnDrCr)="row">
       <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
        <template >
                    <badge class="badge-dot mr-4" type="">
                      <div v-if="row.value==='CR'">
                        <badge type="success" size="lg">CREDIT</badge>
                        </div>
                        <div v-else>
                        <badge type="danger" size="lg">DEBIT</badge>
                        </div>
                        <!--<i :class="[row.value == 'ACTIVE' ? 'bg-success' : 'bg-danger']"></i>
                        <span class="status">{{row.value}}</span>-->
                    </badge>
                </template>
      </template>

    <template #cell(accountName)="data">
        <span v-for="n in data.value.toString().split(' ')" :key="n" >
              <span>{{n}}</span>
              <br>
          </span>
      </template>

<template #head()="data">
          <span v-for="n in data.label.split(' ')" :key="n" >
              <span class="text-capitalize">{{n}}</span>
              <br>
          </span>
</template>


      
      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>


      

  </div>
  <!--</b-container>-->
          </div>
            </div>
          <div v-else>
            <LoadingPanel></LoadingPanel>
          </div>
          </div>

    <modal modal-classes="modal-sm" :show.sync="modals.modal0">
     <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Success</h5>
     </template>
     <div>
      <h4>Your request is being processed. An email will be sent to {{model.email}} shortly.</h4>
     </div>
     <template slot="footer">
         <base-button type="primary">Ok</base-button>
     </template>
   </modal>
  </card>
  
        </div>
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import { Select, Option, DatePicker } from 'element-ui';
import { Datetime } from 'vue-datetime';
import LoadingPanel from '@/components/LoadingPanel';
import { mapState, mapActions, mapGetters } from "vuex";
export default {

    components: {
        BaseHeader,
        Datetime,
        LoadingPanel
       //vSelect,
        //[Select.name]: Select,
        //[Option.name]: Option
    },
    data() {
      return {
           light:'light',
            items:[],
            fields: [
{ key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'trnAmount', label: 'Transaction amount	', sortable: true, class: 'text-left' },
          { key: 'accountName', label: 'Account Name', sortable: true, class: 'text-left' },
          { key: 'accountNumber', label: 'Account Number', sortable: true, class: 'text-left' },
          { key: 'trnContractReference', label: 'Contract Reference', sortable: true, class: 'text-left' },
          {key: 'trnCounterPartyBankName',label: 'Counterparty Bank Name	',sortable: true,class: 'text-left'},
          {key: 'trnCounterPartyService',label: 'Counterparty Service',sortable: true,class: 'text-left'},
          {key: 'trnCounterpartyBankCode',label: 'Counterparty Bank Code',sortable: true,class: 'text-left'},
          { key: 'trnDate', label: 'Date', sortable: true, class: 'text-left' },
          { key: 'trnDrCr', label: 'DrCr', sortable: true, class: 'text-left' },
          { key: 'trnNarration', label: 'Transaction Narration  ', sortable: true, class: 'text-left' },
          { key: 'source', label: 'Source', sortable: true, class: 'text-left' },
          { key: 'maker', label: 'Maker  ', sortable: true, class: 'text-left' },
         // { key: 'action', label: 'Action' }
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        },
        model: {
            accountNumber:'',
            endDate:'',
            startDate:'',
             minDatetime:'1970-01-01',
           maxDatetime:'',
         
        },
        modals:{
            modal0:false,
        },
        title:'Recent Transactions',
        records:''
      }
    },
    methods: {
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1 
      },
        search(){
            if(this.model.startDate===''&&this.model.endDate===''){
                if(this.model.accountNumber!==''){
            this.$store.dispatch("customerTransactions",{
            bankCode: this.userInformation.userBankCode,
            endDate: "",
            readType: "search",
            searchItem: this.model.accountNumber,
            startDate: ""
       }, { root: false });
            this.title=`"${this.model.accountNumber}" => `
                }
                else{
                  this.notifyVue('danger','Empty dates or account number/reference');  
                }
            }
            else if(this.model.startDate!==''&&this.model.endDate!==''){
                console.log(`start Date>>> ${this.model.startDate}`);
                console.log(`end Date>>> ${this.model.endDate}`);
                var date = this.model.startDate.split('T')[0];
                var date1 = this.model.endDate.split('T')[0];
                if(this.model.accountNumber!==''){
                     this.$store.dispatch("customerTransactions",{
            bankCode: this.userInformation.userBankCode,
            endDate: date1,
            readType: "search",
            searchItem: this.model.accountNumber,
            startDate: date
       }, { root: false });
       this.title=`${this.model.startDate.split('T')[0]} - ${this.model.endDate.split('T')[0]} =>"${this.model.accountNumber.split('T')[0]}" `
                }
                else{
                    var date = this.model.startDate.split('T')[0];
                var date1 = this.model.endDate.split('T')[0];
                    this.$store.dispatch("customerTransactions",{
            bankCode: this.userInformation.userBankCode,
            endDate: date1,
            readType: "search",
            searchItem: "",
            startDate: date
       }, { root: false });
       this.title=`${this.model.startDate.split('T')[0]} - ${this.model.endDate.split('T')[0]} => `
                }
            }
            else{
                this.notifyVue('danger','Empty dates or account number/reference');  
            }
        },
    
    formatDate(str){
        return `${str.split(' ')[2]}-${str.split(' ')[1]}-${str.split(' ')[3]}`;
    },

      
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      
    },
    watch:{
        response_transaction(newValue, oldValue){
         console.log("<><<><><><"+this.response_transaction.data);
      if(Object.keys(this.response_transaction).length !== 0){
          if ('data' in this.response_transaction){
        this.records=this.response_transaction.data.length;
    var data = []; 
    data = this.response_transaction.data;
    console.log("&$$$$$&"+this.response_transaction.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
              sn:count++,
                trnId:item.trnId,
                trnAmount	: `₦${item.trnAmount}`,
                trnCounterPartyService: item.trnCounterPartyService,
                trnDate: item.trnDate,
                trnCounterpartyBankCode: item.trnCounterpartyBankCode,
                trnEventDate: item.trnEventDate,
                trnContractReference: item.trnContractReference,
                logourl:item.logourl,
                trnDrCr: item.trnDrCr,
                accountNumber: item.accountNumber,
                accountName: item.accountName,
                counterPartyAccountNumber: item.counterPartyAccountNumber,
                counterPartyAccountName:item.counterPartyAccountName,
                journalNarration:item.journalNarration,
                trnCounterPartyBankName:item.trnCounterPartyBankName,
                ftCounterPartySwitchCode:item.ftCounterPartySwitchCode,
                trnNarration:item.trnNarration,
                source:item.source,
                bankCode:item.bankCode,
                branchCode:item.branchCode,
                maker:item.maker,
                checker:item.checker,
                bankName:item.bankName,

            };
          });
         }
         else{

             this.items=[];
             this.records='0';

         }

     // this.totalRows = this.items.length;
      //this.items = data;
    }
        },
 

success_transaction(newValue, oldValue) {
     console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
       // this.modals.modal0=true; 
        //successMsg = newValue;
        
      }
    },
error_transaction(newValue, oldValue) {
     console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        //this.showDismissibleAlert=true;
        
      }
    },
    
    },
   mounted:function (){
       this.$store.dispatch("customerTransactions",{
           bankCode: this.userInformation.userBankCode
       }, { root: false });
    
    },
    computed:{//
           ...mapState({
               //errors: state => state.role.role_errors,loading: state => state.role.role_loading,
    //success: state => state.role.role_success,
    userInformation: state => state.auth.userInfo,
    response_transaction: state => state.transaction.transaction_response,
    loading_transaction: state => state.transaction.transaction_loading,
    success_transaction: state => state.transaction.transaction_success,error_transaction: state => state.transaction.transaction_errors,
    
    }),
    ...mapActions(['accountStatementRequest']),
    ...mapGetters([""]),
    
    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    height: calc(1.5em + 1.25rem + 5px);
    line-height: 1.5;
    width: 100%;
}
.search-result-info p {
    color: #546ee6;
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    margin: 20px 0 20px 0;
}
.search-result-info p .sub {
    font-size: 16px;
    line-height: 20px;
    font-weight: 200;
    color: #74788c;
}
.table-section {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    /*max-width: 100%;*/
}
</style>
