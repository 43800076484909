<template>
  <!--<div class="content">
     
    <div class="card">-->
        
      <div>
          <notifications></notifications>
        <card class="no-border-card" body-classes="px-3 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">All Customers Accounts</h3>
          </template>
          <div>
            <div v-if="Object.keys(response).length !== 0">
              
  <!--<b-container class="table-container">--> <!--class="over-flow">-->
  <div class="form-body">
      <div class="table-section col-12">
    <!-- User Interface controls -->
    <div class="row">

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Search"
                      name="search"
                      placeholder="Search"
                      v-model="filter">
          </base-input>
        </div>
</div>
<b-row>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="response.data.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <div>
    <b-table striped hover  
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="lg"
      show-empty
      large
      :head-variant="light"
      @filtered="onFiltered"
    >
      
      <template #cell(action)="row">
                <template>
                    <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-dark">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                      <!--<i class="ni ni-settings"></i>-->
                    </span>
                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                          <a class="dropdown-item" @click="editAccount(row.item, row.index)">
                             <!-- <i class="far fa-edit"></i>-->
                             <i class="fas fa-user-edit"></i>
                             <!--<i class="ni ni-ruler-pencil"></i>-->
                              Edit Account</a>
                            <a class="dropdown-item" @click="showTerminal(row.item, row.index)">
                                <i class="fas fa-user-edit"></i>
                                Assign Terminal</a>
                            <!--<a class="dropdown-item" href="#">Something else here</a>-->
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
      </template>
<template #head()="data">
          <span v-for="n in data.label.split(' ')" :key="n" >
              <span class="h6">{{n}}</span>
              <br>
          </span>
</template>
      <template #cell(accountStatus)="row">
       <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
        <template >
                    <badge class="badge-dot mr-4" type="">
                      <div v-if="row.value==='ACTIVE'">
                        <badge type="success" size="lg">ACTIVE</badge>
                        </div>
                        <div v-else>
                        <badge type="danger" size="lg">{{row.value}}</badge>
                        </div>
                        <!--<i :class="[row.value == 'ACTIVE' ? 'bg-success' : 'bg-danger']"></i>
                        <span class="status">{{row.value}}</span>-->
                    </badge>
                </template>
      </template>
<template #cell(accountType)="row">
       <template >
                    <badge class="badge-dot mr-4" type="">
                      
                        <badge type="primary" size="lg">{{row.value}}</badge>
                   </badge>
                </template>
      </template>
      <template #cell(accountCurrency)="row">
       <template >
                    <badge class="badge-dot mr-4" type="">
                      
                        <badge type="primary" size="lg">{{row.value}}</badge>
                   </badge>
                </template>
      </template>

      <template #cell(accountClass)="row">
       <template >
                    <badge class="badge-dot mr-4" type="">
                      
                        <badge type="primary" size="lg">{{row.value}}</badge>
                   </badge>
                </template>
      </template>

<!-- <template #head(accountNumber)="data">
        <span class="font-weight-bolder text-capitalize">{{ data.label. }}</span>
      </template>-->

      
      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>
<modal :show.sync="modals.modal1"
               body-classes="p-0"
               modal-classes="modal-dialog-centered modal-md">
               <div class="vodal-dialog" style="width: 500px; height: 450px; animation-duration: 300ms;">
                     <article class="item-right" ><!-- @click="displayDetail()">-->
                    <a><span @click="modals.modal1 = false"
            ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="fill:#171717;" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
</span></a></article>
<!---<div class="card">-->
  <div class="card-body">
    <div>
      <div class="view-more-customer-info">
        <div class="view-more-customer-info-wrap">
          <div class="view-more-top">
            <h4 class="card-title mb-4">Edit Customer Account</h4>
            <div class="row">
              <div class="col-sm-6">
                <p class="queue-title">ACCOUNT NUMBER</p>
                <p class="queue-value"> {{model.accountNumber}} </p></div>
                <div class="col-sm-6">
                  <p class="queue-title">ACCOUNT NAME</p>
                  <p class="queue-value"> {{model.accountName}} </p>
                  </div>
                  </div></div>
                        <div class="view-more-base">
                          <div class="table-responsive">
                                                            <form
                                id="edit_account"
                                @click="resetForm"
                                @submit.prevent="submitEdit"
                              >
 
            <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
<base-input label="Account Status">
                      <el-select class="select-danger" clearable filterable
             placeholder="Account Status"
             v-model="model.accountStatus">
    <el-option v-for="option in option_account_status"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value"
               required>
    </el-option>
  </el-select>
  <p v-show="statusCtrl" class="form-error-message"> The Account Status is required </p>
</base-input>
            </div>
 </div>
 <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
<base-input label="Account Class">
                      <el-select class="select-danger" clearable
             placeholder="Account Class"
             v-model="model.accountClass">
    <el-option v-for="option in option_product"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value"
               required
               >
    </el-option>
  </el-select>
  <p v-show="classCtrl" class="form-error-message"> The Account Class is required </p>
                    </base-input>

            </div>
 </div>
                              </form>
                                            </div></div>
                                            <p class="text-right">
                            <span>
                              <span class="authorize-btn"
                                ><button
                                  :class="{ disabled: loading }"
                                  type="submit"
                                  form="edit_account"
                                  class="btn btn-primary mr-1"
                                >
                                  <i v-if="!loading" class="fas fa-check"></i
                                  >Submit
                                  <span
                                    :class="{ 'spinner-border': loading }"
                                  ></span>
                                </button> </span
                            ></span>
                          </p>
                                            </div></div></div>
  </div>
    <!---</card>
                                                </div></div>-->

               </div>
            
        </modal>


<modal :show.sync="modals.modal3"
               body-classes="p-0"
               modal-classes="modal-dialog-centered modal-md">
               <div class="vodal-dialog" style="width: 500px; height: 450px; animation-duration: 300ms;">
                     <article class="item-right" ><!-- @click="displayDetail()">-->
                    <a><span @click="modals.modal3 = false"
            ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="fill:#171717;" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
</span></a></article>
<!---<div class="card">-->
  <div class="card-body">
    <div>
      <div class="view-more-customer-info">
        <div class="view-more-customer-info-wrap">
          <div class="view-more-top">
            <h4 class="card-title mb-4">Assign Terminal To Customer Account</h4>
            </div>
                        <div class="view-more-base">
                          <div class="table-responsive">
                            <form id="form_update" @click="resetForm()"
          @submit.prevent="assignTerminal ">
                                <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Serial Number"
                      name="Serial Number"
                      placeholder="Serial Number"
                      :required="inputRequired"
                      v-model="model.serialNumber">
          </base-input>
        </div>
                                </div>

                            </form>
                       </div></div>
                    <p class="text-right"><span>
                <span class="authorize-btn"><button  :class="{disabled:loading}" type="submit" form="form_update" class="btn btn-primary mr-1"><i v-if="!loading" class="fas fa-check"></i>Update
                         <span :class="{'spinner-border': loading}"></span> </button>
                                    </span></span></p>
                                            </div></div></div>
  </div>
    <!---</card>
                                                </div></div>-->

               </div>
            
        </modal>
      
</div>
  </div>
  <!--</b-container>-->
          </div>
          <div v-else>
            <LoadingPanel></LoadingPanel>
          </div>
          </div>
          </card>
      </div>
   <!-- </div>
    
  </div>-->
</template>

<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
import country from '@/components/country';
import Loader from '@/components/Loader';
import BaseInput from '../../components/Inputs/BaseInput.vue';

  export default {
    components: { 
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      LoadingPanel,
        BaseInput,
      //Loader
      
    },
    data() {
      return {
          option_account_status:[{value:'ACTIVE',label:'ACTIVE'},{value:'CLOSE',label:'CLOSE'}],
          option_user:[],
          option_country:[],
           option_product:[],
            option_gl:[],
          light:'light',
         inputRequired:true,
         statusCtrl:false,
         classCtrl:false,
         model:{
        serialNumber:'',
        accountClass:'',
        accountStatus:''
},
        modals:{
          modal3:false,
          modal0:false,
          modal1:false,
        },
        items:[],
        fields: [
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'accountNumber', label: 'Account No.', sortable: true, class: 'text-left' },
          { key: 'accountBalance', label: 'Account Balance', sortable: true, class: 'text-left' },
          { key: 'accountLedgerBalance', label: 'Account LedgerBalance', sortable: true, class: 'text-left' },
          { key: 'accountName', label: 'Account Name', sortable: true, class: 'text-left' },
          {key: 'accountCustomerId',label: 'Account CustomerId',sortable: true,class: 'text-left'},
          {key: 'accountClass',label: 'Account Class',sortable: true,class: 'text-left'},
          {key: 'accountStatus',label: 'Account Status',sortable: true,class: 'text-left'},
          { key: 'accountType', label: 'Account Type', sortable: true, class: 'text-left' },
          { key: 'accountCreatedDate', label: 'Account CreatedDate  ', sortable: true, class: 'text-left' },
          { key: 'accountBranchCode', label: 'Account BranchCode', sortable: true, class: 'text-left' },
          {key: 'accountLastAccessTimestamp',label: 'Account LastAccessTimestamp',sortable: true,class: 'text-left'},
          {key: 'accountCurrency',label: 'Account Currency',sortable: true,class: 'text-left'},
          {key: 'accountGlCategory',label: 'Account GlCategory',sortable: true,class: 'text-left'},
          
          {key: 'accountGlLevel1Code',label: 'Account GlLevel1Code',sortable: true,class: 'text-left'},
          { key: 'accountGlLevel2Code', label: 'Account GlLevel2Code', sortable: true, class: 'text-left' },
          { key: 'action', label: 'Action' }
      
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
       ...mapState({errors: state => state.account.account_errors,loading: state => state.account.account_loading,
    success: state => state.account.account_success,userInformation: state => state.auth.userInfo,
    response: state => state.account.account_response,response_user: state => state.user.user_response,
    errors1: state => state.account.account_errors1,
    success1: state => state.account.account_success1,
    response_product: state => state.product.product_response, error_product: state => state.product.product_errors,
   response_glL2: state => state.product.gl_response,
    }),
    ...mapActions(['accountUpdate']),
    ...mapGetters(["getaccountResponse"]),
    
    },
    /*mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },*/
    methods: {
        showTerminal(item,index){
            this.modals.modal3 = true;
             let account_obj=this.response.data.find(obj => ((obj.accountNumber === item.accountNumber)));
        this.model.accountClass = account_obj.accountClass;
        this.model.accountStatus = account_obj.accountStatus;
        this.model.accountNumber = account_obj.accountNumber;
        //this.model=account_obj;

        },
        assignTerminal(){
           var payload= {accountNumber: this.model.accountNumber,
            bankCode: this.userInformation.userBankCode,
            serialNumber: this.model.serialNumber,
            source: "CBA"};
this.$store.dispatch("createTerminal",payload, { root: false });
            this.inputRequired=false;
        },
         resetForm(){
            //console.log(`>>><<<${this.nameCtrl}`);
            this.inputRequired=true;
        },
        submitForm(){
     // alert('this.model.username');
     if(this.validateSelect()){
        this.inputRequired=false;
         this.$store.dispatch("branchUpdate", this.model, { root: false });
        /* this.model={
          branchID:"",
         branchCode:"",
         branchBankCode:"",
         branchLocation:"",
         branchAddress:"",
         branchCity:"",
         branchState:"",
         branchType:"",
         branchCategory:"",
         branchManager:"",
         branchRegion:"",
         branchSource:"",
         branchStatus:"",
         branchCreatedBy:"",
         branchCreatedAt:"",
         branchUpdatedAt:"",
         branchTransactionLimit:"",
         branchCountry:"",
         branchName:"",
         branchMaker:""
};*/
     }
      },
validateSelect(){
          if(this.model.accountStatus===""){
              this.statusCtrl=true;
          }
          else{
              this.statusCtrl=false;
          }
          if(this.model.accountClass===""){
              this.classCtrl=true;
          }
          else{
              this.classCtrl=false;
         
          }
          
          return !this.statusCtrl && !this.classCtrl;
      },

        editAccount(item, index){
           this.modals.modal1 = true;
           this.model.accountName = item.accountName;
           let resp= this.$store.getters.getAccountResponse;
        let account_obj=resp.data.find(obj => ((obj.accountNumber === item.accountNumber)));
         this.model.accountClass = account_obj.accountClass;
        this.model.accountStatus = account_obj.accountStatus;
        this.model.accountNumber = account_obj.accountNumber;
        this.model.accountBankCode= account_obj.accountBankCode;
        this.model.accountType= account_obj.accountType;
        console.log(`>>???${JSON.stringify(this.model.accountType)}`);
        //this.model=branch_obj;
        },
         
 submitEdit(){
     if(this.validateSelect()){
       var  payload={
           accountBankCode: this.model.accountBankCode,
accountClass: this.model.accountClass,
accountMaker: this.userInformation.userMaker,
accountNumber: this.model.accountNumber,
accountStatus: this.model.accountStatus,
accountType: this.model.accountType,
auditUser: this.userInformation.userId
};
this.$store.dispatch("accountUpdate",payload, { root: false });

          //console.log(`>>???${JSON.stringify(this.model.cqJson1)}`);
     }
      },
      
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      
    },
    
    watch: {

response_user(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_user).length !== 0){
    var user = [];
    user = this.response_user.data;
    this.option_user = user.map((item) => {
            return {
                value: `(${item.userId}) ${item.userFirstName} ${item.userMiddleName} ${item.userLastName}`,
                label: `(${item.userId}) ${item.userFirstName} ${item.userMiddleName} ${item.userLastName}`
            };
        });
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);
      
    }
        },

    errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('danger',newValue);
        //this.showDismissibleAlert=true;
      }
    },
    errors1(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
        this.notifyVue('danger',newValue);
        //this.showDismissibleAlert=true;
      }
    },
    success1(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
        this.notifyVue('success',newValue);
        this.modals.modal1 = !true;
        this.$store.dispatch("accountRead", {
        accountBankCode: this.userInformation.userBankCode,
        readAll: "NO"
     
              } , { root: false });      //this.items = data;
   


        //state.branch_response = dataObj;
        //this.$store.dispatch("updatedBranch",dataObj, { root: false });

        /*this.$store.dispatch("branchReadAll", {
        branchBankCode: this.userInformation.userBranchCode,
        readAll: "YES",
        source: "CBA"
     
              } , { root: false });*/
console.log("Updated");
        //this.showDismissibleAlert=true;
      }
    },

response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_product).length !== 0){
    var product = [];
    product = this.response_product.data;
    this.option_product = product.map((item) => {
            return {
                value: `${item.Code}`,
                label: `${item.Code} (${item.Description})`
            };
        });
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);
      
    }
        },
     response_glL2(newValue, oldValue) {
      //console.log(`Updating from Role ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      if(Object.keys(this.response_glL2).length !== 0){
    var gl = [];
    gl = this.response_glL2.data;
    this.option_gl = gl.map((item) => {
            return {
                value: item.glLevel2Description,
                label: item.glLevel2Description
            };
        });
 //       console.log('//////////');
//console.log("&$$$$$&"+this.option_role);
      
    }
   
        },


    success(newValue, oldValue) {
      console.log(`Success from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('success',`${newValue}`);
          this.modals.modal3=false;
         this.modals.modal0=false;
         
        //this.showDismissibleAlert=true;
      }
    },
    sucess_reset(newValue, oldValue){
      console.log(`%%%%%%Updating from ${oldValue} to ${newValue}`);
      if (newValue !== null) {
    this.reset_resp = newValue;
         this.modals.modal1=true;
      }
    
    },
    response(newValue, oldValue){
         console.log("<><<><><><"+this.response.data);
      if(Object.keys(this.response).length !== 0){
         
    var data = []; 
    data = this.response.data;
    console.log("&$$$$$&"+this.response.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                accountNumber:item.accountNumber,
                accountBalance	: '₦ '+item.accountBalance,
                accountLedgerBalance: '₦ '+item.accountLedgerBalance,
                accountName: item.accountName,
                accountCustomerId: item.accountCustomerId,
                accountClass:item.accountClass,
                accountStatus: item.accountStatus,
                accountType:item.accountType,
                accountCreatedDate:item.accountCreatedDate,
                accountBranchCode:item.accountBranchCode,
                accountLastAccessTimestamp:item.accountLastAccessTimestamp,
                accountCurrency:item.accountCurrency,
                accountGlCategory:item.accountGlCategory,
                accountGlLevel1Code:item.accountGlLevel1Code,
                accountGlLevel2Code:item.accountGlLevel2Code
     
            };
          });

     // this.totalRows = this.items.length;
      //this.items = data;
    }
    }
   
  },
    updated() {
        //this.data = this.$store.getters.getPrivilageResponse;
        //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
    },
    created:function (){
    },
    mounted:function (){
  this.$store.dispatch("accountRead", {
accountBankCode: this.userInformation.userBankCode,
readAll: "NO"
     
              } , { root: false });


let countries = country;
    this.option_country = countries.map((item) => {
            return {
                value: `${item.name}`,
                label: `${item.name}`
            };
        });
  this.$store.dispatch("userRead", {
              readAll: "YES",
              source:'CBA',
              userBankCode:this.userInformation.userBankCode,
              } , { root: false });
              
let payload = {
            product:{
                productBankCode: this.userInformation.userBankCode,
                productType: "AC"
            },
            gl:{
                glLevel2BankCode: this.userInformation.userBankCode,
                readAll: "NO"
            }
        };

    this.$store.dispatch("productRead",payload, { root: false });
    
   
    },
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.table-section {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    /*max-width: 100%;*/
}


.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.item-right {
   padding-left: 90%; 
  cursor: pointer;
  margin:0px 0% 5px 0px;
    float:right!important;
}
 .vodal-dialog {
    overflow-y: scroll;
    overflow-x: hidden !important;
    background-color: #fff;
}
.vodal-dialog .card {
    box-shadow: none !important;
}
body{
  
  overflow-x: hidden !important;
}
.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.view-more-customer-info {
    color: #545b62;
}
.view-more-customer-info .view-more-top {
    margin-bottom: 10px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
p{
    color: #495057;
}
.view-more-customer-info p {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 0;
}
.view-more-top .queue-value {
    font-size: 14px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 20px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
.view-more-customer-info {
    color: #545b62;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #495057;
}
.table-section tbody {
    max-width: 500px;
    overflow-x: scroll;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8f9fa;
}
.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #eff2f7;
}
.view-more-base .view-more-base-tag {
    margin-bottom: 0;
}

.view-more-base .view-more-base-value {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 16px;
    color: #556ee6;
    font-weight: 600;
}
.card-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 600;
}
.view-more-customer-info .view-more-top .customer-bio {
    display: flex;
    margin: 0;
}
.view-more-customer-info .view-more-top .customer-bio .customer-avatar {
    margin-right: 10px;
}
.view-more-customer-info p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
}
.view-more-customer-info .view-more-top {
    margin-bottom: 10px;
}
.view-more-base .view-more-base-value .customer-signature {
    height: auto;
    width: 100%;
    cursor: pointer;
}
.sample-file-download {
    cursor: pointer;
}
.view-more-customer-info .view-more-top .customer-bio .customer-avatar img {
    height: 80px;
    border-radius: 10%;
    cursor: pointer;
}
img, svg {
    vertical-align: middle;
}
.view-more-customer-info .view-more-top .customer-bio .name {
    display: block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 10px;
}
.view-more-customer-info .view-more-top .customer-bio .age {
    display: block;
    margin-bottom: 5px;
}
.view-more-customer-info .view-more-top .customer-bio .status {
    display: inline-block;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    height: calc(1.5em + 1.25rem + 5px);
    line-height: 1.5;
    width: 100%;
}

</style>