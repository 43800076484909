<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Query Report</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">Query Report</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
         
          
          <!--</div>-->
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div v-if="Object.keys(response1).length !== 0">
<!---------------------3------------------------>
   <card v-if="page==='1'">
    <!-- Card header -->
    <h3 slot="header" class="mb-0">3. Query Report Conditions</h3>
    <div class="row">
     <div class="col-12">   
    <label for="" class="form-control-label">Decide the conditions for the columns of the Report.</label>
     </div>
    <div class="col-12 mt-3">
    <code>{{conditions}}</code>
    </div>
    </div>
    <!-- Card body -->
    
    <form id="form1"
          @submit.prevent="addQuery">

          <div class="row form-control-label"><div class="col-2"><label >And/Or</label></div>
          <div class="col-4"><label >Column</label></div><div class="col-2">
              <label >Operator</label></div><div class="col-4"><label >Keyword</label></div></div>

<div class="form-row" v-for="(model, index) in models" :key="index">
    <!------------------------------->
    <div class="col-2">
<base-input v-if="index!==0">
                      <el-select class="select-danger" filterable
             placeholder="Relation" reqiured @change="checkSelectRelation(index)"
             v-model="model.relation">
    <el-option v-for="option in  [{value:'OR',label:'OR'},{value:'AND',label:'AND'}]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="componentCtrl[index].relation" class="form-error-message"> The  is required </p>
                    </base-input>
        </div>
        <!------------------------------->
        <div class="col-4">
<base-input>
                      <el-select class="select-danger" filterable
             placeholder="Column" reqiured  @change="checkSelectColumn(index)"
             v-model="model.column">
    <el-option v-for="option in option_column"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="componentCtrl[index].column" class="form-error-message"> The Column is required </p>
                    </base-input>
        </div>
        <!------------------------------->
         <div class="col-2">
<base-input>
                      <el-select class="select-danger" filterable
             placeholder="Operator" reqiured  @change="checkSelectOperator(index)"
             v-model="model.operator">
    <el-option v-for="option in [{value:'=',label:'='},{value:'<>',label:'<>'},{value:'<',label:'<'},{value:'>',label:'>'},{value:'<=',label:'<='},{value:'>=',label:'>='},{value:'LIKE',label:'LIKE'} ]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
<p v-show="componentCtrl[index].operator" class="form-error-message"> The Operator is required </p>
                    </base-input>
        </div>
<!------------------------------->
        <div class="col-4">
          <base-input
                    name="Keyword"
                      type="text"
                      placeholder="Keyword"
                      v-model="model.keyword"
                      @input="checkSelectKeyword(index)">
                      
          </base-input>
          <p v-show="componentCtrl[index].keyword" class="mt--4 form-error-message"> The Keyword is required </p>
        </div>

      
      </div>
    <p class="text-right mt-3">
                        <span>
                     <button type="submit" class="btn btn-secondary mr-1"> Add New Clause </button></span></p>
    </form>
    <div class="row">
        <div class="col-6 text-left">
          <base-button type="default" @click="backToPage2" >
            Back
     </base-button>
      </div>
      <div class="col-6 text-right">
          <base-button @click="nextPage" icon type="primary" :class="{disabled:loading_report}">
   <span class="btn-inner--text">Next</span>
       <span :class="{'spinner-border': loading_report}"></span>
     </base-button>
     
      </div>
      </div>
  </card>
  <!-------------------------Page Two---------------------->
   <card v-else>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">2.  Set up Report Columns</h3>
    <label for="" class="form-control-label">Decide the columns that will feature in the Queried Report.</label>
    
    <!-- Card body -->
    
    <form>


<div>
    <div class="row">
        <div class="col-8 text-left">
            <label class="col-form-label" >Report Columns</label>
        </div>
        <div class="col-4 text-right mt-2">
                      <div  class="checkbox-row">
                            <div  class="checkbox-container">
                              <label
                                
                                for="save-bene"
                                class="checkbox"
                                ><input
                                 v-bind:value="false"
                                 v-model="radioSelect"
                                  type="checkbox"
                                  id="save-bene"
                                  
                                />
                                <div
                                  
                                  aria-hidden="true"
                                  class="checkbox-box"
                                >
                                  <div
                                    
                                    class="checkbox-outer"
                                  >
                                    <div
                                      
                                      class="checkbox-inner"
                                    ></div>
                                  </div>
                                </div>
                                <span  class="checkbox-label"
                                  >Select All Columns</span
                                ></label
                              >
                            </div>
                          </div>
                  </div>
    </div>
    <div v-if="!radioSelect" class="row">
        <div class="col">
        <b-form-checkbox-group
      v-model="selectedColumn"
      :options="option_column"
      class="mb-3"
      value-field="value"
      text-field="label"
      disabled-field="notEnabled"
    ></b-form-checkbox-group>
</div>
    </div>

      </div>
  
    </form>
    <div class="row">
        <div class="col-6 text-left">
          <base-button type="default" @click="page='1'" >
            Back
     </base-button>
      </div>
      <div class="col-6 text-right">
          <base-button @click="submitCondition" icon type="primary" :class="{disabled:loading_report}">
   <span class="btn-inner--text">Query</span>
       <span :class="{'spinner-border': loading_report}"></span>
     </base-button>
     
      </div>
      </div>
  </card>
  </div>
    <div v-else>
              <LoadingPanel></LoadingPanel>
          </div>
           <modal modal-classes="modal-sm" :show.sync="modals.modal0">
     <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
     </template>
     <div>
       <form id="form_reason" @submit.prevent="confirmCondition">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <label for="" class="form-control-label" v-if="conditions.length>0">Conditions: {{conditions}}</label>
          <label for="" class="form-control-label" v-else>Proceed with No Conditions?</label>
        </div>
      </div>
       </form>
     </div>
     <template slot="footer">
         <base-button type="default" @click="modals.modal0 = false">Cancel</base-button>
         <base-button :class="{disabled:loading_report}" type="primary" native-type="submit" form="form_reason" >Proceed <span :class="{'spinner-border': loading_report}"></span></base-button>
     </template>
   </modal>
        </div>
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import country from '@/components/country';
//import UserInfo from './UserInfo.vue';
import vSelect from 'vue-select';
import { Select, Option } from 'element-ui';
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
export default {

    components: {
        BaseHeader,
        //UserInfo,
        LoadingPanel,
        //vSelect,
        //[Select.name]: Select,
        //[Option.name]: Option
    },
    data() {
      return {
          //selected_branchCode:{},
          selectedColumn: [],
          radioSelect:false,
         fileCtrl:false,
          accCtrl:false,
          data:[],
          inputRequired:true,
          modals:{
              modal3:false,
              modal0:false
          },
            models: [{
            relation:'',
            column:'',
            operator:'',
            keyword:''
            }],
            componentCtrl:[{
                relation:false,
                column:false,
                operator:false,
                keyword:false
            }],
           option_column:[],
           option_sel_column:[],
           conditions:'',
           page:'1',
           n:0
      }
    },
    methods: {
        allSelected(){
            var arr = [];
            for(let i=0;i<this.option_column.length;i++){
                arr.push(this.option_column[i].value);
            }
            return arr.toString();
        },
        /*addQuery () {
           
          console.log(JSON.stringify(this.models)); 
          if(this.models.length>0){
           let i=this.n++;
              if(this.models[i].operator==='LIKE'){
                  this.conditions+=` ${this.models[i].relation} ${this.models[i].column} ${this.models[i].operator} '%${this.models[i].keyword}%' `;
              }
              else{
                  this.conditions+=` ${this.models[i].relation} ${this.models[i].column} ${this.models[i].operator} '${this.models[i].keyword}' `;
              }
          
      this.models.push({
            relation:'',
            column:'',
            operator:'',
            keyword:''
});
          }
         
          console.log(`%%% ${this.conditions}`);
    },*/
        goBack(){
            this.$router.push({name:'ReportView'});
        },
         /*nextPage(){
            //console.log(`>>><<<${this.nameCtrl}`);
            if(this.conditions===''){
                console.log(">>>>Testing")
                 let j=this.n;
              if(this.models[j].operator==='LIKE'){
                  this.conditions+=` ${this.models[j].relation} ${this.models[j].column} ${this.models[j].operator} '%${this.models[j].keyword}%' `;
              }
              else{
                  this.conditions+=` ${this.models[j].relation} ${this.models[j].column} ${this.models[j].operator} '${this.models[j].keyword}' `;
              }
          
      this.models.push({
            relation:'',
            column:'',
            operator:'',
            keyword:''
});
            }
            this.modals.modal0=true;
        },*/
        confirmCondition(){
            
            this.modals.modal0 = false;
            this.page='2';
        },

nextPage(){
            //console.log(`>>><<<${this.nameCtrl}`);
            if(this.models[0].column && this.models[0].operator && this.models[0].keyword){
            this.conditions = '';
            for(let i = 0;i<this.models.length;i++){
                this.conditions += `${this.models[i].relation} `;
                this.conditions += `${this.models[i].column} `;
                this.conditions += `${this.models[i].operator} `;
                this.conditions += (this.models[i].operator==='LIKE')?`'%${this.models[i].keyword}%' `:`${this.models[i].keyword} `;
            }

            this.modals.modal0=true;}
            else{
                this.notifyVue("danger", 'Select atleast one condition');
            }
        },

    submitCondition(){
        console.log(this.radioSelect)
        this.$store.dispatch("reportReadSingleCondition", {
        readCount: "ALL",
reportBankCode: this.item_report.reportBankCode,
reportCondition: this.conditions,
reportFieldList: (this.radioSelect)?this.allSelected():this.selectedColumn.toString(),
reportName: this.item_report.reportName
    }, { root: false });
    },
      
   checkSelectRelation(i){
          this.componentCtrl[i].relation = (this.models[i].relation==='')?true:false;
      },
      checkSelectColumn(i){
          this.componentCtrl[i].column = (this.models[i].column==='')?true:false;
      },
      checkSelectOperator(i){
          this.componentCtrl[i].operator = (this.models[i].operator==='')?true:false;
      },
      checkSelectKeyword(i){
          this.componentCtrl[i].keyword = (this.models[i].keyword==='')?true:false;
      },
      validate(i){

            console.log(`???//${i}  ${this.componentCtrl[i].column}`);
            var result1 = true,result2 = true,result3 = true,result4 = true;
      if(this.models[i].relation==='' && i!==0){
         this.componentCtrl[i].relation=true;
         result1 = false;
      }
      else{
         this.componentCtrl[i].relation=false;
         result1 = true; 
      }
      if(this.models[i].column===''){
         this.componentCtrl[i].column=true;
         result2 = false;
      }
      else{
         this.componentCtrl[i].column=false;
         result2 = true; 
      }
      if(this.models[i].operator===''){
         this.componentCtrl[i].operator=true;
         result3 = false;
      }
      else{
         this.componentCtrl[i].operator=false;
         result3 = true; 
      }
      if(this.models[i].keyword===''){
         this.componentCtrl[i].keyword=true;
         result4 = false;
      }
      else{
         this.componentCtrl[i].keyword=false;
         result4 = true; 
      }
      console.log(`resultresultresultresult ${result1} && ${result2} && ${result3} && ${result4}`);
      return result1 && result2 && result3 && result4;
        },
        
        addQuery () {
           if(this.validate(this.n)){
          console.log(JSON.stringify(this.models)); 
          if(this.models.length>=0){
           let i=this.n;
              if(this.models[i].operator==='LIKE'){
                  this.conditions+=` ${this.models[i].relation} ${this.models[i].column} ${this.models[i].operator} '%${this.models[i].keyword}%' `;
              }
              else{
                  this.conditions+=` ${this.models[i].relation} ${this.models[i].column} ${this.models[i].operator} '${this.models[i].keyword}' `;
              }
          
      this.models.push({
            relation:'',
            column:'',
            operator:'',
            keyword:''
});
 this.componentCtrl.push({ 
             relation:false,
                column:false,
                operator:false,
                keyword:!true
});

this.n++;
          }
         
          console.log(`%%% ${this.conditions}`);
           }
    },  
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
     
    },
    watch:{
    response1(){
         //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response1).length !== 0){
            if ('columns' in this.response1){
    var column = [];
    column = this.response1.columns;
    this.option_column = column.map((item) => {
            return {
                value: `${item.columnName}`,
                label: `${item.columnName}`
            };
        });
    this.option_sel_column = column.map((item) => {
            return {
                item: `${item.columnName}`,
                name: `${item.columnName}`
            };
        });    
           }
           else{
            this.option_column=[{value:'',label:'No Record'}];
        }
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);
      
    }
    },

        success_report(newValue, oldValue) {
     console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },
    
    error_report(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    }
    },
   mounted:function (){

       this.$store.dispatch("reportReadColumn", {
        reportBankCode: this.item_report.reportBankCode,
        reportName: this.item_report.reportName

    }, { root: false });
       
     this.page='1';
     this.n=0;
        
    },
    computed:{
           ...mapState({
               //errors: state => state.role.role_errors,loading: state => state.role.role_loading,
    //success: state => state.role.role_success,
    userInformation: state => state.auth.userInfo,
   response_report: state => state.report.report_response, loading_report: state => state.report.report_loading,
    success_report: state => state.report.report_success,error_report: state => state.report.report_errors,
    item_report: state => state.report.report_item,
    response1: state => state.report.report_response1,
    error_user: state => state.user.user_error
    
    }),
    ...mapActions(['userRead']),
    ...mapGetters([""]),
    
    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
.queue-title {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
    margin-top: 20px;
    color: #9e9e9e;
}
.queue-value {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 35px;
    color: #2a3042;
}
.table-section {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    /*max-width: 100%;*/
}


.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.item-right {
   padding-left: 90%; 
  cursor: pointer;
  margin:0px 0% 5px 0px;
    float:right!important;
}
 .vodal-dialog {
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fff;
}
.vodal-dialog .card {
    box-shadow: none !important;
}
code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
}
.divider {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
}
.save-bene {
    padding: 7px 0 0;
}
.save-bene1 {
    padding: 7px 0 0;
}

.checkbox-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;
    color: #525f7f;
}
.checkbox-container .checkbox {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
    align-items: center;
}
.checkbox-container input[type=checkbox] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 0;
}
input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}
.checkbox-container .checkbox-box {
    height: 20px;
    display: flex;
    align-items: center;
}
.checkbox-container input[type=checkbox]:checked+.checkbox-box .checkbox-outer {
    background-color: #5e72e4;
}
.checkbox-container .checkbox-outer {
    width: 20px;
    height: 20px;
    position: relative;
    transition: background-color .2s ease-in-out;
    background-color: #e6ebf1;
    border-radius: 50%;
}
.checkbox-container input[type=checkbox]:checked+.checkbox-box .checkbox-inner {
    opacity: 1;
    transform: scale(1);
}
.checkbox-container .checkbox-inner {
    width: 10px;
    height: 8px;
    opacity: 0;
    margin: 6px 0 0 5px;
    transform: scale(1.15);
    transition: all .2s ease-in-out;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAABGdBTUEAALGPC/xhBQAAAcZJREFUSA3NlrtKA0EUhrOSwhQG8dIIsRHSiIWdhXaiGNBSU1n6AEJewDxBnkAfQEXriLXYipUo2HhpVEQEr+t3wo5MDrMTMbPigZ9zmXP+fzbs7CSX+2OLstKL47gf7mUQg+0oiu6z0sohNgTOgDGJZQPZGORbRsnya6LWE1oSgRk4Vx288tOGNcTy4MR6KhPeEQyEVYMN0ppRUL71cwYVRKAEnpSQpEcg/GmAdFfYlb2TTwZ9MiGDtKKETNrIQqwA+7lRsPwVcTELwbolYofVLMTKKLzYKknc7ChG4xxoggMw33GAhqQX12aygbJ3noZx8GqNfRCv+IZYr1r9dlj3zbXW6F63J5JYNrDgGqZeBPJSaJOXp+CaaavRtKgnk/wZP93WTEKtkdJf0b2pOQSugyu8D+D78EoM5EBr20kldy0w3QsONUuS3+LljYyAfKq0ySet5OL11hjqA8eaLckv8RspazUvsW8RwkFwmkLsKst1lPdxdlyDYARcuNhV7ZNcLtzuDaIxcK0EdLrZvZLFAPsEkNvaZVIfttrDhJBOAdfFGv4WN1tGcBY8AmN7BL+6xX88hMAoG1gCN2CfP7ZvZkP/2n8B0rR2l1wYbFEAAAAASUVORK5CYII=);
}
.checkbox-container .checkbox-label {
   margin: 0 0 0 0px;
    font-size: 11px;
    font-weight: 600;
    color: #171717;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    display: flex;
    user-select: none;
}
.checkbox-row{
    grid-gap: 14px 0;
    gap: 14px 0;
    margin: 0px 0 0px;
    padding: 0 0px;
}
</style>
