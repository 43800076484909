import { render, staticRenderFns } from "./JournalOpen.vue?vue&type=template&id=607f8bb9&scoped=true&"
import script from "./JournalOpen.vue?vue&type=script&lang=js&"
export * from "./JournalOpen.vue?vue&type=script&lang=js&"
import style0 from "./JournalOpen.vue?vue&type=style&index=0&id=607f8bb9&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "607f8bb9",
  null
  
)

export default component.exports