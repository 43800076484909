<template>
<div>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">All Reports </h6>        
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Report</a></li>
              <li class="breadcrumb-item active" aria-current="page">All Reports</li>           
              </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
  <div class="row">
    <!--<div class="col-lg-4 col-md-4 col-sm-4 col-12">
        <user-info></user-info>
          </div>-->
         <div class="col-lg-12 col-md-12 col-sm-12 col-12">    

             <card class="no-border-card" body-classes="px-3 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">View Reports</h3>
          </template>
          <div>
            <div v-if="Object.keys(response).length !== 0">
              
  <!--<b-container class="table-container">--> <!--class="over-flow">-->
  <div class="form-body">
      <div class="table-section col-12">
    <!-- User Interface controls -->
     <div class="row mb-2">
 <label for="search" class="form-control-label">Search</label>
        <div class="input-group">
  <input type="text" v-model="filter" placeholder="Search ..." class="form-control" name="search">
  <div class="input-group-append">
    <JsonExcel
  class="btn-outline-default btn btn-primary"
  :data="items"
  :fields="data_fields"
  worksheet="My Worksheet"
  name="filename.xls"
  :before-generate = "startDownload"
  :before-finish   = "finishDownload"
>
  Excel
</JsonExcel>
    <!--<base-button size="md" outline :disabled="loading" @click="csvExport(items)"  class="btn btn-primary"><i class="fas fa-download"></i>Download CSV  <span :class="{'spinner-border': loading}"></span></base-button>-->
  </div>
</div>
</div>
 <!--<div class="row">

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Search"
                      name="search"
                      placeholder="Search"
                      v-model="filter">
          </base-input>
        </div>
downloadExcel
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
            <JsonExcel
  class="btn btn-default"
  :data="items"
  :fields="file_fields"
  worksheet="MyWorksheet"
  name="report.xls"
>
  Export Excel
</JsonExcel>
        </div>
</div>-->
<b-row>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <div>
    <b-table id="mytab" striped hover  
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="lg"
      show-empty
      large
      :head-variant="light"
      @filtered="onFiltered"
    >
    
    <!--<template #table-colgroup="scope">
    <col
      v-for="field in scope.fields"
      :key="field.key"
      :style="{ width: '180px',height:'200px' }"
    >
  </template>-->
  <template #cell(reportName)="row">
                       <div class="name-container" @click="viewInfo(row.item, row.index)">
                    <el-tooltip class="mr-3"  content="View Report info" placement="left">
                    <i class="fas fa-info-circle hand"></i>
                    </el-tooltip>
                    <span v-for="n in row.value.split(' - ')" :key="n">
              <span>{{n}}</span>
              <br>
          </span>
                    
                       </div>
                   
                  </template>
  <!-- A custom formatted header cell for field 'name' -->
      <template #cell(reportCategory)="data">
          <span v-for="n in data.value.split(' ')" :key="n" >
              <span>{{n}}</span>
              <br>
          </span>
</template>
    <template #cell(reportStatus)="row">
       <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
        <template >
                    <badge class="badge-dot" type="">
                      <div v-if="row.value==='ACTIVE'">
                        <badge type="success" size="lg">ACTIVE</badge>
                        </div>
                        <div v-else>
                        <badge type="danger" size="lg">PENDING</badge>
                        </div>
                        <!--<i :class="[row.value == 'ACTIVE' ? 'bg-success' : 'bg-danger']"></i>
                        <span class="status">{{row.value}}</span>-->
                    </badge>
                </template>
      </template> 
     
        <template #cell(action)="row">
            <base-button @click="readColumn(row.item,row.index)" icon type="secondary" :class="{disabled:loading}">
   <span class="btn-inner--text">Query Report</span>
     
     </base-button>
                       <!---<span><button  class="btn btn-sm btn-outline-secondary"> Query Report </button></span>-->
                   
                  </template>


      
      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>


      

  </div>
  <!--</b-container>-->
          </div>
            </div>
          <div v-else>
            <LoadingPanel></LoadingPanel>
          </div>
          

          </div>
          </card>


        </div>
  </div>
   
</div>
</div>
</template>
<script>
import JsonExcel from "vue-json-excel";
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { mapState, mapActions, mapGetters } from "vuex";
import BaseHeader from '@/components/BaseHeader';
import LoadingPanel from '@/components/LoadingPanel';
import XLSX from "json-as-xlsx"
  export default {
    components: { 
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      JsonExcel,
      LoadingPanel,
       
      
    },
    data() {
      return {
         light:'light',
        modals:{
            modal0:false,
        },
        items:[],
       data_fields:{sn:"sn",
                    reportName:"reportName",
                    reportDescription:"reportDescription",
                    reportOwner:"reportOwner",
                    reportCategory:"reportCategory",
                    reportStatus:"reportStatus"
                    },
        fields: [
        { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'reportName', label: 'Name', sortable: true, class: 'text-left' },
          { key: 'reportDescription', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'reportOwner', label: 'Owner', sortable: true, class: 'text-left' },
          { key: 'reportCategory', label: 'Category', sortable: true, class: 'text-left' },
          { key: 'reportStatus', label: 'Status', sortable: true, class: 'text-left' },
          //{ key: 'eodUpdatedAt', label: 'Updated At', sortable: true, class: 'text-left' },
          //{ key: 'eodPercent', label: 'Percentage Done', sortable: true, class: 'text-left' },
         // {key: 'eodStatus',label: 'Status',sortable: true,class: 'text-left'},
          { key: 'action', label: 'Action' }
         
        ],

         file_fields:{
           sn:"sn",
           Name:"reportName",
           Description:"reportDescription",
           Owner:"reportOwner",
           Category:"reportCategory",
           Status:"reportStatus"
         },
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }, 
       ...mapState({errors: state => state.report.report_errors,loading: state => state.report.report_loading,
    success: state => state.report.report_success,userInformation: state => state.auth.userInfo,
    loading1: state => state.report.report_loading1,
    response: state => state.report.report_response,response_user: state => state.user.user_response,
    }),
    ...mapActions(['branchReadAll','branchUpdate']),
    ...mapGetters(["getBranchResponse"]),
    
    },
    /*mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },*/
    methods: {
       csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(","),
        ...arrData.map(item => Object.values(item).join(","))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },

readColumn(item,index){
this.$store.dispatch("readColumn", {
        item:item
      }, { root: false });

},

viewInfo(item,index){
    this.$store.dispatch("viewSingleReport", {
        item:item
    }, { root: false });
},

      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
startDownload(){
        alert('show loading');
    },
    finishDownload(){
        alert('hide loading');
    },


      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      
    },
    
    watch: {
success(newValue, oldValue) {
      console.log(`Success from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('success',`${newValue}`);
         this.modals.modal0 = false;
        //this.showDismissibleAlert=true;
      }
    },
    errors(newValue, oldValue) {
      console.log(`Success from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('danger',`${newValue}`);
         
        //this.showDismissibleAlert=true;
      }
    },
    
    response(newValue, oldValue){
         //console.log("<><<><><><"+this.response.data);
      if(Object.keys(this.response).length !== 0){
         if ('data' in this.response){
    var data = []; 
    data = this.response.data;
    console.log("&$$$$$&"+this.response.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
              sn:count++,
              reportChecker:item.reportChecker,
                reportCreatedAt:item.reportCreatedAt,
                reportBankCode:item.reportBankCode,
                reportStatus:item.reportStatus,
                reportCategory:item.reportCategory,
                reportDescription:item.reportDescription,
                reportUpdatedAt:item.reportUpdatedAt,
                reportName:item.reportName,
                reportId:item.reportId,
                reportOwner:item.reportOwner,
                reportType:item.reportType,

            };
          });
         }
         else{
             this.items = [];
         }

     // this.totalRows = this.items.length;
      //this.items = data;
    }
    }
   
  },
    updated() {
        //this.data = this.$store.getters.getPrivilageResponse;
        //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
    },
    created:function (){
    },
    mounted:function (){
this.$store.dispatch("reportRead", {
reportBankCode: this.userInformation.userBankCode,
readAll: "NO",
reportCategory: "GENERAL SERVICES"
              } , { root: false });

  }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.table-section {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    /*max-width: 100%;*/
}
.hand{
    cursor: pointer;
}


.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.item-right {
   padding-left: 90%; 
  cursor: pointer;
  margin:0px 0% 5px 0px;
    float:right!important;
}
 .vodal-dialog {
    overflow-y: scroll;
    overflow-x: hidden !important;
    background-color: #fff;
}
.vodal-dialog .card {
    box-shadow: none !important;
}
body{
  
  overflow-x: hidden !important;
}
.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.view-more-customer-info {
    color: #545b62;
}
.view-more-customer-info .view-more-top {
    margin-bottom: 10px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
p{
    color: #495057;
}
.view-more-customer-info p {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 0;
}
.view-more-top .queue-value {
    font-size: 14px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 20px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
.view-more-customer-info {
    color: #545b62;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #495057;
}
.table-section tbody {
    max-width: 500px;
    overflow-x: scroll;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8f9fa;
}
.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #eff2f7;
}
.view-more-base .view-more-base-tag {
    margin-bottom: 0;
}

.view-more-base .view-more-base-value {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 16px;
    color: #556ee6;
    font-weight: 600;
}
.card-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 600;
}
.title{
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 800;
    color: #546ee6;
}
.sub{
    margin-bottom: 10px;
}
</style>