<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Create Vault</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Vault</a></li>
              <li class="breadcrumb-item active" aria-current="page">Create Vault</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
         
          
          <!--</div>-->
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <!--<div v-if="option_acc.length!==0">-->
          <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Create Vault</h3>

    <!-- Card body -->
    
    <form @click="resetForm"
          @submit.prevent="submit">
      <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Vault Account Number">
                      <el-select class="select-danger" clearable filterable
             placeholder="Vault Account Number"
             v-model="model.vaultAccountNumber">
    <el-option v-for="option in option_acc"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="accCtrl" class="form-error-message"> The Vault Account Number is Required' </p>
                    </base-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Vault Teller Number">
                      <el-select class="select-danger" clearable filterable
             placeholder="Vault Teller Number"
             v-model="model.vaultTellerNumber">
    <el-option v-for="option in option_teller"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="tellerCtrl" class="form-error-message"> The Vault Teller Number is Required' </p>
                    </base-input>
        </div>

      
      </div>

      <div class="text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_vault}">
     <span class="btn-inner--text">Submit</span>
        <span :class="{'spinner-border': loading_vault}"></span>
     </base-button>
      <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
      </div>
    </form>
  </card>
  <!---</div>
    <div v-else>
              <LoadingPanel></LoadingPanel>
          </div>-->
        </div>
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import country from '@/components/country';
//import UserInfo from './UserInfo.vue';
import vSelect from 'vue-select';
import { Select, Option } from 'element-ui';
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
export default {

    components: {
        BaseHeader,
        //UserInfo,
        //LoadingPanel,
        //vSelect,
        //[Select.name]: Select,
        //[Option.name]: Option
    },
    data() {
      return {
          //selected_branchCode:{},
          option_acc:[],
          option_teller:[],
          tellerCtrl:false,
          accCtrl:false,
          inputRequired:true,
            model: {
            vaultAccountNumber:'',
            vaultTellerNumber:'',
            }
      }
    },
    methods: {
         resetForm(){
            //console.log(`>>><<<${this.nameCtrl}`);
            this.inputRequired=true;
        },
      submit: function(event) {
          console.log(`>>><<<${this.validateSelect()}`);
          if(this.validateSelect()){

          //this.model.branchCreatedBy= this.userInformation.userId;
          //this.model.branchLocation="0000,0000"
this.model.vaultBranchCode=this.userInformation.userBranchCode;
this.model.vaultMaker= this.userInformation.userId;
this.model.vaultBankCode=this.userInformation.userBankCode;
//this.model.requestType="create";
this.model.vaultChecker="SYSTEM";
this.model.source="WEB";
this.model.vaultCurrency="NGN";
       console.log(`>>>>>>> Model ${JSON.stringify(this.model)}`);
          this.$store.dispatch("vaultCreate", this.model , { root: false });
          this.inputRequired=false;

          this.model={
            vaultAccountNumber:'',
            vaultTellerNumber:'',
        };
        
          //event.target.reset();

          }   
      },
      validateSelect(){
          if(this.model.vaultTellerNumber===""){
              this.tellerCtrl=true;
          }
          else{
              this.tellerCtrl=false;
          }
          if(this.model.vaultTellerNumber===""){
              this.accCtrl=true;
          }
          else{
              this.accCtrl=false;
          }
          
          return !this.accCtrl && !this.tellerCtrl;
      },
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    },
    watch:{
        response_internalAcc(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_internalAcc).length !== 0){
           if(this.response_internalAcc.data!== 0){
    var acc = [];
    acc = this.response_internalAcc.data;
    this.option_acc = acc.map((item) => {
            return {
                value: `${item.accountNumber}`,
                label: `${item.accountName} - ${item.accountNumber}`
            };
        });}
        else{
            this.option_acc=[{value:null,label:'No Record'}];
        }
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);
      
    }
        },

 response_teller(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_teller).length !== 0){
           if(this.response_teller.data.length!==0){
    var teller = [];
    teller = this.response_teller.data;
    this.option_teller = teller.map((item) => {
            return {
                value: `${item.tellerNumber}`,
                label: `${item.tellerNumber}`
            };
        });
           }
           else{
            this.option_teller=[{value:null,label:'No Record'}];
        }
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);
      
    }
        },


        success_vault(newValue, oldValue) {
     console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },
    
    error_vault(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    }
    },
   mounted:function (){

    this.$store.dispatch("tellerRead", {
              readAll: "NO",
             tellerBankCode:this.userInformation.userBankCode,
              } , { root: false });

     this.$store.dispatch("internalAccountRead", {
              readAll: "NO",
              accountBankCode:this.userInformation.userBankCode,
              } , { root: false });
    
    
    },
    computed:{
           ...mapState({
               //errors: state => state.role.role_errors,loading: state => state.role.role_loading,
    //success: state => state.role.role_success,
    userInformation: state => state.auth.userInfo,
    //response: state => state.role.role_response, response_read: state => state.role.role_read_response,
    response_teller: state => state.teller.teller_response,
    response_internalAcc: state => state.internalAccount.internal_acc_response,
    response_vault: state => state.vault.vault_response, loading_vault: state => state.vault.vault_loading,
    success_vault: state => state.vault.vault_success,error_vault: state => state.vault.vault_errors,
    error_user: state => state.user.user_error
    
    }),
    ...mapActions(['userRead']),
    ...mapGetters([""]),
    
    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
</style>
