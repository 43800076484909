<template>
<div>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">All Users</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">User</a></li>
              <li class="breadcrumb-item active" aria-current="page">All Users</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="md" @click="show=true" outline class="btn btn-primary"><i class="fas fa-plus"></i>New User</base-button>
          <!--<base-button size="md" type="neutral">New User</base-button>-->
          <!--<base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <user-info></user-info>
          </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        
    <!-- Card header -->
    <!-- Card body -->
   
<PageinatedTables></PageinatedTables>

        </div>
      </div>
      <Loader></Loader>
</div>
<CreateUser @closeCreateUser="updateCreateUser" :showCreateUser="show" ></CreateUser>


</div>
</template>
<script>
import Loader from '@/components/Loader';
import BaseHeader from '@/components/BaseHeader';
import UserInfo from './UserInfo.vue';
import CreateUser from './CreateUser';
import PageinatedTables from './PaginatedTables'
import Card from '../../components/Cards/Card.vue';
export default {

    components: {
        BaseHeader,
        UserInfo,
        PageinatedTables,
        Loader,
        CreateUser
       // Card
      
    },
    data(){
  return{
    show:false
  }
},
  methods: {
       updateCreateUser(value){
   this.show=value;
},
  }
  }
</script>
<style lang="css" scoped>
 .card-head:first-child {
    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}
.card-head {
    padding: 0.25rem 0.5rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
</style>