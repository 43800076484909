<template>
  <div>
    <notifications></notifications>
    <base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <i @click="allRoles()" class=" mr-2 pointer fas fa-arrow-left"></i>
          <h6 class="h2 d-inline-block mb-0">Edit Role Privileges</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item">
                <router-link to="/"><i class="fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item"><a href="#">Role</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Edit Role Privileges
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <card>
            <!-- Card header -->
            <div class="row">
              <div class="col-sm-6">
                <p class="queue-title">Role</p>
                <p class="queue-value">{{response_read.roleName}}</p>
              </div>
              <div class="col-sm-6 text-right">
                <p class="queue-title">Role Maker</p>
                <p class="queue-value">{{response_read.roleMaker}}</p>
              </div>
            </div>

<!--            <h3 slot="header" class="mb-0">Create Role</h3>

             Card body -->
            <div v-if="true">
            <form class="needs-validation" @submit.prevent="submitPrivilege">
              <div class="form-row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <p class="mt-1 mb-1"><code>Assign privilages to role</code></p>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <base-input
                    label="Limit"
                    name="limit"
                    type="number"
                    placeholder="Transaction Limit"
                    required
                    v-model="model.limit"
                   
                  >
                  </base-input>
                </div>
              </div>
              <!--<div class="text-right">
                <base-button
                  id="submit1"
                  icon
                  type="primary"
                  native-type="submit"
                  :class="{ disabled: loading, display: Btn_display }"
                >
                  <span class="btn-inner--text">Submit</span>
                  <span :class="{ 'spinner-border': loading }"></span>
                </base-button>
              </div>-->
              <b-collapse id="collapse-4" v-model="visible" class="mt-2">
                <!--<b-card>-->
                
                <div class="form-row">
                  <!-- <div v-if="priArray.length !== 0">-->
                  <!-----------------Branch------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Branch</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000001"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Branch</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000002"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Branch</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000003"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Branch</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000004"
                        v-model="privilegeId"
                      />
                      <label for="mike">Branch Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------GL------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">GL</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000005"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create GL</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000006"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read GL</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000007"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update GL</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000008"
                        v-model="privilegeId"
                      />
                      <label for="mike">GL Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Customer------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Customer</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000009"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Customer</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000010"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Customer</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000011"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Customer</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000012"
                        v-model="privilegeId"
                      />
                      <label for="mike">Customer Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Internalaccount------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Internalaccount</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000013"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Internalaccount</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000014"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Internalaccount</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000015"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Internalaccount</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000016"
                        v-model="privilegeId"
                      />
                      <label for="mike">Internalaccount Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Teller------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Teller</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000017"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Teller</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000018"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Teller</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000019"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Teller</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000020"
                        v-model="privilegeId"
                      />
                      <label for="mike">Teller Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Till------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Till</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000021"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Till</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000022"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Till</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000023"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Till</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000024"
                        v-model="privilegeId"
                      />
                      <label for="mike">Till Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Vault------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Vault</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000025"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Vault</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000026"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Vault</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000027"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Vault</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000028"
                        v-model="privilegeId"
                      />
                      <label for="mike">Vault Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Report------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Report</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000029"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Report</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000030"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Report</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000031"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Report</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000032"
                        v-model="privilegeId"
                      />
                      <label for="mike">Report Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------User------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">User</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000033"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create User</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000034"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read User</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000035"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update User</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000036"
                        v-model="privilegeId"
                      />
                      <label for="mike">User Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Role------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Role</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000037"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Role</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000038"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Role</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000039"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Role</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000040"
                        v-model="privilegeId"
                      />
                      <label for="mike">Role Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Privilege------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Privilege</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000041"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Privilege</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000042"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Privilege</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000043"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Privilege</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000044"
                        v-model="privilegeId"
                      />
                      <label for="mike">Privilege Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Journal------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Journal</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000045"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Journal</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000046"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Journal</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000047"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Journal</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000048"
                        v-model="privilegeId"
                      />
                      <label for="mike">Journal Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Trncode------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Trncode</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000049"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Trncode</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000050"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Trncode</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000051"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Trncode</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000052"
                        v-model="privilegeId"
                      />
                      <label for="mike">Trncode Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Product------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Product</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000053"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Product</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000054"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Product</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000055"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Product</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000056"
                        v-model="privilegeId"
                      />
                      <label for="mike">Product Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Fundtransfer------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Fundtransfer</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000057"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Fundtransfer</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000058"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Fundtransfer</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000059"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Fundtransfer</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000060"
                        v-model="privilegeId"
                      />
                      <label for="mike">Fundtransfer Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Cashtransaction------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Cashtransaction</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000061"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Cashtransaction</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000062"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Cashtransaction</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000063"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Cashtransaction</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000064"
                        v-model="privilegeId"
                      />
                      <label for="mike">Cashtransaction Checker</label>
                    </b-input-group>
                  </div>
                  <!-----------------Deposit------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Deposit</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000065"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Deposite</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000066"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Deposite</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000067"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Deposite</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000068"
                        v-model="privilegeId"
                      />
                      <label for="mike">Deposite Checker</label>
                    </b-input-group>
                  </div>
                  <!-----------------Withdrawal------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Withdrawal</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000069"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Withdrawal</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000070"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Withdrawal</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000071"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Withdrawal</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000072"
                        v-model="privilegeId"
                      />
                      <label for="mike">Withdrawal Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Transaction------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Transaction</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000073"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Transaction</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000074"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Transaction</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000075"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Transaction</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000076"
                        v-model="privilegeId"
                      />
                      <label for="mike">Transaction Checker</label>
                    </b-input-group>
                  </div>
                  <!-----------------Loan------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Loan</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000077"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Loan</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000078"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Loan</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000079"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Loan</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000080"
                        v-model="privilegeId"
                      />
                      <label for="mike">Loan Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Sms------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Sms</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000081"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Sms</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000082"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Sms</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000083"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Sms</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000084"
                        v-model="privilegeId"
                      />
                      <label for="mike">Sms Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Email------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Email</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000085"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Email</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000086"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Email</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000087"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Email</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000088"
                        v-model="privilegeId"
                      />
                      <label for="mike">Email Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Notification------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Notification</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000089"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Notification</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000090"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Notification</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000091"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Notification</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000092"
                        v-model="privilegeId"
                      />
                      <label for="mike">Notification Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Eod------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Eod</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000093"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Eod</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000094"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Eod</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000095"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Eod</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000096"
                        v-model="privilegeId"
                      />
                      <label for="mike">Eod Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Fixedassets------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Fixedassets</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000097"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Fixedassets</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000098"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Fixedassets</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000099"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Fixedassets</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000100"
                        v-model="privilegeId"
                      />
                      <label for="mike">Fixedassets Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Monthlyfee------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Monthlyfee</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000101"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Monthlyfee</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000102"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Monthlyfee</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000103"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Monthlyfee</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000104"
                        v-model="privilegeId"
                      />
                      <label for="mike">Monthlyfee Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Reversal------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Reversal</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000105"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Reversal</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000106"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Reversal</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000107"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Reversal</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000108"
                        v-model="privilegeId"
                      />
                      <label for="mike">Reversal Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Apiconfiguration------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Apiconfiguration</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000109"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Apiconfiguration</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000110"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Apiconfiguration</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000111"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Apiconfiguration</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000112"
                        v-model="privilegeId"
                      />
                      <label for="mike">Apiconfiguration Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Cards------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Cards</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000113"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Cards</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000114"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Cards</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000115"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Cards</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000116"
                        v-model="privilegeId"
                      />
                      <label for="mike">Cards Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Upload_customer------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Upload_customer</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000121"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Upload_customer</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000122"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Upload_customer</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000123"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Upload_customer</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000124"
                        v-model="privilegeId"
                      />
                      <label for="mike">Upload_customer Checker</label>
                    </b-input-group>
                  </div>

                  <!-----------------Settings------------------------------>
                  <div class="col-sm-12">
                    <p class="module-title mt-1 mb-1">Settings</p>
                  </div>
                  <div class="col-sm-12">
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000117"
                        v-model="privilegeId"
                      />
                      <label for="mike">Create Settings</label>
                    </b-input-group>
                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000118"
                        v-model="privilegeId"
                      />
                      <label for="mike">Read Settings</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000119"
                        v-model="privilegeId"
                      />
                      <label for="mike">Update Settings</label>
                    </b-input-group>

                    <b-input-group class="inline">
                      <input
                        type="checkbox"
                        id="mike1"
                        value="0000000120"
                        v-model="privilegeId"
                      />
                      <label for="mike">Settings Checker</label>
                    </b-input-group>
                  </div>
                  <!---<span>Checked names: {{ privilegeId }}</span>-->

                  <!--<div v-if="priArray.length !== 0">-->

                  <!-----------------Branch------------------------------>

                  <!--<div v-for="privilege in priObj">
          <div class="col-sm-12">
              <p class="module-title mt-1 mb-1"> {{privilege.privilegeModuleName}} </p>
          </div>
        <div class="col-sm-12">-->
                  <!-- <input type="checkbox" id="mike" value="Mike" v-model="privilegeId">
<label for="mike"> {{privilege.privilegeType +' '+privilege.privilegeModuleName}}</label>-->
                  <!--  <base-input group>
    <base-checkbox class="checkbox-inline custom-control-inline custom-control" v-model="test">
     {{privilege.privilegeType +' '+privilege.privilegeModuleName}}
  </base-checkbox>
    <base-checkbox class="checkbox-inline custom-control-inline custom-control" v-model="privilegeId">
      {{`${privilege.privilegeType}`}} {{`${privilege.privilegeModuleName}`}}
  </base-checkbox>
  <base-checkbox class="checkbox-inline custom-control-inline custom-control" v-model="privilegeId">
     {{`${privilege.privilegeType}`}} {{`${privilege.privilegeModuleName}`}}
  </base-checkbox>
    <base-checkbox class="checkbox-inline custom-control-inline custom-control" v-model="privilegeId">
      {{`${privilege.privilegeType}`}} {{`${privilege.privilegeModuleName}`}}
  </base-checkbox>
            </base-input>
            </div>
        </div>-->
                </div>
                <!--</b-card>-->
              </b-collapse>

              <div class="text-right">
                <!--<input type="submit" value="Submit">-->
                <base-button :class="{disabled:loading}"
                  id="submit2"
                  icon
                  type="primary"
                  native-type="submit"
                  
                >
                  <span class="btn-inner--text">Submit</span>
                  <span :class="{'spinner-border': loading}"></span>
                </base-button>
              </div>
            </form>
            </div>
            <div v-else>
                <LoadingPanel></LoadingPanel>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";
//import Loader from "@/components/Loader";
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
//import Loaders from "../../components/Loader.vue";
export default {
  components: {
    BaseHeader,
    // Loaders
    LoadingPanel
  },

  data() {
    return {
      data: {},
      //test:false,
      //selected:'',
      privilegeId: [],
      visible:true,
      //Btn_display:false,
      model: {
        roleName: "",
        limit: "",
      },
    };
  },
  methods: {
    async submitPrivilege() {
      if (this.privilegeId.length !== 0) {
        var payload = {
          rpPrivilegeId: [],
        };
        var privilegeObj = {};
        //var newArr = [];
        for (let i = 0; i < this.privilegeId.length; i++) {
          privilegeObj = {
            privilegeId: this.privilegeId[i],
          };
          payload["rpPrivilegeId"].push(privilegeObj);
        }
        payload.rpRoleId = this.response_read.roleId;
        payload.rpMaker = this.response_read.roleMaker;
        payload.rpBankCode = this.response_read.roleBankCode;
        payload.roleTrnLimit = this.model.limit;
        payload.action='edit';
        console.log(`((((((((((()))))))))))  ${JSON.stringify(payload)}`);
        this.$store.dispatch("userRolePrivilegeCreate", payload, {
          root: false,
        });
        this.model.roleName = "";
        this.model.limit = "";
        this.privilegeId = [];
      } else {
        this.notifyVue("danger", "Please select at least one privilege");
      }
    },
    async submit() {
      console.log("<<<<<<<<<<<<<{{{{}}}}>>>>>>>>>>>>>>>");
      var payload = {};
      payload.roleName = this.model.roleName;
      payload.roleMaker = this.userInformation.role.roleMaker;
      payload.roleBankCode = this.userInformation.role.roleBankCode;
      payload.roleTrnLimit = this.model.limit;
      //console.log(`>>>>>>>>>>>>>>>${JSON.stringify(newArr)}`);
      console.log(`???????????${JSON.stringify(this.userInformation.role)}`);
      console.log(`All length ${JSON.stringify(this.priObj.responseCode)}`);
      console.log(`///////Payload ${JSON.stringify(payload)}`);
      this.$store.dispatch("roleCreate", payload, { root: false });
    },
    allRoles(){
      this.$router.push({name:'All Role'});
    },
    
    notifyVue(type = "default", msg) {
      console.log(type);
      this.$notify({
        message: `<b>${msg}</b>`,
        timeout: 5000,
        icon: "ni ni-bell-55",
        type,
      });
    },
    async callapi() {
        if(Object.keys(this.response_read).length !== 0){
        var privilegeData = [];
        var privilege_data = [];
        privilegeData= this.response_read.privileges; 
        for(var i=0;i<privilegeData.length;i++){
            privilege_data[i]=privilegeData[i].privilegeId;
        }
        this.privilegeId=privilege_data;
    
    console.log("&$$$$$&"+this.privilegeId);}
     /* this.$store.dispatch(
        "privilageRead",
        {
          readAll: "YES",
        },
        { root: false }
      );*/
    },
    getUserInformation() {
      this.data = this.$store.getters.getPrivilageResponse;
    },
    setValue() {
      this.data = this.$store.getters.getPrivilageResponse;
      console.log("&$$$$$&" + this.data.data[0].privilageModuleName);
    },
  },
  computed: {
    ...mapState({errors: state => state.role.role_errors,loading: state => state.role.role_loading,
    success: state => state.role.role_success,userInformation: state => state.auth.userInfo,
    response: state => state.role.role_response, response_read: state => state.role.role_read_response}),
    ...mapActions(['roleRead','roleReadSingle']),
    ...mapGetters(["getPrivilageResponse"]),
    
    },
   

  watch: {
response_read(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        if(Object.keys(this.response_read).length !== 0){
         if ('privileges' in this.response_read){
          var privilegeData =[];
          privilegeData= this.response_read.privileges; 
        for(var i=0;i<privilegeData.length;i++){
            this.privilegeId[i]=privilegeData[i].privilegeId;
        }
        this.model.limit = this.response_read.roleTrnLimit;
       // this.privilegeId=privilege_data;
    
    console.log("&$$$$$&"+this.privilegeId);}
        //this.notifyVue("danger", newValue);
        //this.showDismissibleAlert=true;
      }}}
  },
    success(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },
    error(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },
    /*Btn_display(newValue, oldValue) {
      this.visible = newValue === true ? true : false;
     
    },*/
  
  updated() {
      //for(var i=0;i<this.response_read.privileges.length;i++){
       //     this.privilegeId[i]=this.response_read.privileges[i].privilegeId;
        //}
    //this.data = this.$store.getters.getPrivilageResponse;
    //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
  },
  created: function () {
      
    //this.$store.dispatch("privilageRead", {
    //     readAll: "YES"} , { root: false });
    //this.data = this.$store.getters.getPrivilageResponse;
    //console.log("&$$$$$&"+this.data[0].privilageModuleName);
  },

  mounted: function () {
    
      //this.totalRows = data.length;
      //this.items = data;
    //}
  },
  beforeCreate: function () {
    //console.log("Hello world");
    //this.$nextTick(function () {
    //this.callapi();
    //});
  },
  //beforeRouteEnter(to, from, next) {
    //ledgerBalance((err, balance) => {
//next(vm => vm.callapi());
    
//},
  /*
  beforeRouteUpdate (to, from, next) {
    //this.post = null
    //getPost(to.params.id, (err, post) => {
     // setValue();
     // next()
   // })
  },*/
};
</script>
<style scoped>
.display {
  display: none;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
label {
  font-weight: 300 !important;
}
.inline {
  display: inline !important;
  margin-right: 1.5rem;
}
.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.21875rem;
  padding-right: 2.5rem;
}
.module-title {
  font-size: 12px !important;
  line-height: 18px;
  font-weight: 700;
  margin-bottom: 5px !important;
  margin-top: 10px !important;
  color: #556ee6;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
.queue-value {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #2a3042;
}
.queue-title{
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
.pointer{
cursor: pointer; }
</style>

