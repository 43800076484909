<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Select Product Type
</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Product</a></li>
              <li class="breadcrumb-item active" aria-current="page">View Product
</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
            <div v-if="product_type.length!==0">
          <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Select Product Type</h3>
    <!-- Card body -->
    <div class="row">
       
        <div  class="col-md-3" v-for="(product_types, prdTemplateId) in product_type"
                  :key="prdTemplateId">
            <div class="product-type-card" @click="viewProduct(product_types.prdTemplateCode)"><div class="card">
                <div class="card-body text-center"><span class="product-type-card-icon">
                    <svg viewBox="0 0 409 512" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M52.1969 451.584C47.6469 448.581 42.1979 446.828 36.3379 446.828C20.4129 446.828 7.50293 459.738 7.50293 475.663C7.50293 491.588 20.4129 504.498 36.3379 504.498H329.178C338.678 504.498 346.378 496.797 346.378 487.298V451.584H52.1969Z" fill="#D5B0EC"></path>
                    <path d="M383.832 467.327H65.1729V28.0918H383.832C393.331 28.0918 401.032 35.7928 401.032 45.2918V450.126C401.032 459.626 393.331 467.327 383.832 467.327Z" fill="#F9F6F6"></path>
                    <path d="M383.832 28.0918H343.172C352.671 28.0918 360.372 35.7928 360.372 45.2918V450.126C360.372 459.625 352.671 467.326 343.172 467.326H383.832C393.331 467.326 401.032 459.625 401.032 450.126V45.2918C401.032 35.7928 393.331 28.0918 383.832 28.0918V28.0918Z" fill="#E7ECF1"></path>
                    <path d="M63.9439 467.327H65.1729V36.337C65.1729 20.412 52.2629 7.50195 36.3379 7.50195C20.4129 7.50195 7.50293 20.412 7.50293 36.337V475.663V475.662C7.50293 459.737 20.4129 446.827 36.3379 446.827C49.3639 446.828 60.3679 455.467 63.9439 467.327V467.327Z" fill="#DAE4EA"></path>
                    <path d="M343.603 173.14H118.013C114.547 173.14 111.737 170.33 111.737 166.864V80.3844C111.737 76.9184 114.547 74.1084 118.013 74.1084H343.603C347.069 74.1084 349.879 76.9184 349.879 80.3844V166.864C349.88 170.33 347.07 173.14 343.603 173.14Z" fill="#CBE2FF"></path>
                    <path d="M343.603 74.1084H309.219V166.864C309.219 170.33 306.409 173.14 302.943 173.14H343.603C347.069 173.14 349.879 170.33 349.879 166.864V80.3844C349.88 76.9184 347.07 74.1084 343.603 74.1084Z" fill="#9DC6FB"></path>
                    <path d="M190.774 414.921H118.013C114.547 414.921 111.737 412.111 111.737 408.645V371.505C111.737 368.039 114.547 365.229 118.013 365.229H190.774C194.24 365.229 197.05 368.039 197.05 371.505V408.645C197.05 412.111 194.24 414.921 190.774 414.921Z" fill="#FC8086"></path>
                    <path d="M349.879 208.651H111.737C107.593 208.651 104.235 212.01 104.235 216.153C104.235 220.296 107.594 223.655 111.737 223.655H349.879C354.023 223.655 357.381 220.296 357.381 216.153C357.381 212.01 354.023 208.651 349.879 208.651Z" fill="black"></path>
                    <path d="M349.879 258.343H111.737C107.593 258.343 104.235 261.702 104.235 265.845C104.235 269.988 107.594 273.347 111.737 273.347H349.879C354.023 273.347 357.381 269.988 357.381 265.845C357.381 261.702 354.023 258.343 349.879 258.343Z" fill="black"></path>
                    <path d="M349.879 308.035H111.737C107.593 308.035 104.235 311.394 104.235 315.537C104.235 319.68 107.594 323.039 111.737 323.039H349.879C354.023 323.039 357.381 319.68 357.381 315.537C357.381 311.394 354.023 308.035 349.879 308.035Z" fill="black"></path>
                    <path d="M349.879 357.727H230.808C226.664 357.727 223.306 361.086 223.306 365.229C223.306 369.372 226.665 372.731 230.808 372.731H349.879C354.023 372.731 357.381 369.372 357.381 365.229C357.381 361.086 354.023 357.727 349.879 357.727V357.727Z" fill="black"></path>
                    <path d="M349.879 407.419H230.808C226.664 407.419 223.306 410.778 223.306 414.921C223.306 419.064 226.665 422.423 230.808 422.423H349.879C354.023 422.423 357.381 419.064 357.381 414.921C357.381 410.778 354.023 407.419 349.879 407.419V407.419Z" fill="black"></path>
                    <path d="M401.031 355.032C405.175 355.032 408.533 351.673 408.533 347.53V45.293C408.533 31.672 397.451 20.59 383.831 20.59H69.083C63.205 8.417 50.736 0 36.338 0C16.301 0 0 16.301 0 36.337L0.000999451 475.404C-5.49364e-07 475.49 0 475.575 0 475.662H0.000999451C0.000999451 495.698 16.301 511.999 36.338 511.999H329.178C342.799 511.999 353.88 500.917 353.88 487.296V474.828H383.831C397.452 474.828 408.533 463.746 408.533 450.125V383.04C408.533 378.897 405.175 375.538 401.031 375.538C396.887 375.538 393.529 378.897 393.529 383.04V450.125C393.529 455.472 389.179 459.823 383.832 459.823H72.676V36.337C72.676 36.088 72.662 35.842 72.657 35.594H383.831C389.178 35.594 393.528 39.945 393.528 45.292V347.53C393.529 351.673 396.888 355.032 401.031 355.032V355.032ZM15.005 36.337C15.005 24.574 24.575 15.004 36.338 15.004C48.101 15.004 57.671 24.574 57.671 36.337V446.316C44.757 436.928 27.622 437.081 15.005 446.263V36.337ZM338.876 487.297C338.876 492.644 334.526 496.995 329.179 496.995H36.338C24.575 496.995 15.006 487.425 15.006 475.662C15.006 451.218 49.719 446.139 56.762 469.493C57.717 472.661 60.635 474.829 63.945 474.829H338.876V487.297Z" fill="black"></path>
                    <path d="M357.382 80.3845C357.382 72.7865 351.201 66.6055 343.604 66.6055H180.825C176.681 66.6055 173.323 69.9645 173.323 74.1075C173.323 78.2505 176.682 81.6095 180.825 81.6095H342.377V165.636H119.24V81.6105H145.752C149.896 81.6105 153.254 78.2515 153.254 74.1085C153.254 69.9655 149.895 66.6065 145.752 66.6065H118.014C110.416 66.6065 104.235 72.7875 104.235 80.3855V166.865C104.235 174.463 110.416 180.643 118.014 180.643H343.604C351.201 180.643 357.382 174.462 357.382 166.865V80.3845Z" fill="black"></path>
                    <path d="M118.014 357.727C110.416 357.727 104.235 363.908 104.235 371.505V408.645C104.235 416.243 110.416 422.423 118.014 422.423H190.774C198.372 422.423 204.553 416.242 204.553 408.645V371.505C204.553 363.907 198.372 357.727 190.774 357.727H118.014ZM189.548 407.419H119.24V372.732H189.548V407.419Z" fill="black"></path></svg></span><p class="product-type-card-main"> {{product_types.prdTemplateDestcription}} </p>
        <p class="product-type-card-sub"> View {{product_types.prdTemplateDestcription.toLowerCase()}} product! </p></div></div></div></div>
                <div  class="col-md-3">
            <div class="product-type-card" @click="viewAccountEntry()"><div class="card">
                <div class="card-body text-center"><span class="product-type-card-icon">
                    <svg viewBox="0 0 409 512" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M52.1969 451.584C47.6469 448.581 42.1979 446.828 36.3379 446.828C20.4129 446.828 7.50293 459.738 7.50293 475.663C7.50293 491.588 20.4129 504.498 36.3379 504.498H329.178C338.678 504.498 346.378 496.797 346.378 487.298V451.584H52.1969Z" fill="#D5B0EC"></path>
                    <path d="M383.832 467.327H65.1729V28.0918H383.832C393.331 28.0918 401.032 35.7928 401.032 45.2918V450.126C401.032 459.626 393.331 467.327 383.832 467.327Z" fill="#F9F6F6"></path>
                    <path d="M383.832 28.0918H343.172C352.671 28.0918 360.372 35.7928 360.372 45.2918V450.126C360.372 459.625 352.671 467.326 343.172 467.326H383.832C393.331 467.326 401.032 459.625 401.032 450.126V45.2918C401.032 35.7928 393.331 28.0918 383.832 28.0918V28.0918Z" fill="#E7ECF1"></path>
                    <path d="M63.9439 467.327H65.1729V36.337C65.1729 20.412 52.2629 7.50195 36.3379 7.50195C20.4129 7.50195 7.50293 20.412 7.50293 36.337V475.663V475.662C7.50293 459.737 20.4129 446.827 36.3379 446.827C49.3639 446.828 60.3679 455.467 63.9439 467.327V467.327Z" fill="#DAE4EA"></path>
                    <path d="M343.603 173.14H118.013C114.547 173.14 111.737 170.33 111.737 166.864V80.3844C111.737 76.9184 114.547 74.1084 118.013 74.1084H343.603C347.069 74.1084 349.879 76.9184 349.879 80.3844V166.864C349.88 170.33 347.07 173.14 343.603 173.14Z" fill="#CBE2FF"></path>
                    <path d="M343.603 74.1084H309.219V166.864C309.219 170.33 306.409 173.14 302.943 173.14H343.603C347.069 173.14 349.879 170.33 349.879 166.864V80.3844C349.88 76.9184 347.07 74.1084 343.603 74.1084Z" fill="#9DC6FB"></path>
                    <path d="M190.774 414.921H118.013C114.547 414.921 111.737 412.111 111.737 408.645V371.505C111.737 368.039 114.547 365.229 118.013 365.229H190.774C194.24 365.229 197.05 368.039 197.05 371.505V408.645C197.05 412.111 194.24 414.921 190.774 414.921Z" fill="#FC8086"></path>
                    <path d="M349.879 208.651H111.737C107.593 208.651 104.235 212.01 104.235 216.153C104.235 220.296 107.594 223.655 111.737 223.655H349.879C354.023 223.655 357.381 220.296 357.381 216.153C357.381 212.01 354.023 208.651 349.879 208.651Z" fill="black"></path>
                    <path d="M349.879 258.343H111.737C107.593 258.343 104.235 261.702 104.235 265.845C104.235 269.988 107.594 273.347 111.737 273.347H349.879C354.023 273.347 357.381 269.988 357.381 265.845C357.381 261.702 354.023 258.343 349.879 258.343Z" fill="black"></path>
                    <path d="M349.879 308.035H111.737C107.593 308.035 104.235 311.394 104.235 315.537C104.235 319.68 107.594 323.039 111.737 323.039H349.879C354.023 323.039 357.381 319.68 357.381 315.537C357.381 311.394 354.023 308.035 349.879 308.035Z" fill="black"></path>
                    <path d="M349.879 357.727H230.808C226.664 357.727 223.306 361.086 223.306 365.229C223.306 369.372 226.665 372.731 230.808 372.731H349.879C354.023 372.731 357.381 369.372 357.381 365.229C357.381 361.086 354.023 357.727 349.879 357.727V357.727Z" fill="black"></path>
                    <path d="M349.879 407.419H230.808C226.664 407.419 223.306 410.778 223.306 414.921C223.306 419.064 226.665 422.423 230.808 422.423H349.879C354.023 422.423 357.381 419.064 357.381 414.921C357.381 410.778 354.023 407.419 349.879 407.419V407.419Z" fill="black"></path>
                    <path d="M401.031 355.032C405.175 355.032 408.533 351.673 408.533 347.53V45.293C408.533 31.672 397.451 20.59 383.831 20.59H69.083C63.205 8.417 50.736 0 36.338 0C16.301 0 0 16.301 0 36.337L0.000999451 475.404C-5.49364e-07 475.49 0 475.575 0 475.662H0.000999451C0.000999451 495.698 16.301 511.999 36.338 511.999H329.178C342.799 511.999 353.88 500.917 353.88 487.296V474.828H383.831C397.452 474.828 408.533 463.746 408.533 450.125V383.04C408.533 378.897 405.175 375.538 401.031 375.538C396.887 375.538 393.529 378.897 393.529 383.04V450.125C393.529 455.472 389.179 459.823 383.832 459.823H72.676V36.337C72.676 36.088 72.662 35.842 72.657 35.594H383.831C389.178 35.594 393.528 39.945 393.528 45.292V347.53C393.529 351.673 396.888 355.032 401.031 355.032V355.032ZM15.005 36.337C15.005 24.574 24.575 15.004 36.338 15.004C48.101 15.004 57.671 24.574 57.671 36.337V446.316C44.757 436.928 27.622 437.081 15.005 446.263V36.337ZM338.876 487.297C338.876 492.644 334.526 496.995 329.179 496.995H36.338C24.575 496.995 15.006 487.425 15.006 475.662C15.006 451.218 49.719 446.139 56.762 469.493C57.717 472.661 60.635 474.829 63.945 474.829H338.876V487.297Z" fill="black"></path>
                    <path d="M357.382 80.3845C357.382 72.7865 351.201 66.6055 343.604 66.6055H180.825C176.681 66.6055 173.323 69.9645 173.323 74.1075C173.323 78.2505 176.682 81.6095 180.825 81.6095H342.377V165.636H119.24V81.6105H145.752C149.896 81.6105 153.254 78.2515 153.254 74.1085C153.254 69.9655 149.895 66.6065 145.752 66.6065H118.014C110.416 66.6065 104.235 72.7875 104.235 80.3855V166.865C104.235 174.463 110.416 180.643 118.014 180.643H343.604C351.201 180.643 357.382 174.462 357.382 166.865V80.3845Z" fill="black"></path>
                    <path d="M118.014 357.727C110.416 357.727 104.235 363.908 104.235 371.505V408.645C104.235 416.243 110.416 422.423 118.014 422.423H190.774C198.372 422.423 204.553 416.242 204.553 408.645V371.505C204.553 363.907 198.372 357.727 190.774 357.727H118.014ZM189.548 407.419H119.24V372.732H189.548V407.419Z" fill="black"></path></svg></span><p class="product-type-card-main"> Accounting Entry </p>
        <p class="product-type-card-sub"> View Account Entry product! </p></div></div></div></div>
    </div>

  </card>
  </div>
    <div v-else>
              <LoadingPanel></LoadingPanel>
          </div>
        </div>
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
export default {

    components: {
        BaseHeader,
       LoadingPanel,
        //vSelect,
        //[Select.name]: Select,
        //[Option.name]: Option
    },
    data() {
      return {
          product_type:[]
      }
    },
methods:{
    viewProduct(prdTemplateCode){
        this.$store.dispatch("readProductView",{
       productBankCode: this.userInformation.userBankCode,
        productType:prdTemplateCode
    }, { root: false });
    },
    viewAccountEntry(){
      this.$router.push('accounting-entry/all');
    }

},
   mounted:function (){
   this.$store.dispatch("productReadTemplate",{
        you: "you"
    }, { root: false });
    
    },
    watch:{

        response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_product).length !== 0){
           this.product_type = this.response_product.data;
           console.log(`Product types >>> ${JSON.stringify(this.product_type)}`);
           console.log(`Product length >>> ${this.product_type.length}`);
    }
        },
    },
    
    computed:{
           ...mapState({
               //errors: state => state.role.role_errors,loading: state => state.role.role_loading,
    //success: state => state.role.role_success,
    userInformation: state => state.auth.userInfo,
    response_product: state => state.product.product_response,
 loading_product: state => state.product.product_loading,
    success_product: state => state.product.product_success,error_product: state => state.product.product_errors,
    
    }),
    ...mapActions(['productReadTemplate','readProductView']),
    ...mapGetters([""]),
    
    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    height: calc(1.5em + 1.25rem + 5px);
    line-height: 1.5;
    width: 100%;
}

.product-type-card {
    cursor: pointer;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    margin-bottom: 15px;
}
.product-type-card .card {
    margin-bottom: 0;
}
.product-type-card-main {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
    font-weight: 600;
}
.product-type-card-sub {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
}
.product-type-card-icon svg {
    height: 45px;
    margin-bottom: 15px;
}
svg {
    overflow: hidden;
}
img, svg {
    vertical-align: middle;
}
</style>
