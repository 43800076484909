<template>
  <!--<div class="content">
     
    <div class="card">-->
        
      <div>
          <notifications></notifications>
        <card class="no-border-card" body-classes="px-3 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">All Batches</h3>
          </template>
          <div>
            <div v-if="Object.keys(response).length !== 0">
              
  <!--<b-container class="table-container">--> <!--class="over-flow">-->
  <div class="form-body">
      <div class="table-section col-12">
    <!-- User Interface controls -->
    <div class="row">

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Search"
                      name="search"
                      placeholder="Search"
                      v-model="filter">
          </base-input>
        </div>
</div>
<b-row>
      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="response.data.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <div>
    <b-table striped hover  
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="lg"
      show-empty
      large
      :head-variant="light"
      @filtered="onFiltered"
    >
    
    <!--<template #table-colgroup="scope">
    <col
      v-for="field in scope.fields"
      :key="field.key"
      :style="{ width: '180px',height:'200px' }"
    >
  </template>-->

      <template #cell(batchStatus)="row">
       <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
        <template >
                    <badge class="badge-dot mr-4" type="">
                      <div v-if="row.value==='OPEN'">
                        <badge type="success" size="lg">OPEN</badge>
                        </div>
                        <div v-else>
                        <badge type="danger" size="lg">CLOSED</badge>
                        </div>
                        <!--<i :class="[row.value == 'ACTIVE' ? 'bg-success' : 'bg-danger']"></i>
                        <span class="status">{{row.value}}</span>-->
                    </badge>
                </template>
      </template>
      <template #cell(Status)="row">
          <div v-if="row.value==='OPEN'">
                <template>
                    <el-tooltip placement="left" content="Add to Checkers Queue">
                    <base-button
                    @click.native="checkersQueue(row.item,row.index)"
                    class="edit"
                    type="warning"
                    size="sm"
                    icon
                  >
                  <i class="text-white fas fa-check"></i>
                  <!--<i class=" text-white fas fa-check-square"></i>-->
                  </base-button>
</el-tooltip>
                  <el-tooltip placement="left" content="Add to Entry to Batch ">
                  <base-button
                    @click.native="editItem(row.item,row.index)"
                    class="like btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                  <!--<i class="text-white fas fa-plus-square"></i>-->
                    <i class="text-white far fa-edit"></i>
                  </base-button>
                  </el-tooltip>
                  <el-tooltip placement="left" content="Delete Entry">
                  <base-button
                    @click.native="deleteItem(row.item,row.index)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                  <i class="text-white far fa-trash-alt"></i>
                    <!--<i class="text-white ni ni-fat-remove"></i>-->
                  </base-button>
                  </el-tooltip>
                </template>
                </div>
                <div v-else>
                    <badge type="danger" size="lg">CLOSED</badge>
                </div>
      </template>



      
      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>


      

  </div>
  <!--</b-container>-->
          </div>
            </div>
          <div v-else>
            <LoadingPanel></LoadingPanel>
          </div>
          <modal modal-classes="modal-sm" :show.sync="modals.modal0">
     <template slot="header">
        <h5 class="modal-title text-center" id="exampleModalLabel">Confirmation</h5>
     </template>
     <div class="text-center">
      <h4>Are you sure you want to delete this entry?</h4>
     </div>
     <template slot="footer">
         <base-button type="secondary" @click="modals.modal0 = false">No</base-button>
         <base-button type="primary" @click="deleteEntry">Yes</base-button>
     </template>
   </modal>

          </div>
          </card>
      </div>
   <!-- </div>
    
  </div>-->
</template>

<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';

  export default {
    components: { 
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      LoadingPanel,
       
      
    },
    data() {
      return {
         light:'light',
        modals:{
            modal0:false,
        },
        items:[],
       
        fields: [
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'batchName', label: 'Batch Name', sortable: true, class: 'text-left' },
          { key: 'batchReference', label: 'Batch Reference', sortable: true, class: 'text-left' },
          { key: 'batchStatus', label: 'Batch Status', sortable: true, class: 'text-left' },
          { key: 'batchCreatedAt', label: 'Created At', sortable: true, class: 'text-left' },
          {key: 'batchUpdatedAt',label: 'Updated At',sortable: true,class: 'text-left'},
          { key: 'Status', label: 'Action' }
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
       ...mapState({errors: state => state.journal.journal_errors,loading: state => state.journal.journal_loading,
    success: state => state.journal.journal_success,userInformation: state => state.auth.userInfo,
    response: state => state.journal.journal_response,response_user: state => state.user.user_response,
    }),
    ...mapActions(['branchReadAll','branchUpdate']),
    ...mapGetters(["getBranchResponse"]),
    
    },
    /*mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },*/
    methods: {
        deleteEntry(){
            
              let read =  {
batchBankCode: this.model.batchBankCode,
readAll: "YES"  
              };
        let status = {
              bankCode: this.model.batchBankCode,
                batchReference: this.model.batchReference,
                status: "DELETED"
              } ;
     let payload ={
        status:status,
        read:read
     };

         this.$store.dispatch("updateBatchStatus", payload, { root: false });
        },
deleteItem(item,index){
    this.modals.modal0 = true;
    this.model = item;
},
editItem(item,index){
     this.$store.dispatch("editBatch", {
batchName:item.batchName,
batchReference:item.batchReference,
            }, { root: false });
},


        checkersQueue(item,index){
            this.$store.dispatch("batchClose", {
           batchBankCode: item.batchBankCode,
batchChecker: item.batchChecker,
batchCreatedAt:item.batchCreatedAt,
batchId: item.batchId,
batchMaker: item.batchMaker,
batchName:item.batchName,
batchReference:item.batchReference,
batchStatus:item.batchStatus,
batchUpdatedAt:item.batchUpdatedAt
            }, { root: false });

        },
 
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      
    },
    
    watch: {
success(newValue, oldValue) {
      console.log(`Success from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('success',`${newValue}`);
         this.modals.modal0 = false;
        //this.showDismissibleAlert=true;
      }
    },
    errors(newValue, oldValue) {
      console.log(`Success from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('danger',`${newValue}`);
         
        //this.showDismissibleAlert=true;
      }
    },
    
    response(newValue, oldValue){
         //console.log("<><<><><><"+this.response.data);
      if(Object.keys(this.response).length !== 0){
         if ('data' in this.response){
    var data = []; 
    data = this.response.data;
    console.log("&$$$$$&"+this.response.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
              sn:count++,
                batchName:item.batchName,
                batchReference:item.batchReference,
                batchUpdatedAt:item.batchUpdatedAt,
                batchBankCode:item.batchBankCode,
                batchChecker:item.batchChecker,
                batchMaker:item.batchMaker,
                batchId:item.batchId,
                batchStatus:item.batchStatus,
                Status:item.batchStatus,
                batchCreatedAt:item.batchCreatedAt
            };
          });
         }
         else{
             this.items = [];
         }

     // this.totalRows = this.items.length;
      //this.items = data;
    }
    }
   
  },
    updated() {
        //this.data = this.$store.getters.getPrivilageResponse;
        //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
    },
    created:function (){
    },
    mounted:function (){
this.$store.dispatch("batchRead", {
batchBankCode: this.userInformation.userBankCode,
readAll: "YES"
              } , { root: false });

  }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.table-section {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    /*max-width: 100%;*/
}


.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.item-right {
   padding-left: 90%; 
  cursor: pointer;
  margin:0px 0% 5px 0px;
    float:right!important;
}
 .vodal-dialog {
    overflow-y: scroll;
    overflow-x: hidden !important;
    background-color: #fff;
}
.vodal-dialog .card {
    box-shadow: none !important;
}
body{
  
  overflow-x: hidden !important;
}
.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.view-more-customer-info {
    color: #545b62;
}
.view-more-customer-info .view-more-top {
    margin-bottom: 10px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
p{
    color: #495057;
}
.view-more-customer-info p {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 0;
}
.view-more-top .queue-value {
    font-size: 14px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 20px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
.view-more-customer-info {
    color: #545b62;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #495057;
}
.table-section tbody {
    max-width: 500px;
    overflow-x: scroll;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8f9fa;
}
.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #eff2f7;
}
.view-more-base .view-more-base-tag {
    margin-bottom: 0;
}

.view-more-base .view-more-base-value {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 16px;
    color: #556ee6;
    font-weight: 600;
}
.card-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 600;
}
</style>