<template>
  <!--<div class="content">
     
    <div class="card">-->

  <div>
    <notifications></notifications>
    <card class="no-border-card" body-classes="px-3 pb-1" footer-classes="pb-2">
      <template slot="header">
        <h3 class="mb-0">Customers</h3>
      </template>
      <div>
        <div v-if="Object.keys(response).length !== 0">
          <!--<b-container class="table-container">-->
          <!--class="over-flow">-->
          <div class="form-body">
            <div class="table-section col-12">
              <!-- User Interface controls -->
             <div class="row mb-2">
 <label for="search" class="form-control-label">Search</label>
        <div class="input-group">
  <input type="text" v-model="filter" placeholder="Search ..." class="form-control" name="search">
  <div class="input-group-append">
    <base-button size="md" outline :disabled="loading" @click="queryCustomer()"  class="btn btn-primary"><i class="fas fa-search"></i>Search More  <span :class="{'spinner-border': loading}"></span></base-button>
  </div>
</div>
</div>

<div class="row my-1 mx--4">
<div class="col-lg-6 col-md-6 col-sm-5 col-12">
        <el-select class="select-danger" filterable
             placeholder="" 
             v-model="perPage">
    <el-option v-for="option in [{label:'5',value:5},{label:'10',value:10},{label:'15',value:15}, {label:'100',value:100} ]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <label class="form-control-label ml-1" for="">Per Page</label>
        </div>
     

    <div class="col-lg-6 col-md-6 col-sm-7 col-12">
      <div class="text-right">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
        </div>
    </div>
    </div>
              <!-- Main table element -->
              <div>
                <b-table
                  striped
                  hover
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  stacked="lg"
                  show-empty
                  large
                  :head-variant="light"
                  @filtered="onFiltered"
                >
                  <template #cell(action)="row">
                    <template>
                    <el-tooltip placement="top" content="Manage Document">
                    <base-button
                    @click.native="showDoc(row.item,row.index)"
                    class="info"
                    type="warning"
                    size="sm"
                    icon
                  >
                  <i class="text-white fas fa-file-upload"></i>
                  <!--<i class="text-white fas fa-info-circle"></i>
                  <i class=" text-white fas fa-check-square"></i>-->
                  </base-button>
</el-tooltip>
                  <el-tooltip placement="top" content="Add Sub Account">
                  <base-button
                    @click.native="addSubAccount(row.item,row.index)"
                    class="like btn-link"
                    type="info"
                    size="sm"
                    icon
                  >
                  <!--<i class="text-white fas fa-plus-square"></i>-->
                    <i class="text-white fas fa-plus"></i>
                  </base-button>
                  </el-tooltip>
                      <!--<base-button @click="addSubAccount(row.item, row.index)" size="sm" outline type="primary">Add Sub Account</base-button>-->
                    </template>
                  </template>
                  <template #cell(sn)="data">
        {{ data.index + 1 }}
      </template>

                  <template #cell(customerStatus)="row">
                    <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
                    <template>
                      <badge class="badge-dot mr-4" type="">
                        <div v-if="row.value === 'ACTIVE'">
                          <badge type="success" size="lg">ACTIVE</badge>
                        </div>
                        <div v-else>
                          <badge type="danger" size="lg">INACTIVE</badge>
                        </div>
                        <!--<i :class="[row.value == 'ACTIVE' ? 'bg-success' : 'bg-danger']"></i>
                        <span class="status">{{row.value}}</span>-->
                      </badge>
                    </template>
                  </template>
                  <template #cell(customerName)="row">
                       <div class="name-container" @click="viewInfo(row.item, row.index)">
                    <el-tooltip class="mr-3"  content="View more info" placement="left">
                    <i class="fas fa-info-circle"></i>
                    
                    </el-tooltip>
                    {{row.value}}
                       </div>
                   
                  </template>

                  <template #row-details="row">
                    <b-card>
                      <ul>
                        <li v-for="(value, key) in row.item" :key="key">
                          {{ key }}: {{ value }}
                        </li>
                      </ul>
                    </b-card>
                  </template>
                </b-table>
              </div>

              <modal
                :show.sync="modals.modal3"
                body-classes="p-0"
                modal-classes="modal-dialog-centered modal-md"
              >
                <div
                  class="vodal-dialog"
                  style="width: 500px; height: 450px; animation-duration: 300ms"
                >
                  <article class="item-right">
                    <!-- @click="displayDetail()">-->
                    <a
                      ><span @click="modals.modal3 = false; resetForm()"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          style="fill: #171717"
                          class="bi bi-x"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg> </span
                    ></a>
                  </article>
                  <!---<div class="card">-->
                  <div class="card-body">
                    <div>
                      <div class="view-more-customer-info">
                        <div class="view-more-customer-info-wrap">
                          <div class="view-more-top">
                            <h4 class="card-title mb-4">Add Sub Account</h4>
                          </div>
                          <div class="view-more-base">
                            <div class="table-responsive">
                              <form
                                id="sub_account"
                                @click="resetForm"
                                @submit.prevent="createSubAccount"
                              >
 <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
<base-input label="Account Class">
                      <el-select class="select-danger" filterable
             placeholder="Account Class"
             v-model="model.customerAccountClass">
    <el-option v-for="option in option_product"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value"
               required
               >
    </el-option>
  </el-select>
  <p v-show="classCtrl" class="form-error-message"> The Account Class is required </p>
                    </base-input>

            </div>
 </div>
            <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
<base-input label="Currency">
                      <el-select class="select-danger"  filterable
             placeholder="Currency"
             v-model="model.customerCurrency">
    <el-option v-for="option in option_currency"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <p v-show="countryCtrl" class="form-error-message"> The Account Class is required </p>
</base-input>
            </div>
 </div>
 
                              </form>
                            </div>
                          </div>
                          <p class="text-right">
                            <span>
                              <span class="authorize-btn"
                                ><button
                                  :class="{ disabled: loading_account }"
                                  type="submit"
                                  form="sub_account"
                                  class="btn btn-primary mr-1"
                                >
                                  <i v-if="!loading" class="fas fa-check"></i
                                  >Update
                                  <span
                                    :class="{ 'spinner-border': loading }"
                                  ></span>
                                </button> </span
                            ></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!---</card>
                                                </div></div>-->
                </div>
              </modal>

              
              <modal
                :show.sync="modals.modal1"
                body-classes="p-0"
                modal-classes="modal-dialog-centered modal-md"
              >
              <div v-if="Object.keys(single_response).length !== 0">
                <div
                  class="vodal-dialog"
                  style="width: 500px; height: 450px; animation-duration: 300ms"
                >
                <div v-if="formSelect">
                  <article class="item-right" >
                    <!-- @click="displayDetail()">-->
                    <a
                      ><span @click="modals.modal1 = false"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          style="fill: #171717"
                          class="bi bi-x"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg> </span
                    ></a>
                  </article>
                  <!---<div class="card">-->
  <div class="card-body">
    <div>
      <div class="view-more-customer-info">
        <div class="view-more-customer-info-wrap">
            <div class="view-more-top">
                <h4 class="card-title mb-4">Customer Info</h4>
          <div class="row"><div class="col-sm-12">
              <p class="customer-bio"><span class="customer-avatar">
                  <div> <img :src="single_response.customerPicture" alt="">
                  </div></span>
                  <span class="customer-info"><span class="name">{{single_response.customerFirstName}} {{single_response.customerMiddleName}} {{single_response.customerLastName}}</span><span class="age" v-if="checkDOB(single_response,customerDob)"> {{ageDifference(single_response.customerDob)}} years </span>
                  <span class="age" v-else> 0 years </span>
    <span class="status">
                        <div v-if="single_response.bankStatus === 'ACTIVE'">
                          <badge type="success" size="lg">ACTIVE</badge>
                        </div>
                        <div v-else>
                          <badge type="danger" size="lg">INACTIVE</badge>
                        </div>
                        <!--<i :class="[row.value == 'ACTIVE' ? 'bg-success' : 'bg-danger']"></i>
                        <span class="status">{{row.value}}</span>-->
    </span>


                  </span>
                  </p></div></div></div>
                  <div class="view-more-mid">

                  </div>
                        <div class="view-more-base">
                          <div class="table-responsive">
                            <table class="table table-striped table-hover mb-0">
                              <tbody>
                                <tr>
                                  <td><p class="view-more-base-tag"> BVN: </p></td>
                                  <td><p class="view-more-base-value"> {{single_response.customerBvn}} </p></td>
                                  </tr>
                                  <tr>
                                    <td><p class="view-more-base-tag"> Email: </p></td>
                                    <td><p class="view-more-base-value">{{single_response.customerEmail}}</p></td>
                                    </tr>
                                    <tr>
                                      <td><p class="view-more-base-tag"> Phone: </p></td>
                                      <td><p class="view-more-base-value">{{single_response.customerPhoneNumber}}</p></td>
                                      </tr>
                                      <tr>
                                        <td><p class="view-more-base-tag"> Dob: </p></td>
                                        <td><p class="view-more-base-value"> {{single_response.customerDob}} </p></td>
                                        </tr>
                                        <tr><td><p class="view-more-base-tag"> Address: </p></td>
                                        <td><p class="view-more-base-value"> {{single_response.customerAddress}} </p></td></tr>
                                        <tr><td><p class="view-more-base-tag"> State: </p></td>
                                        <td><p class="view-more-base-value"> {{single_response.customerState}} </p></td></tr>
                                        <tr><td><p class="view-more-base-tag"> Country: </p></td>
                                        <td><p class="view-more-base-value"> {{single_response.customerCountry}} </p></td></tr>
                                        <tr><td><p class="view-more-base-tag"> Bank Code: </p></td>
                                        <td><p class="view-more-base-value"> {{single_response.customerBankCode}} </p></td></tr>
                                        <tr><td><p class="view-more-base-tag"> Branch Code: </p></td>
                                        <td><p class="view-more-base-value"> {{single_response.customerBranchCode}} </p></td></tr>
                                        <tr><td><p class="view-more-base-tag"> Account Officer : </p></td>
                                        <td><p class="view-more-base-value"> {{single_response.customerAccountOfficer}} </p></td></tr>
                                        <tr><td><p class="view-more-base-tag"> Signature : </p></td>
                                        <td><p class="view-more-base-value">
                                            <span ><div >
                                                <img  :src="single_response.customerMandate" alt="" class="customer-signature">
                                                </div></span>
                                        </p></td></tr>
                                        </tbody></table>  
                                            </div></div>
                                            <p class="mt-2 text-center"><span>
                                              <span class=" authorize-btn">
                                                  <base-button @click="formSelect=false"  size="md" outline type="primary">Edit Customer Info</base-button>
                                                </span></span></p>
                                            </div></div></div>
  </div>
  </div>
  <!-------------------------------------------------------------------------------------->
  <div v-if="!formSelect">
  <article class="item-right">
                    <!-- @click="displayDetail()">-->
                    <a
                      ><span @click="formSelect=true" 
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          style="fill: #171717"
                          class="bi bi-x"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg> </span
                    ></a>
                  </article>
                  <!---<div class="card">-->
  <div class="card-body">
    <div>
      <div class="view-more-customer-info">
        <div class="view-more-customer-info-wrap">
            <div class="view-more-top">
                <h4 class="card-title mb-4">Edit Customer Info</h4>
          </div>
                        <div class="view-more-base">
                          <div class="table-responsive">
                             <form
                                id="edit_account"
                                @click="resetForm"
                                @submit.prevent="editCustomer"
                              >

<div class="row">
     <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="First Name"
                      name="Customer FirstName"
                      placeholder="First Name"
                      :required='inputRequired'
                      v-model="model.customerFirstName">
          </base-input>
        </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Middle Name"
                      name="Customer Middle Name"
                      placeholder="Middle Name"
                     v-model="model.customerMiddleName">
          </base-input>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Last Name"
                      name="Last Name"
                      placeholder="Customer Last Name"
                      :required='inputRequired'
                      v-model="model.customerLastName">
          </base-input>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Phone Number"
                      name="Phone Number"
                      placeholder="Phone Number"
                      :required='inputRequired'
                      v-model="model.customerPhoneNumber">
          </base-input>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
<base-input label="Country">
                      <el-select class="select-danger" clearable filterable
             placeholder="Country"
             v-model="model.customerCountry">
    <el-option v-for="option in option_country"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <p v-show="countryCtrl" class="form-error-message"> The Country is required </p>
                    </base-input>
        </div>

<div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label=" Customer Address"
                      name="  Customer Address"
                      placeholder="Customer  Address"
                      :required='inputRequired'
                      v-model="model.customerAddress">
          </base-input>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Customer Bvn"
                      name="Customer Bvn"
                      placeholder="Customer Bvn"
                      :required='inputRequired'
                      v-model="model.customerBvn">
          </base-input>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Customer Email"
                      name="Customer Email"
                      placeholder="Customer Email"
                      :required='inputRequired'
                      v-model="model.customerEmail">
          </base-input>
        </div>
<div class="col-lg-12 col-md-12 col-sm-12 col-12">
  <label class="form-control-label"> Date of Birth</label>
  <datetime 
        v-model="model.customerDob" input-class="form-control" class="theme-green"
        placeholder="DOB"  zone="Africa/Lagos" value-zone="Africa/Lagos" :min-datetime="minDatetime" :max-datetime="maxDatetime"></datetime>
        <p v-show="dobCtrl" class="form-error-message"> The Date of Birth is required </p>
          <!--<base-input label="Date of Birth">
                     <el-date-picker 
      v-model="model.customerDob"
      type="date"
      placeholder="Date of Birth">
    </el-date-picker>
    <p v-show="dobCtrl" class="form-error-message"> The Date of Birth is required </p>
          </base-input>-->
          
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Customer State"
                      name="Customer State"
                      placeholder="Customer State"
                      :required='inputRequired'
                      v-model="model.customerState">
          </base-input>
        </div>

 <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Gender ">
                      <el-select class="select-danger" clearable
             placeholder="Gender"
             v-model="model.customerGender">
    <el-option v-for="option in option_gender"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="genCtrl" class="form-error-message"> The Gender is Required' </p>
                    </base-input>
        </div>

         <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label=" Account Officer ">
                      <el-select class="select-danger" clearable
             placeholder="Branch Manager"
             v-model="model.customerAccountOfficer">
    <el-option v-for="option in option_user"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="officerCtrl" class="form-error-message">The Account Officer is Required' </p>
                    </base-input>
        </div>
</div>
</form>
                            </div>
                          </div>
                          <p class="text-right">
                            <span>
                              <span class="authorize-btn"
                                ><button
                                  :class="{ disabled: loading }"
                                  type="submit"
                                  form="edit_account"
                                  class="btn btn-primary mr-1"
                                >
                                  <i v-if="!loading" class="fas fa-check"></i
                                  >Submit
                                  <span
                                    :class="{ 'spinner-border': loading }"
                                  ></span>
                                </button> </span
                            ></span>
                          </p>
                                            </div></div></div>
  </div>
  <p class="ml-3 text-left"><a @click="formSelect=true"  class="sample-file-download "><span  class="fas fa-arrow-left"></span> Back </a></p>
                </div>
  </div>

              </div>
              <div v-else>
                      <LoadingPanel></LoadingPanel>
                    </div>
              </modal>
            </div>
          </div>
          <!--</b-container>-->
        </div>
        <div v-else>
          <LoadingPanel></LoadingPanel>
        </div>


      </div>

 <!----------------------------------------------------------->     
      <modal1 :show.sync="modals.doc"
                   body-classes="p-1"
                   modal-classes="modal-dialog-centered modal-md">
               <!--<div class="card">-->
        <!-- Card header -->
        <div class="card-head">
            <!-- Title -->
            <!--<div class="row">-->
              <!--<div class="col-12">-->
             <article class="item-right" ><!-- @click="displayDetail()">-->
                    <a><span @click="modals.doc=false"
            ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="fill:#171717;" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
</span></a></article>
              <!--</div>-->
              <div>
            <h5 class="h3 mb-0 text-center ">Document Management</h5>
             </div>
              
           <!-- </div>-->
        </div>
        <!-- Card body -->
         
        <div class="card-body">
          <div v-show="cust_doc_loading" ><p class="text-center"><button class="btn btn-secondary"><span class="spinner-border" ></span>
                                </button></p></div>
        <!-- <div v-show="cust_doc_loading" class="loading-div" ><div class="text-center"><LoadingPanel></LoadingPanel></div></div>-->
        <div>
        <div class="row">
        <div class="col"> 
        <div  v-if="'data' in customer_doc">
   <!-- Card body -->
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-12" v-for="(doc, index) in cust_doc"
                  :key="index">
                  <div v-if="doc.documentStatus!=='NONE' && doc.documentUrl!==''">
            <div class="our-team">
        <div class="picture">
          <img class="img-fluid" :src="(doc.documentUrl.endsWith('.pdf'))?'https://coregem-imgs.s3.amazonaws.com/PDF_File.png':doc.documentUrl">
        </div>
        <div class="team-content">
          <h6 class="name">{{doc.documentTypeName}}</h6>
          <span :class="{ 'text-success': (doc.documentStatus!=='PENDING'), 'text-danger': (doc.documentStatus==='PENDING') }" class="text-success">●</span>
          <small>{{doc.documentStatus}}</small>
         
          <!--<h4 class="title">Web Developer</h4>-->
        </div>
        <ul class="social">
          <li><a :href="doc.documentUrl"  target="_blank" class="pointer fas fa-cloud-download-alt" data-toggle="tooltip" data-placement="top" title="Download" aria-hidden="true"></a></li>
          <li><a @click="deleteDocument(doc.documentUrl,doc.documentType)" class="pointer far fa-trash-alt" data-toggle="tooltip" data-placement="top" title="Delete" aria-hidden="true"></a></li>
          <li v-show="doc.documentStatus!=='APPROVED'" ><a @click="authorizeDocument(doc.documentUrl,doc.documentType)" class="pointer far fa-check-square" data-toggle="tooltip" data-placement="top" title="Approve" aria-hidden="true"></a></li>
        </ul>
      </div>
      </div>
      <div v-else>
        <form @submit.prevent="submitDocument(index, doc.documentTypeName,doc.documentType)" :id="'form'+index" >
          <p v-show="errorArr[index]" class="form-error-message"> The File is required </p>
         <div class="our-team">
        <div class="pic">
          <label class="pointer">
  <input type="file" :ref="'file-input'+index" style="display: none;" accept="application/pdf, image/*" :id="'myfile'+index"  @change="handleImages($event,index)">
  <img class="img-fluid" src="https://coregem-imgs.s3.amazonaws.com/picture_logo.jpg" alt="" />
</label>
<!--<img class="img-fluid" src="https://coregem-imgs.s3.amazonaws.com/picture_logo.jpg">-->
        </div>
        <div class="team-content mt--2">
          <small class="name">Click To Upload File</small>

           <button type="submit" class="btn btn-primary">Submit
                                  </button>

          <h6 class="name">{{doc.documentTypeName}}</h6>
          <!--<span :class="{ 'text-success': (doc.documentStatus!=='PENDING'), 'text-danger': (doc.documentStatus==='PENDING') }" class="text-success">●</span>
          <small>{{doc.documentStatus}}</small>-->
          <!--<h4 class="title">Web Developer</h4>-->
        </div>
        <!--<ul class="social"> :class="{ disabled: loading }" 
          <li><a class="pointer fas fa-cloud-download-alt" data-toggle="tooltip" data-placement="top" title="Download" aria-hidden="true"></a></li>
          <li><a class="pointer far fa-trash-alt" data-toggle="tooltip" data-placement="top" title="Delete" aria-hidden="true"></a></li>
          <li><a class="pointer far fa-check-square" data-toggle="tooltip" data-placement="top" title="Approve" aria-hidden="true"></a></li>
        </ul>-->
      </div>
      </form>
      </div>
        </div>
        </div>
        
  
  </div>
    <div v-else>
              <LoadingPanel></LoadingPanel>
          </div>
        </div>
      </div>
</div>
 </div>
      </modal1>

    </card>

   <!-- {{Object.keys(single_response).length}}-->
  </div>
  <!-- </div>
    
  </div>-->
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui";
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from "@/components/LoadingPanel";
import LoadingProgress from "@/components/LoadingProgress";
import country from "@/components/country";
import Loader from "@/components/Loader";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import { Datetime } from 'vue-datetime';
export default {
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    LoadingPanel,
    ///LoadingProgress,
    Datetime
    //BaseInput,
    //Loader
  },
  
  data() {
    return {
      selectedRecord:{},
      files:[],
        formSelect:true,
        errorArr:[],
      option_branch_status: [
        { value: "ACTIVE", label: "ACTIVE" },
        { value: "INACTIVE", label: "INACTIVE" },
      ],
      cust_doc:[],
      option_user: [],
      option_product:[],
      option_country: [],
      option_gl:[],
      option_gender:[{value:"MALE",label:'MALE'},{value:'FEMALE',label:'FEMALE'}],
      option_currency:[{value:'NGN',label:'NGN'},{value:'GBP',label:'GBP'},{value:'USD',label:'USD'}],
      light: "light",
      inputRequired: true,
      statusCtrl: false,
      officerCtrl: false,
      countryCtrl: false,
      classCtrl:false,
      genCtrl:false,
      dobCtrl:false,
      minDatetime:'1970-01-01',
      maxDatetime:'',
      model: {
        customerFirstName:'',
            customerLastName:'',
            customerMiddleName:'',
            customerPhoneNumber:'',
            customerCountry:'',
            customerAddress:'',
            customerBVN:'',
            customerEmail:'',
            customerDob:'',
            customerState:'',
            customerAccountClass:'',
            customerCurrency:'',
            customerAccountOfficer:'',
            customerGender:''
      },
      modals: {
        modal3: false,
        modal0: false,
        modal1: false,
        doc:false
      },
      items: [],
      fields: [
        { key: "sn", label: "S/N", sortable: true, sortDirection: "desc" },
        {
          key: "customerBankCode",
          label: "Bank Code",
          sortable: true,
          class: "text-left",
        },
        {
          key: "customerBranchCode",
          label: "Branch Code",
          sortable: true,
          class: "text-left",
        },
        {
          key: "customerName",
          label: "Name",
          sortable: true,
          class: "text-left",
        },
        {
          key: "customerEmail",
          label: "Email",
          sortable: true,
          class: "text-left",
        },
        {
          key: "customerPhoneNumber",
          label: "Phonenumber",
          sortable: true,
          class: "text-left",
        },
        {
          key: "customerBvn",
          label: "BVN",
          sortable: true,
          class: "text-left",
        },
        {
          key: "customerState",
          label: "State",
          sortable: true,
          class: "text-left",
        },
        {
          key: "customerCountry",
          label: "Country",
          sortable: true,
          class: "text-left",
        },
        {
          key: "customerStatus",
          label: "Status",
          sortable: true,
          class: "text-left",
        },
        { key: "action", label: "Action" },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    ...mapState({
        success_account: (state) => state.account.account_success,
        errors_account: (state) => state.account.account_errors,
        loading_account: (state) => state.account.account_loading,

      customer_doc: (state) => state.customer.customer_document,
      cust_doc_loading: (state) => state.customer.customer_doc_loading,
      errors: (state) => state.customer.customer_errors,
      loading: (state) => state.customer.customer_loading,
      success: (state) => state.customer.customer_success,
      userInformation: (state) => state.auth.userInfo,
      response: (state) => state.customer.customer_response,
      cust_list: (state) => state.customer.customer_list,
      single_response: (state) => state.customer.single_customer,
      response_user: (state) => state.user.user_response,
      errors1: (state) => state.customer.customer_errors1,
      success1: (state) => state.customer.customer_success1,

       response_product: state => state.product.product_response, error_product: state => state.product.product_errors,
   response_glL2: state => state.product.gl_response,
   
    }),
    ...mapActions(["customerRead","productRead","customerReadSingle","userRead"]),
    ...mapGetters([""]),
  },
  /*mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },*/
  methods: {
    resetFile(){
      for (var i = 0; i < this.cust_doc.length; i++) {
           this.$refs['file-input'+i].reset();
          }
      this.files=[];

      },

   handleImages(e,index){
    const selectedImage = e.target.files[0];
    this.createBase64Images(selectedImage,index);
},
createBase64Images(fileObject,index){
    const img_reader = new FileReader();
    const vm = this;
    img_reader.onload=(e)=>{
        vm.files[index]=e.target.result;
        vm.notifyVue("success","Click on Submit");
    }
    img_reader.readAsDataURL(fileObject);
},
submitDocument(index,name,docType){
  console.log('Testing'+index);
  if( document.getElementById('myfile'+index).files.length == 0 ){
      //this.errorArr[index]=true;
      this.notifyVue("danger", `Upload file ${name}`);
  }
  else{
      this.$store.dispatch("documentCreate",{
        documentBankCode: this.userInformation.userBankCode,
documentFormat: "IMAGE",
documentGroup:this.selectedRecord.customerType,
documentMaker:this.selectedRecord.customerMaker,
documentOwner:this.selectedRecord.customerId,
documentType: docType,
pictureData: this.files[index],
pictureFileName: `${this.selectedRecord.customerId}_${Math.random()}`

    }, { root: false });
    //this.notifyVue("success", "Success");
    console.log(this.files[index]);
  }
  //console.log(evt);
  
    
},

deleteDocument(docUrl,docType){
 
 this.$store.dispatch("documentDelete",{
documentBankCode:this.userInformation.userBankCode,
documentMaker:this.selectedRecord.customerMaker,
documentOwner:this.selectedRecord.customerId,
            documentType: docType,
            documentUrl: docUrl,
            documentGroup:this.selectedRecord.customerType
}, { root: false });
    
},

authorizeDocument(docUrl,docType){
  this.$store.dispatch("documentAuthorize",{
documentBankCode:this.userInformation.userBankCode,
documentMaker:this.selectedRecord.customerMaker,
documentOwner:this.selectedRecord.customerId,
            documentType: docType,
            documentUrl: docUrl,
             documentGroup:this.selectedRecord.customerType
}, { root: false });
    
    
},
showDoc(item,index){
  this.selectedRecord = item;
    this.modals.doc = true;
     this.$store.dispatch("documentReadByCustomer",{
        documentBankCode: this.userInformation.userBankCode,
        documentGroup: item.customerType,
        documentOwner: item.customerId,
        
    }, { root: false });
    ///let year=str.split('-')[0];
    
},

ageDifference(str){
    console.log(str);
    let year=str.split('-')[0];
    return new Date().getFullYear().valueOf() - year[0].valueOf();
},
checkDOB(myObj,key){
    var test = false; 
    if (myObj.hasOwnProperty(key)) {
          test =true;
}
else
{
    test =false;
}
console.log(`>><<CheckDOB  ${test}`);
return test;
},
    resetForm() {
      //console.log(`>>><<<${this.nameCtrl}`);
      this.inputRequired = true;
    },
    
    validateSelectEdit() {
      if (this.model.customerAccountOfficer === "") {
        this.officerCtrl = true;
      } else {
        this.officerCtrl = false;
      }
      if (JSON.stringify(this.model.customerDob).length === 0) {
        this.dobCtrl = true;
      } else {
        this.dobCtrl = false;
      }
      if (this.model.branchCountry === "") {
        this.countryCtrl = true;
      } else {
        this.countryCtrl = false;
      }
      if (this.model.customerGender === "") {
        this.genCtrl = true;
      } else {
        this.genCtrl = false;
      }

      return !this.officerCtrl && !this.dobCtrl && !this.countryCtrl&& !this.genCtrl;
    },

validateSubAccountSelect(){
    if(this.model.customerAccountClass===""){
              this.classCtrl=true;
          }
          else{
              this.classCtrl=false;
          }
          if(this.model.customerCountry===""){
              this.countryCtrl=true;
          }
          else{
              this.countryCtrl=false;
          }
          
          return !this.countryCtrl && !this.classCtrl;
},
createSubAccount(){
    if(this.validateSubAccountSelect){
        console.log(`<<<<<<${this.model.customerAccountClass}`);
        console.log(`>>>>>>${this.model.customerCurrency}`); 
        this.$store.dispatch("accountCreate",{
        accountBankCode: this.userInformation.userBankCode,
        accountBranchCode: this.userInformation.userBranchCode,
        accountClass: this.model.customerAccountClass,
        accountCurrency: this.model.customerCurrency,
        accountCustomerId: this.model.customerId,
        accountMaker: this.userInformation.userId
    }, { root: false });
    }

},

addSubAccount(item, index) {
      // branchBankCode
      this.modals.modal3 = true;
      this.model.customerBankCode = item.customerBankCode;
      this.model.customerBranchCode = item.customerBranchCode;
      this.model.customerName = item.customerName;
      this.model.customerEmail = item.customerEmail;
      this.model.customerPhoneNumber = item.customerPhoneNumber;
      this.model.customerBvn = item.customerBvn;
      this.model.customerState = item.customerState;
      this.model.customerCountry = item.customerCountry;
      this.model.customerStatus = item.customerStatus;
      
      let customer_obj=this.response.data.find(obj => ((obj.customerPhoneNumber === this.model.customerPhoneNumber)&&(obj.customerEmail===this.model.customerEmail)));
      this.model.customerId=customer_obj.customerId;
      /*this.model.customerCurrency = customer_obj.customerCurrency;
      this.model.customerAccountClass = customer_obj.customerAccountClass;
      */
      
},
viewInfo(item, index){
  console.log("ViewMore ViewMore ViewMore");

  console.log(JSON.stringify(item));
  console.log(item.customerId);
    this.modals.modal1 = true;
    this.model.customerId=item.customerId; 
    console.log(this.model.customerId);
    this.$store.dispatch("customerReadSingle",{
        customerBankCode: this.userInformation.userBankCode,
        customerId: this.model.customerId
    }, { root: false });
    

},

formatDate(date){
          return date.split('-')[2]+'-'+this.convert(date.split('-')[1])+'-'+date.split('-')[0];
      },
      convert(str){
          var res =''; 
          switch (str) {
              case '01':
              case '1':
                  res = 'JAN';
                  break;
              case '02':
              case '2':
                  res = 'FEB';
                  break;
              case '03':
              case '3':
                  res = 'MAR';
                  break;
              case '04':
              case '4':
                  res = 'APR';
                  break;
              case '05':
              case '5':
                  res = 'MAY';
                  break;
             case '06':
            case '6':
                  res = 'JUN';
                  break;
            case '07':
              case '7':
                  res = 'JUL';
                  break;
            case '08':
            case '8':
                  res = 'AUG';
                  break;
            case '09':
            case '9':
                  res = 'SEP';
                  break;
            case '10':
                  res = 'OCT';
                  break;
            case '11':
                  res = 'NOV';
                  break;
            case '12':
                  res = 'DEC';
                  break;
              default:
                  res=str;
                  break;
          }
          return res;
      },

formatDate1(str){
        return `${str.split(' ')[2]}-${str.split(' ')[1]}-${str.split(' ')[0]}`;
    },
    showError(length){
      console.log("4544 "+length);
      for (var i = 0; i < length; i++) {
          this.errorArr[i]=false;
          console.log("<><> Hello "+i);
          }
    },

editCustomer() {
    if(this.validateSelectEdit){
        this.model.auditUser = this.userInformation.userId;
        this.model.customerCountryCode = "234";
        this.model.customerMaker= this.userInformation.userMaker;
        this.model.customerAccountOfficer =this.model.customerAccountOfficer;
        console.log(`>>>>>>>>>Testing<<<<< ${String(this.model.customerDob)}`);
        console.log(`>>>>>>>>><<<<< ${this.formatDate(String(this.model.customerDob).split("T")[0])}`)
        this.model.customerDob=this.formatDate(String(this.model.customerDob).split("T")[0]);//this.formatDate(this.model.customerDob);
        console.log("Helll JAttototooto");
        this.$store.dispatch("customerUpdate",this.model, { root: false });
    }
      // branchBankCode
     },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    notifyVue(type = "default", msg) {
      console.log(type);
      this.$notify({
        message: `<b>${msg}</b>`,
        timeout: 5000,
        icon: "ni ni-bell-55",
        type,
      });
    },
    queryCustomer(){
      console.log("Testing");
        if(this.filter!==null && this.filter!==''&&this.filter.length!==0){
        this.$store.dispatch("customerReadList", {
          customerBankCode:this.userInformation.userBankCode,
	readType:"search",
	searchItem: this.filter
             } , { root: false });}
        else{
           this.notifyVue('danger','Enter an account number');
        }
      },
  },

  watch: {
    /*cust_list(newValue, oldValue){
      console.log("Response Query "+JSON.stringify(this.cust_list));
      if(Object.keys(this.cust_list).length !== 0){
            if ('data' in this.cust_list){
    var data = []; 
   data = this.cust_list.data;
    //arr.push(this.cust_list);
  console.log("&$$$$$&>>><<<<"+this.cust_list.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn: count++,
            customerBankCode: item.customerBankCode,
            customerBranchCode: item.customerBranchCode,
            customerName: item.customerName,
            customerEmail: item.customerEmail,
            customerPhoneNumber: item.customerPhoneNumber,
            customerBvn: item.customerBvn,
            customerstate: item.customerState,
            customerCountry: item.customerCountry,
            customerState: item.customerState,
            customerCountry: item.customerCountry,
            customerStatus: item.customerStatus,
            customerId:item.customerId
            };
        });
     }
    else{
            this.items = [];
            //this.initialLoading = true;
         }
        }
        else{
            this.items = [];
            //this.initialLoading = true;
         }
    },
     */
   

      single_response(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      if (Object.keys(this.single_response).length !== 0) {
          this.model = this.single_response;
      }},
    response_user(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      if (Object.keys(this.response_user).length !== 0) {
        var user = [];
        user = this.response_user.data;
        this.option_user = user.map((item) => {
          return {
            value: `(${item.userId}) ${item.userFirstName} ${item.userMiddleName} ${item.userLastName}`,
            label: `(${item.userId}) ${item.userFirstName} ${item.userMiddleName} ${item.userLastName}`,
          };
        });
        console.log("//////////");
        //console.log("&$$$$$&"+this.option_branch);
      }
    },
    response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_product).length !== 0){
    var product = [];
    product = this.response_product.data;
    this.option_product = product.map((item) => {
            return {
                value: `${item.Code}`,
                label: `${item.Code} (${item.Description})`
            };
        });
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);
      
    }
        },
     response_glL2(newValue, oldValue) {
      //console.log(`Updating from Role ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      if(Object.keys(this.response_glL2).length !== 0){
    var gl = [];
    gl = this.response_glL2.data;
    this.option_gl = gl.map((item) => {
            return {
                value: item.glLevel2Description,
                label: item.glLevel2Description
            };
        });
 //       console.log('//////////');
//console.log("&$$$$$&"+this.option_role);
      
    }
   
        },

    errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", newValue);
        //this.showDismissibleAlert=true;
      }
    },
    errors1(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", newValue);
        //this.showDismissibleAlert=true;
      }
    },
    success1(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", newValue);
        setTimeout(() => this.modals.modal1 = !true, 2000);
        //setTimeout(function(){ this.modals.modal1 = false; }, 3000);
        
       
        console.log("Updated");
        //this.showDismissibleAlert=true;
      }
    },
    success(newValue, oldValue) {
      console.log(`Success from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        this.modals.modal3 = false;
        this.modals.modal0 = false;

        //this.showDismissibleAlert=true;
      }
    },
    sucess_reset(newValue, oldValue) {
      console.log(`%%%%%%Updating from ${oldValue} to ${newValue}`);
      if (newValue !== null) {
        this.reset_resp = newValue;
        this.modals.modal1 = true;
      }
    },
    response(newValue, oldValue) {
      console.log("<><<><><><" + this.response.data);
      if (Object.keys(this.response).length !== 0) {
         if ('data' in this.response){
        var data = [];
        data = this.response.data;
        console.log("&$$$$$&" + this.response.data);
        var count = 1;
        this.items = data;
        /*this.items = data.map((item) => {
          return {
             sn: count++,
            custId: item.custId,
customerAccountOfficer:item.customerAccountOfficer,
customerAddress:item.customerAddress,
customerAge: item.customerAge,
customerBankCode:item.customerBankCode,
customerBranchCode:item.customerBranchCode,
customerBranchId: item.customerBranchId,
customerBvn:item.customerBvn,
customerChecker:item.customerChecker,
customerCorporatePhone: item.customerCorporatePhone,
customerCountry:item.customerCountry,
customerCountryAddress: item.customerCountryAddress,
customerCreatedDate:item.customerCreatedDate,
customerDob:item.customerDob,
customerEmail:item.customerEmail,
customerFirstName:item.customerFirstName,
customerGender: item.customerGender,
customerHouseNo: item.customerHouseNo,
customerId:item.customerId,
customerIdCard: item.customerIdCard,
customerIncorporationDate:item.customerIncorporationDate,
customerIncorporationNumber:item.customerIncorporationNumber,
customerLastName:item.customerLastName,
customerLga:item.customerLga,
customerMaker:item.customerMaker,
customerMandate:item.customerMandate,
customerMiddleName:item.customerMiddleName,
customerName:item.customerName,
customerNearestBusStop:item.customerNearestBusStop,
customerNextOfKinAddress:item.customerNextOfKinAddress,
customerNextOfKinName:item.customerNextOfKinName,
customerNextOfKinPhoneNumber:item.customerNextOfKinPhoneNumber,
customerOtherPhoneNumber:item.customerOtherPhoneNumber,
customerPhoneNumber:item.customerPhoneNumber,
customerPicture:item.customerPicture,
customerPushId:item.customerPushId,
customerReferralCode:item.customerReferralCode,
customerReferralType:item.customerReferralType,
customerState:item.customerState,
customerStatus:item.customerStatus,
customerStreetName:item.customerStreetName,
customerType:item.customerUpdatedDate,
customerUpdatedDate:item.customerUpdatedDate,
customerUtilityBill:item.customerUtilityBill,
customerWebsite:item.customerWebsite,
customerprodiceId:item.customerprodiceId
          };
        });*/
         }
       else{
            this.items = [];
            //this.initialLoading = true;
         }
        }
        else{
            this.items = [];
            //this.initialLoading = true;
         }
    },

    customer_doc(newValue, oldValue) {
      console.log("<><<Custome><><><"  + this.customer_doc.data);
      if (Object.keys(this.customer_doc).length !== 0) {
         if ('data' in this.customer_doc){
        var data = [];
        data = this.customer_doc.data;
        //this.showError(data.length);
        console.log("&$$$$$&" + this.customer_doc.data);
        this.cust_doc = data;
        /*this.cust_doc = data.map((item) => {
          return {
           documentCreatedAt: item.documentCreatedAt,
            documentId:item.documentId,
            documentOwner:item.documentOwner,
            documentStatus:item.documentStatus,
            documentType: item.documentType,
            documentTypeGroup:item.documentTypeGroup,
          documentTypeName: item.documentTypeName,
          documentUpdatedAt:item.documentUpdatedAt,
            documentUrl:item.documentUrl
          };
        });*/
         }
       else{
            this.cust_doc = [];
            //this.initialLoading = true;
         }}
         else{
            this.cust_doc = [];
            //this.initialLoading = true;
         }
      },

    success_account(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", newValue);
        //this.showDismissibleAlert=true;
      }
    },
    errors_account(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", newValue);
        //this.showDismissibleAlert=true;
      }
    },
  },
  updated() {
    //this.data = this.$store.getters.getPrivilageResponse;
    //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
  },
  created: function () {},
  mounted: function () {
    if ('data' in this.response){
        var data = [];
        data = this.response.data;
        console.log("&$$$$$&" + this.response.data);
        var count = 1;
        this.items = data;
        /*this.items = data.map((item) => {
          return {
            sn: count++,
            custId: item.custId,
customerAccountOfficer:item.customerAccountOfficer,
customerAddress:item.customerAddress,
customerAge: item.customerAge,
customerBankCode:item.customerBankCode,
customerBranchCode:item.customerBranchCode,
customerBranchId: item.customerBranchId,
customerBvn:item.customerBvn,
customerChecker:item.customerChecker,
customerCorporatePhone: item.customerCorporatePhone,
customerCountry:item.customerCountry,
customerCountryAddress: item.customerCountryAddress,
customerCreatedDate:item.customerCreatedDate,
customerDob:item.customerDob,
customerEmail:item.customerEmail,
customerFirstName:item.customerFirstName,
customerGender: item.customerGender,
customerHouseNo: item.customerHouseNo,
customerId:item.customerId,
customerIdCard: item.customerIdCard,
customerIncorporationDate:item.customerIncorporationDate,
customerIncorporationNumber:item.customerIncorporationNumber,
customerLastName:item.customerLastName,
customerLga:item.customerLga,
customerMaker:item.customerMaker,
customerMandate:item.customerMandate,
customerMiddleName:item.customerMiddleName,
customerName:item.customerName,
customerNearestBusStop:item.customerNearestBusStop,
customerNextOfKinAddress:item.customerNextOfKinAddress,
customerNextOfKinName:item.customerNextOfKinName,
customerNextOfKinPhoneNumber:item.customerNextOfKinPhoneNumber,
customerOtherPhoneNumber:item.customerOtherPhoneNumber,
customerPhoneNumber:item.customerPhoneNumber,
customerPicture:item.customerPicture,
customerPushId:item.customerPushId,
customerReferralCode:item.customerReferralCode,
customerReferralType:item.customerReferralType,
customerState:item.customerState,
customerStatus:item.customerStatus,
customerStreetName:item.customerStreetName,
customerType:item.customerUpdatedDate,
customerUpdatedDate:item.customerUpdatedDate,
customerUtilityBill:item.customerUtilityBill,
customerWebsite:item.customerWebsite,
customerprodiceId:item.customerprodiceId

customerBankCode: item.customerBankCode,
            customerBranchCode: item.customerBranchCode,
            customerName: item.customerName,
            customerEmail: item.customerEmail,
            customerPhoneNumber: item.customerPhoneNumber,
            customerBvn: item.customerBvn,
            customerstate: item.customerState,
            customerCountry: item.customerCountry,
            customerState: item.customerState,
            customerCountry: item.customerCountry,
            customerStatus: item.customerStatus,
            customerId:item.customerId
          };
        });*/
         }
       else{
            this.items = [];
            //this.initialLoading = true;
         }

    this.$store.dispatch(
      "customerRead",
      {
        customerBankCode: this.userInformation.userBankCode
      },
      { root: false }
    );

    let countries = country;
    this.option_country = countries.map((item) => {
      return {
        value: `${item.name}`,
        label: `${item.name}`,
      };
    });
    this.$store.dispatch(
      "userRead",
      {
        readAll: "YES",
        source: "CBA",
        userBankCode: this.userInformation.userBankCode,
      },
      { root: false }
    );

    let payload = {
            product:{
                productBankCode: this.userInformation.userBankCode,
                productType: "AC"
            },
            gl:{
                glLevel2BankCode: this.userInformation.userBankCode,
                readAll: "NO"
            }
        };

    this.$store.dispatch("productRead",payload, { root: false });
    var dateObj = new Date();
var month = (String(dateObj.getUTCMonth() + 1).length===1)?'0'+(dateObj.getUTCMonth() + 1):(dateObj.getUTCMonth() + 1); //months from 1-12
var day = String(dateObj.getUTCDate().length===1)?'0'+dateObj.getUTCDate():dateObj.getUTCDate();
var year = dateObj.getUTCFullYear();
var newdate = year + "-" + month + "-" + day;
this.maxDatetime=newdate;
    //console.log(newdate);
    //console.log('Testing>>>>>> ' +this.maxDatetime);
  },
};
</script>
<style lang="css" scoped>
.pointer{
    cursor: pointer;
  }
.name-container{
    cursor: pointer;
    display: flex; 
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.table-section {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 40px;
  /*max-width: 100%;*/
}

.text-title {
  font-size: 0.875rem;
  font-weight: 600;
}
.item-right {
   padding-left: 100%; 
  cursor: pointer;
  margin:0px 0% -10px 0px;
    float:right!important;
}
.vodal-dialog {
  overflow-y: scroll;
  overflow-x: hidden !important;
  background-color: #fff;
}
.vodal-dialog .card {
  box-shadow: none !important;
}
body {
  overflow-x: hidden !important;
}
.text-title {
  font-size: 0.875rem;
  font-weight: 600;
}
.view-more-customer-info {
  color: #545b62;
}
.view-more-customer-info .view-more-top {
  margin-bottom: 10px;
}
.theme-green{
  color: #9e9e9e;
}
.view-more-top .queue-title {
  margin-bottom: 2px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: #9e9e9e;
}
p {
  color: #495057;
}
.view-more-customer-info p {
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 0;
}
.view-more-top .queue-value {
  font-size: 14px;
  line-height: 18px;
  color: #212121;
  margin-bottom: 20px;
}
.view-more-top .queue-title {
  margin-bottom: 2px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: #9e9e9e;
}
.view-more-customer-info {
  color: #545b62;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #495057;
}
.loading-div {
  width: 50px;
 height: 50px;
 align-content: center;
 align-items: center;
}
.table-section tbody {
  max-width: 500px;
  overflow-x: scroll;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #eff2f7;
}
.view-more-base .view-more-base-tag {
  margin-bottom: 0;
}

.view-more-base .view-more-base-value {
  margin-bottom: 0;
  font-size: 13px;
  line-height: 16px;
  color: #556ee6;
  font-weight: 600;
}
.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: 600;
}
.view-more-customer-info .view-more-top {
    margin-bottom: 10px;
}
.view-more-customer-info .view-more-top .customer-bio {
    display: flex;
    margin: 0;
}
.view-more-customer-info .view-more-top .customer-bio .customer-avatar {
    margin-right: 10px;
}
.view-more-customer-info p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
}
.view-more-customer-info .view-more-top {
    margin-bottom: 10px;
}
.view-more-base .view-more-base-value .customer-signature {
    height: auto;
    width: 100%;
    cursor: pointer;
}
.sample-file-download {
    cursor: pointer;
}
.view-more-customer-info .view-more-top .customer-bio .customer-avatar img {
    height: 80px;
    border-radius: 10%;
    cursor: pointer;
}
img, svg {
    vertical-align: middle;
}
.view-more-customer-info .view-more-top .customer-bio .name {
    display: block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 10px;
}
.view-more-customer-info .view-more-top .customer-bio .age {
    display: block;
    margin-bottom: 5px;
}
.view-more-customer-info .view-more-top .customer-bio .status {
    display: inline-block;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    height: calc(1.5em + 1.25rem + 5px);
    line-height: 1.5;
    width: 100%;
}


.our-team {
  padding: 30px 0 40px;
  margin-bottom: 30px;
  background-color: #ffffff80;
  text-align: center;
  overflow: hidden;
  position: relative;
  border-radius:5%;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
}

.our-team .picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
}

.our-team .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 0%;
  background-color: #1369ce;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}


.our-team .pic img {
  width: 50%;
  height: 50%;
  border-radius: 0%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team .picture img {
  width: 100%;
  height: auto;
  border-radius: 0%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}


.our-team .title {
  display: block;
  font-size: 15px;
  color: #4e5052;
  text-transform: capitalize;
}

.our-team .social {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #56585c;
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .social {
  bottom: 0;
}

.our-team .social li {
  display: inline-block;
}

.our-team .social li a {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: white;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.our-team .social li a:hover {
  color: #56585c;
  background-color: #f7f5ec;
}

.card-head:first-child {
    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}
.card-head {
    padding: 0.25rem 0.5rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
</style>