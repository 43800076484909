<template>
<div>
    <modal1 :show="(showCreateUser && showModal)"
                   body-classes="p-1"
                   modal-classes="modal-dialog-centered modal-lg">
               <!--<div class="card">-->
        <!-- Card header -->
        <div class="card-head">
            <!-- Title -->
            <!--<div class="row">-->
              <!--<div class="col-12">-->
             <article class="item-right" ><!-- @click="displayDetail()">-->
                    <a><span @click="closeModal()"
            ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="fill:#171717;" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
</span></a></article>
              <!--</div>-->
              <div class="py-2">
            <h5 class="h3 mb-0 text-center">Create User</h5>
             </div>
              
           <!-- </div>-->
        </div>
        <!-- Card body -->
        <div class="card-body">
<div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div v-if="option_role.length!==0">
         
    <!-- Card body -->
    
    <form 
          @submit.prevent="submit">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Branch Code">
                      <el-select class="select-danger" clearable filterable
             placeholder="Branch Code"
             v-model="model.userBranchCode">
    <el-option v-for="option in option_branch"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="branchCtrl" class="form-error-message"> Branch Code is required </p>
                    </base-input>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="User Email"
                      name="User Email"
                      placeholder="User Email"
                      required
                      v-model="model.userEmail">
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="User Phone Number"
                      name="User Phone Number"
                      placeholder="User Phone Number"
                      required
                      v-model="model.userPhone">
          </base-input>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="User First Name"
                      name="User First Name"
                      placeholder="User First Name"
                      required
                      v-model="model.userFirstName">
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="User Middle Name"
                      name="User Middle Name"
                      placeholder="User Middle Name"
                      required
                      v-model="model.userMiddleName">
          </base-input>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="User Last Name"
                      name="User Last Name"
                      placeholder="User Last Name"
                      required
                      v-model="model.userLastName">
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Country"
                      name="Country"
                      placeholder="Country"
                      required
                      v-model="model.addressCountry">
          </base-input>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="State"
                      name="State"
                      placeholder="State"
                      required
                      v-model="model.addressState">
          </base-input>
        </div>
      </div>

      <div class="row">
<div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Role Name">
                      <el-select class="select-danger" clearable filterable
             placeholder="Role Name"
             v-model="model.userRoleId">
    <el-option v-for="option in option_role"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <p v-show="roleCtrl" class="form-error-message"> The Role Name is required </p>
                    </base-input>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="City"
                      name="City"
                      placeholder="City"
                      required
                      v-model="model.addressCity">
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Street"
                      name="Street"
                      placeholder="Street"
                      required
                      v-model="model.addressStreet">
          </base-input>
        </div>

<div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="CBA Access">
                      <el-select class="select-danger" clearable filterable
             placeholder="CBA Access"
             v-model="model.setPassword">
    <el-option v-for="option in option_cba"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="cbaCtrl" class="form-error-message"> The CBA Access is required </p>
                    </base-input>
        </div>

      </div>
      <div class="text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_user}">
     <span class="btn-inner--text">Submit</span>
        <span :class="{'spinner-border': loading_user}"></span>
     </base-button>
      <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
      </div>
    </form>
  
  </div>
    <div v-else>
              <LoadingPanel></LoadingPanel>
          </div>
        </div>
      </div>
</div>
</div>
</modal1>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import vSelect from 'vue-select';
import { Select, Option } from 'element-ui';
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
export default {

    components: {
       LoadingPanel,
        //vSelect,
        //[Select.name]: Select,
        //[Option.name]: Option
    },
     props: {
            showCreateUser: Boolean,
            
        },
    data() {
      return {
        showModal:true,
          selected_branchCode:{},
          option_role:[],
          option_branch:[],
          option_cba:[{value:'YES',label:'YES'},{value:'NO',label:'NO'}],
          //option_branch:[{value:'00001',label:'VI'},{value:'00002',label:'Ikeja'}],
          branchCtrl:false,
          roleCtrl:false,
          cbaCtrl:false,
        model: {
    userFirstName: "",
    userMiddleName: "",
    userLastName: "",
    userPhone: "",
    userEmail: "",
    userRoleId: "",
    userMaker: "",
    userBranchCode: "",
    userBankCode: "",
    userBankName: "",
    setPassword:"",
addressCountry: "",
    addressState: "",
    addressCity: "",
    addressStreet: "",
    addressLineOne: "",
    addressLineTwo: "",
    addressLocation: "6.5025544, 3.3228477",
    userCountryCode: "234",
    source: "WEB"
        }
      }
    },
    methods: {
      submit: function(event) {
          console.log(`>>><<<${this.validateSelect()}`);
          if(this.validateSelect()){
          this.model.userBankCode=this.userInformation.userBankCode;
          //this.model.userBranchCode=this.userInformation.userBranchCode;
          this.model.userMaker=this.userInformation.userMaker;
          console.log(`>>>>>>> Model ${JSON.stringify(this.model)}`);
          this.$store.dispatch("userCreate", this.model , { root: false });
          this.model={
    userFirstName: '',
    userMiddleName: '',
    userLastName: '',
    userPhone: '',
    userEmail: '',
    userRoleId: '',
    userMaker: '',
    userBranchCode: '',
    userBankCode: '',
    userBankName: '',
    setPassword:'',
addressCountry: '',
    addressState: '',
    addressCity: '',
    addressStreet: '',
    addressLineOne: '',  
    addressLineTwo: '',
    addressLocation: "6.5025544, 3.3228477",
    userCountryCode: "234",
    source: "WEB"
        };
          //event.target.reset();

          }   
      },
      validateSelect(){
          if(this.model.userBranchCode===""){
              this.branchCtrl=true;
          }
          else{
              this.branchCtrl=false;
          }
          if(this.model.userRoleId===""){
              this.roleCtrl=true;
          }
          else{
              this.roleCtrl=false;
          }
          if(this.model.setPassword===""){
              this.cbaCtrl=true;
          }
          else{
              this.cbaCtrl=false;
          }
          return !this.branchCtrl && !this.roleCtrl && !this.cbaCtrl;
      },
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
       closeModal(){
        console.log("Closing Moddalslslslsl");
          this.showModal = false;
           this.$emit('closeCreateUser', false);
           this.showModal = true;
          
      }
    },
    watch:{
        role_bran(newValue, oldValue) {
      //console.log(`Updating from Role ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      if(Object.keys(this.role_bran).length !== 0){
    var role = [];
    role = this.role_bran.data;
    this.option_role = role.map((item) => {
            return {
                value: item.roleId,
                label: item.roleName
            };
        });
 //       console.log('//////////');
//console.log("&$$$$$&"+this.option_role);
      
    }
   
        },
        response_branch(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_branch).length !== 0){
    var branch = [];
    branch = this.response_branch.data;
    this.option_branch = branch.map((item) => {
            return {
                value: `${item.branchCode}`,
                label: `${item.branchName} (${item.branchBankCode}) - ${item.branchState}`
            };
        });
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);
      
    }
        },
    
    error_branch(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    }
    },
   mounted:function (){
        //this.$store.dispatch("roleRead", {
          //    readAll: "YES",
          //    roleBankCode:this.userInformation.role.roleBankCode} , { root: false });
        
    this.$store.dispatch("branchRead", {
              readAll: "YES",
              source:'web',
              branchBankCode:this.userInformation.userBankCode,
              roleBankCode:this.userInformation.userBankCode,
              action:'create_user'} , { root: false });
    
    },
    computed:{
           ...mapState({
               //errors: state => state.role.role_errors,loading: state => state.role.role_loading,
    //success: state => state.role.role_success,
    userInformation: state => state.auth.userInfo,
    //response: state => state.role.role_response, response_read: state => state.role.role_read_response,
    role_bran: state => state.branch.branch_role,
    response_branch: state => state.branch.branch_response, loading_user: state => state.user.user_loading,
    success_branch: state => state.branch.branch_success,error_branch: state => state.branch.branch_error,
    error_user: state => state.user.user_error,success_user: state => state.user.user_success,
    response_user: state => state.user.user_response
    }),
    ...mapActions(['roleRead','branchRead','userCreate']),
    ...mapGetters([""]),
    
    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
.item-right {
   padding-left: 100%; 
  cursor: pointer;
  margin:0px 0% -10px 0px;
    float:right!important;
}
.card-head:first-child {
    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}
.card-head {
    padding: 0.25rem 0.5rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
</style>
