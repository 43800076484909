<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">CREATE INTERNAL ACCOUNT</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Internal Account</a></li>
              <li class="breadcrumb-item active text-capitalize" aria-current="page">CREATE INTERNAL ACCOUNT</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            
          <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Create Internal Account</h3>

    <!-- Card body -->
    <div v-if="option_product.length!==0">
    <form @click="resetForm"
          @submit.prevent="submit">

<div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Account Name"
                      name="Account Name"
                      placeholder="Account Name"
                      :required="nameCtrl"
                      v-model="model.accountName">
          </base-input>
        </div>
</div>


      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Account Type">
                      <el-select class="select-danger" clearable filterable
             placeholder="Account Type"
             v-model="model.accountType">
    <el-option v-for="option in option_gl"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label"
               required>
    </el-option>
  </el-select>
  <p v-show="typeCtrl" class="form-error-message"> The Account Type is required </p>
                    </base-input>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Account Class">
                      <el-select class="select-danger" clearable filterable
             placeholder="Account Class"
             v-model="model.accountClass">
    <el-option v-for="option in option_product"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value"
               required
               >
    </el-option>
  </el-select>
  <p v-show="classCtrl" class="form-error-message"> The Account Class is required </p>
                    </base-input>
        </div>
      </div>

      
      <div class="text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_interanl_acc}">
     <span class="btn-inner--text">Submit</span>
        <span :class="{'spinner-border': loading_interanl_acc}"></span>
     </base-button>
      <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
      </div>
    </form>
    </div>
    <div v-else>
              <LoadingPanel></LoadingPanel>
          </div>
  </card>
  
        </div>
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
//import UserInfo from './UserInfo.vue';
import vSelect from 'vue-select';
import { Select, Option } from 'element-ui';
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
export default {

    components: {
        BaseHeader,
        LoadingPanel,
        //vSelect,
        //[Select.name]: Select,
        //[Option.name]: Option
    },
    data() {
      return {
          nameCtrl:true,
          selected_branchCode:{},
          option_gl:[],
          option_product:[],
          option_cba:[{value:'YES',label:'YES'},{value:'NO',label:'NO'}],
          //option_branch:[{value:'00001',label:'VI'},{value:'00002',label:'Ikeja'}],
          classCtrl:false,
          typeCtrl:false,
          model: {
            accountBankCode: "",
accountBranchCode: "",
accountClass: "",
accountCurrency: "NGN",
accountCustomerId: "",
accountMaker: "",
accountName: "",
accountType: ""
        }
      }
    },
    methods: {
        resetForm(){
            //console.log(`>>><<<${this.nameCtrl}`);
            this.nameCtrl=true;
        },
      submit: function(event) {
          console.log(`>>><<<${this.validateSelect()}`);
          
          if(this.validateSelect()){
              this.nameCtrl=!true;
          this.model.accountBranchCode=this.userInformation.userBranchCode;
          this.model.accountBankCode=this.userInformation.userBankCode;
          this.model.accountCustomerId=this.userInformation.userId;
          this.model.accountMaker=this.userInformation.userId;
          //this.model.userBranchCode=this.userInformation.userBranchCode;
          //this.model.userMaker=this.userInformation.userMaker;
          console.log(`>>>>>>> Model ${JSON.stringify(this.model)}`);
          this.$store.dispatch("internalAccountCreate", this.model , { root: false });

          this.model={
               accountBankCode: "",
accountBranchCode: "",
accountClass: "",
accountCurrency: "NGN",
accountCustomerId: "",
accountMaker: "",
accountName: null,
accountType: ""
        };
       // this.option_gl
          //event.target.reset();

          }   
      },
      validateSelect(){
          if(this.model.accountType===""){
              this.typeCtrl=true;
          }
          else{
              this.typeCtrl=false;
          }
          if(this.model.accountClass===""){
              this.classCtrl=true;
          }
          else{
              this.classCtrl=false;
          }
          
          return !this.classCtrl && !this.typeCtrl;
      },
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    },
    watch:{
        response_glL2(newValue, oldValue) {
      //console.log(`Updating from Role ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      if(Object.keys(this.response_glL2).length !== 0){
    var gl = [];
    gl = this.response_glL2.data;
    this.option_gl = gl.map((item) => {
            return {
                value: item.glLevel2Description,
                label: item.glLevel2Description
            };
        });
 //       console.log('//////////');
//console.log("&$$$$$&"+this.option_role);
      
    }
   
        },
        response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_product).length !== 0){
    var product = [];
    product = this.response_product.data;
    this.option_product = product.map((item) => {
            return {
                value: `${item.Code}`,
                label: `${item.Code} (${item.Description})`
            };
        });
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);
      
    }
        },
        success_interanl_acc(newValue, oldValue) {
     // console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },
    error_interanl_acc(newValue, oldValue) {
      //console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    }
    ,
    error_product(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    }
    },
   mounted:function (){
        //this.$store.dispatch("roleRead", {
          //    readAll: "YES",
          //    roleBankCode:this.userInformation.role.roleBankCode} , { root: false });
       let payload = {
            product:{
                productBankCode: this.userInformation.userBankCode,
                productType: "AC"
            },
            gl:{
                glLevel2BankCode: this.userInformation.userBankCode,
                readAll: "NO"
            }
        };


    this.$store.dispatch("productRead", payload , { root: false });
    
    },
    computed:{
           ...mapState({
     userInformation: state => state.auth.userInfo,
   response_product: state => state.product.product_response, loading_product: state => state.product.product_loading,
    success_product: state => state.product.product_success,error_product: state => state.product.product_errors,
   response_glL2: state => state.product.gl_response,
   response_interanl_acc: state => state.internalAccount.internal_acc_response, loading_interanl_acc: state => state.internalAccount.internal_acc_loading,
    success_interanl_acc: state => state.internalAccount.internal_acc_success,error_interanl_acc: state => state.internalAccount.internal_acc_errors,
    }),
    ...mapActions(['productRead','internalAccountCreate']),
    ...mapGetters([""]),
    
    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
</style>
