<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Account</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Account</a></li>
              <li class="breadcrumb-item active" aria-current="page">Account</li>
            </ol>
          </nav>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Accounts</h3>

    <!-- Card body -->
 <div v-if="'data' in response">
  <b-tabs content-class="mt-3" tabs pills justified active-nav-item-class="font-weight-bold">
    <!------------------------------Start of tab one----------------------->
    <b-tab @click="readInternalAccounts()" title="General Ledger Accounts" active>
          <div class="form-body"> 
  <div class="table-section col-12"> <!--class="over-flow">-->
    <!-- User Interface controls -->
    <div class="row mb-2">
 <label for="search" class="form-control-label">Search</label>
        <div class="input-group">
  <input type="text" v-model="filter" placeholder="Search ..." class="form-control" name="search">
  <div class="input-group-append">
    <base-button size="md" outline :disabled="loading" @click="queryAccount()"  class="btn btn-primary"><i class="fas fa-search"></i>Search More  <span :class="{'spinner-border': loading}"></span></base-button>
    <base-button size="md" outline :disabled="loading" @click="show=true" class="btn btn-primary"><i class="fas fa-plus"></i>New GL Account</base-button>
  </div>
</div>
</div>
   
<div class="row my-1 mx--4">
<div class="col-lg-6 col-md-6 col-sm-5 col-12">
        
        <el-select class="select-danger" filterable
             placeholder="" 
             v-model="perPage">
    <el-option v-for="option in [{label:'5',value:5},{label:'10',value:10},{label:'15',value:15}, {label:'100',value:100} ]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <label class="form-control-label ml-1" for="">Per Page</label>
    </div>
     

    <div class="col-lg-6 col-md-6 col-sm-7 col-12">
      <div class="text-right">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
    </div>
    </div>

    <!-- Main table element -->
    <div>
    <b-table striped hover
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :head-variant="light"
      stacked="lg"
      show-empty
      small
      @filtered="onFiltered"
    >
      <!--<template #cell(privileges)="row">
        <div class="pointer" @click="modalsDisplay(row.item, row.index)">
          <i class="ni ni-zoom-split-in"></i>
          <span class="ml-1">Info</span>
        
        
        </div>
      </template>-->

      <template #cell(accountStatus)="row">
       <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
        <template >
                    <badge class="badge-dot mr-4" type="">
                      <div v-if="row.value==='ACTIVE'">
                        <badge type="success">ACTIVE</badge>
                        </div>
                        <div v-else>
                        <badge type="danger">PENDING</badge>
                        </div>
                        <!--<i :class="[row.value == 'ACTIVE' ? 'bg-success' : 'bg-danger']"></i>
                        <span class="status">{{row.value}}</span>-->
                    </badge>
                </template>
      </template>

      
      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>


  
  </div>
          
         
          </div>
         
    
    </b-tab>
    <!---------------------------------End of Tab one---------------------------->


    <!---------------------------------Start of Tab Two---------------------------->
    <!------------------------------Start of tab one----------------------->
    <b-tab @click="readAccounts()"  title="Customer Accounts">
       
       <view-customer-accounts></view-customer-accounts>
    
    </b-tab>
    <!---------------------------------End of Tab one---------------------------->
<!---------------------------------End of Tab two---------------------------->
    </b-tabs>
</div>
<div v-else>
    <LoadingPanel></LoadingPanel>
</div>
    
  </card>
 

        </div>
        <GLAccount @closeChild="updateValue" :show="show"></GLAccount> 
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import TabPane from '@/components/Tabs/Tab';
import Tabs from '@/components/Tabs/Tabs';
import LoadingPanel from '@/components/LoadingPanel';
import GLAccount from '../Account/GLAccount';
import ViewCustomerAccounts from '../Account/ViewCustomerAccounts';

import { mapState, mapActions, mapGetters } from "vuex";
export default {

    components: {
        BaseHeader,
        LoadingPanel,
        GLAccount,
        ViewCustomerAccounts,
               //tab,
        //TabPane,
        //Tabs,
      },
    data() {
      return {
        light:'light',
       initialLoading:false,
       show:false,
       modals:{
           modal1:false,
       },
        items:[],
        fields: [
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'accountBankCode', label: 'Bank Code', sortable: true, class: 'text-left' },
          { key: 'accountName', label: 'Account Name', sortable: true, class: 'text-left' },
          { key: 'accountInterestRate', label: 'Interest Rate', sortable: true, class: 'text-left' },
          { key: 'accountClass', label: 'Account Class', sortable: true, class: 'text-left' },
          {key: 'accountCurrency',label: 'Currency',sortable: true,class: 'text-left'},
          { key: 'accountBalance', label: 'Account Balance', sortable: true, class: 'text-left' },
          { key: 'accountNumber', label: 'Account Number  ', sortable: true, class: 'text-left' },
        { key: 'accountCustomerId', label: 'CustomerId', sortable: true, class: 'text-left' },
          { key: 'accountStatus', label: 'Status',sortable: true, class: 'text-left' }
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },
    methods: { 
      readInternalAccounts(){
       this.$store.dispatch("internalAccountRead", {
              readAll: "YES",
               accountBankCode:this.userInformation.userBankCode
              } , { root: false });
    },
    readAccounts(){
        this.$store.dispatch("accountRead", {
accountBankCode: this.userInformation.userBankCode,
readAll: "NO"
     
              } , { root: false });
    },
       onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      queryAccount(){
        if(this.filter!==null || this.filter!==''){
        this.$store.dispatch("internalAccountReadSingle", {
          accountBankCode:this.userInformation.userBankCode,
	        accountNumber: this.filter
             } , { root: false });}
        else{
           this.notifyVue('danger','Enter an account number');
        }

      },
        
     
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
updateValue(value){
   this.show=value;
   //console.log("working&&&&&&&&&&&&"+this.show);
},
    },
    watch:{
errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('danger',newValue);
        //this.showDismissibleAlert=true;
      }
    },
    
    success(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('success',` Success`);
        
        //this.showDismissibleAlert=true;
      }
    },
    
    response(newValue, oldValue){
       if(Object.keys(this.response).length !== 0){
            if ('data' in this.response){
    var data = []; 
    data = this.response.data;
    console.log("&$$$$$&"+this.response.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                accountBankCode: item.accountBankCode,
                accountName: item.accountName,
                accountInterestRate: item.accountInterestRate,
                accountType: item.accountType,
                accountClass: item.accountClass,
                accountCurrency: item.accountCurrency,
                accountBalance:item.accountBalance,
                accountNumber:item.accountNumber,
                accountCustomerId:item.accountCustomerId,
                accountStatus:item.accountStatus
            };
        });
      //this.totalRows = this.items.length;
      //this.items = data;
      this.initialLoading = true;
    }
    else{
            this.items = [];
            this.initialLoading = true;
         }
        }
        else{
            this.items = [];
            this.initialLoading = true;
         }
    },
    response_query(newValue, oldValue){
      console.log("Response Query "+this.response_query);
      if(Object.keys(this.response_query).length !== 0){
            if ('accountId' in this.response_query){
    var arr = []; 
   // data = this.response.data;
    arr.push(this.response_query);
    //console.log("&$$$$$&"+this.response.data);
    var count = 1;
    this.items = arr.map((item) => {
            return {
                sn:count++,
                accountBankCode: item.accountBankCode,
                accountName: item.accountName,
                accountInterestRate: item.accountInterestRate,
                accountType: item.accountType,
                accountClass: item.accountClass,
                accountCurrency: item.accountCurrency,
                accountBalance:item.accountBalance,
                accountNumber:item.accountNumber,
                accountCustomerId:item.accountCustomerId,
                accountStatus:item.accountStatus
            };
        });
      //this.totalRows = this.items.length;
      //this.items = data;
      //this.initialLoading = true;
    }
    else{
            this.items = [];
            //this.initialLoading = true;
         }
        }
        else{
            this.items = [];
            //this.initialLoading = true;
         }
    },
     success_interanl_acc(newValue, oldValue) {
     if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        }
    },
    error_interanl_acc(newValue, oldValue) {
     if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
       }
    }
    },
   mounted:function (){
      if ('data' in this.response){
    var data = []; 
    data = this.response.data;
    console.log("&$$$$$&"+this.response.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                accountBankCode: item.accountBankCode,
                accountName: item.accountName,
                accountInterestRate: item.accountInterestRate,
                accountType: item.accountType,
                accountClass: item.accountClass,
                accountCurrency: item.accountCurrency,
                accountBalance:item.accountBalance,
                accountNumber:item.accountNumber,
                accountCustomerId:item.accountCustomerId,
                accountStatus:item.accountStatus
            };
        });
      
    }
         this.$store.dispatch("internalAccountRead", {
              readAll: "YES",
               accountBankCode:this.userInformation.userBankCode
              } , { root: false });

    },
    computed:{
           ...mapState({errors: state => state.internalAccount.internal_acc_errors,loading: state => state.internalAccount.internal_acc_loading,
    success: state => state.internalAccount.internal_acc_success,userInformation: state => state.auth.userInfo,
    response: state => state.internalAccount.internal_acc_response, response_query: state => state.internalAccount.internal_acc_response_query,
    success_interanl_acc: state => state.internalAccount.internal_acc_success1,error_interanl_acc: state => state.internalAccount.internal_acc_errors1,
    }),
    ...mapActions(['internalAccountRead']),
    ...mapGetters([""]),
    
    }
  }
</script>
<style lang="css" scoped>
.hand{
    cursor: pointer;
}


.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #495057;
}
.table-section {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    
}
.table-section tbody {
    max-width: 500px;
    overflow-x: scroll;
}


@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.table-btn{
    margin-top: 2.0rem !important;
}
</style>